import { createContext } from 'react'

import { useCtx } from '@/hooks/useCtx'

import {
  KnowledgeListContextProps,
  KnowledgeListProviderProps,
} from './knowledgeList.props'

export const KnowledgeListContext =
  createContext<KnowledgeListContextProps | null>(null)

export const KnowledgeListProvider = ({
  children,
  value,
}: KnowledgeListProviderProps) => {
  return (
    <KnowledgeListContext.Provider value={value}>
      {children}
    </KnowledgeListContext.Provider>
  )
}

export const useKnowledgeListContext = () => {
  const ctx = useCtx<KnowledgeListContextProps>(KnowledgeListContext)

  return ctx
}
