import { Header } from '@/components/layout'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useTrans } from '@/hooks/useTranslation'

import { ProjectEditProvider } from './projectEdit.context'
import useProjectEdit from './projectEdit.hook'
import ProjectMembers from './projectMembers/projectMembers'
import ProjectThumbnail from './projectThumbnail/projectThumbnail'

const ProjectEdit = () => {
  const { t } = useTrans()
  const { isCreateNewProject, form, onSubmit } = useProjectEdit()

  return (
    <div className="h-dvh w-screen flex flex-col">
      <Header />
      <div className="w-full container">
        {isCreateNewProject && (
          <p className="h2 mb-4">{t('setUpNewProject')}</p>
        )}
        <Form {...form}>
          <form className="w-full md:w-3/4" onSubmit={onSubmit}>
            <ProjectThumbnail />
            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-col space-y-1.5">
                <FormLabel
                  className="font-semibold"
                  htmlFor="name"
                  requiredField
                >
                  {t('name')}
                </FormLabel>
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input placeholder={t('name')} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex flex-col space-y-1.5">
                <FormLabel className="font-semibold" htmlFor="description">
                  {t('Description')}
                </FormLabel>
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder={t('description')}
                          type="text"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label className="font-semibold">{t('member')}</Label>
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <ProjectMembers />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="w-full flex justify-end mt-16">
              <Button variant="secondary">{t('saveChanges')}</Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}

const ProjectEditContainer = () => {
  return (
    <ProjectEditProvider>
      <ProjectEdit />
    </ProjectEditProvider>
  )
}

export default ProjectEditContainer
