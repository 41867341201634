import AddMember from './addMember/addMember'
import Member from './member/member'
import useProjectMembers from './projectMemeber.hook'

interface IProps { }
const ProjectMembers = (props: IProps) => {
  const { listEmail, author, projectAuthor, onRemoveEmail } =
    useProjectMembers()

  return (
    <div className="w-full flex gap-4 flex-wrap">
      {listEmail?.map((emailItem: string, index: number) => {
        return (
          <Member
            email={emailItem}
            isAuthor={emailItem === author || emailItem === projectAuthor}
            key={index + emailItem}
            onRemoveEmail={() => onRemoveEmail(index)}
          />
        )
      })}

      <AddMember />
    </div>
  )
}

export default ProjectMembers
