import React, { ReactNode } from 'react'

import { BREAKPOINTS } from '@/constants/common'
import { useWindowSize } from '@/hooks/useWindowSize'

interface SideBarContextProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const SideBarContext = React.createContext<SideBarContextProps | null>(
  null,
)

export const SideBarProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <SideBarContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </SideBarContext.Provider>
  )
}

export const useSideBar = () => {
  const ctx = React.useContext(SideBarContext)
  if (!ctx) throw new Error('useSideBar must be used within a SideBarProvider')

  const { width } = useWindowSize()

  const handleToggle = () => {
    if (width <= BREAKPOINTS.MD) ctx.setIsOpen(!ctx.isOpen)
  }

  return {
    isOpenMenu: ctx.isOpen,
    handleToggleMenu: handleToggle,
  }
}

export default SideBarContext
