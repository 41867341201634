import { userRole } from '@/constants/common'
import { isAuth } from '@/helpers/auth'

import { useGetProject } from '../useGetProject/useGetProject'
import { IUseGetUserRole } from './useGetUserRole.props'

const useGetUserRole = (props: IUseGetUserRole) => {
  const isAdmin = isAuth().role === 'admin'
  const { pid = '' } = props
  const author = isAuth().email

  const { project } = useGetProject({ pid })

  if (isAdmin) return { role: userRole.admin }
  if (!author || !project) return { role: null }
  if (project.author === author) return { role: userRole.author }
  if (project.users?.includes(author)) return { role: userRole.member }
  if (project.guestProject) return { role: userRole.guest }

  return { role: null }
}

export default useGetUserRole
