import React, { useCallback } from 'react'

export const useToggle = () => {
  const [toggle, setToggle] = React.useState<boolean>(false)
  const handleToggle = useCallback(() => {
    setToggle((prev) => !prev)
  }, [])

  return {
    toggle,
    handleToggle,
  }
}
