import { ANY, ChannelInfo } from '@/types'

import { EChannel } from './usage.constant'

const mappingChanelTeramot = [
  EChannel.UNKNOWN,
  EChannel.WEBAPP,
  EChannel.TERAMOT,
]

export const getInteractions = (
  acc: ANY,
  [key, channelInfo]: [key: EChannel, channelInfo: ChannelInfo],
): Record<EChannel, ChannelInfo> => {
  if (mappingChanelTeramot.includes(key)) {
    if (!acc[EChannel.TERAMOT]) acc[EChannel.TERAMOT] = { ...channelInfo }
    else {
      acc[EChannel.TERAMOT].n_interaction += channelInfo.n_interaction
      acc[EChannel.TERAMOT].total_cost += channelInfo.total_cost
    }
  }

  if (key === EChannel.WHATSAPP) {
    if (!acc[EChannel.WHATSAPP]) acc[EChannel.WHATSAPP] = { ...channelInfo }
    else {
      acc[EChannel.WHATSAPP].n_interaction += channelInfo.n_interaction
      acc[EChannel.WHATSAPP].total_cost += channelInfo.total_cost
    }
  }
  return acc
}
