import { useFieldArray } from 'react-hook-form'

import { genTableQuestionUUID } from '@/helpers/genUUID'

import { QUESTION_LIST_FORM_KEY } from './questionList.constant'
import { useQuestionListContext } from './questionList.context'

export const useQuestionList = () => {
  const { form } = useQuestionListContext()

  const handleAddQuestion = (question: string) => {
    const listQuestion = form.getValues(QUESTION_LIST_FORM_KEY.QUESTIONS) ?? []
    if (!question) return

    form.setValue(
      QUESTION_LIST_FORM_KEY.QUESTIONS,
      listQuestion.concat({
        _id: genTableQuestionUUID(),
        content: question,
      }),
    )
    form.setValue(QUESTION_LIST_FORM_KEY.ADD_QUESTION, '')
  }

  const { fields, remove } = useFieldArray({
    control: form.control,
    name: QUESTION_LIST_FORM_KEY.QUESTIONS,
  })

  return {
    form,
    handleAddQuestion,
    questionFields: fields,
    handleRemoveQuestion: remove,
  }
}
