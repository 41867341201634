import { useWarningBeforeLeave } from '@/hooks/useValidateCreateDatasetBeforeLeave'

import { UseCreateNameLeavePage } from './updateName.props'

export const useCreateNameLeavePage = (props: UseCreateNameLeavePage) => {
  const { form, meta } = props

  const validate = () => {
    const name = form.getValues('name')
    const description = form.getValues('description')

    return name || description || Object.keys(meta ?? {}).length > 0
  }

  const { blocker } = useWarningBeforeLeave({
    validate,
  })

  return { blocker }
}
