import './Empty.css'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'

import { EmptyProps } from './Empty.props'

const Empty = (props: EmptyProps) => {
  const { header, children } = props
  return (
    <div className="empty">
      <div className="emptyContainer">
        <div className="emptyLayout">
          <div className="emptyHeader">{header}</div>
          <div className="emptyBody">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Empty
