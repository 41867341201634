import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { confirmRodal } from '@/components/rodal'
import Rodal from '@/components/rodal/rodal'
import {
  setCurrentProject,
  setOutputChannels,
} from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'

import { CHANEL_TYPE } from '../usersChannel.constant'
import { useWhatsappChannelValidate } from './whatsappChannel.validate'

const useWhatsappChannel = () => {
  const dispatch = useAppDispatch()
  const { t } = useTrans()
  const { params } = useRouter()
  const pid = params.pid!

  const { schema } = useWhatsappChannelValidate()
  const { project } = useGetProject({ pid })

  const form = useForm({
    defaultValues: { phoneNumbers: [] },
    resolver: yupResolver(schema),
  })

  const isActivateWhatsapp = form.watch('activateWhatsapp')
  const isActivateBusiness = form.watch('activateBusiness')
  const phones = form.watch('phoneNumbers')
  const outputChannelString = useMemo(
    () => JSON.stringify(project?.outputChannels ?? {}),
    [project?.outputChannels],
  )

  useEffect(() => {
    const outputChannels = JSON.parse(outputChannelString)
    const outputChannel = outputChannels?.[CHANEL_TYPE.WHATSAPP]
    if (!outputChannel) return

    form.setValue('activateWhatsapp', outputChannel?.activateWhatsapp || false)
    form.setValue('activateBusiness', outputChannel?.activateBusiness || false)
    form.setValue(
      'businessAllPhones',
      outputChannel?.businessAllPhones || false,
    )
    form.setValue('businessID', outputChannel?.businessID || '')
    form.setValue('graphQLURL', outputChannel?.graphQLURL || '')
    form.setValue('graphQLToken', outputChannel?.graphQLToken || '')
    form.setValue('phoneNumbers', outputChannel?.phoneNumbers || [])
  }, [form, outputChannelString])

  const onSubmit = form.handleSubmit(
    async (payload: Record<string, unknown>) => {
      const meta = {
        activateWhatsapp: payload?.activateWhatsapp ?? false,
        activateBusiness: payload?.activateBusiness ?? false,
        businessAllPhones: payload?.businessAllPhones ?? false,
        businessID: payload?.businessID ?? '',
        graphQLURL: payload?.graphQLURL ?? '',
        graphQLToken: payload?.graphQLToken ?? '',
        phoneNumbers: payload.phoneNumbers ?? [],
        type: CHANEL_TYPE.WHATSAPP,
      }

      const promise = dispatch(setOutputChannels(pid, meta))
      toast.promise(promise, {
        pending: t('processingOutputChannels'),
        success: t('outputChannelsAddedSuccessfully'),
        error: t('errorSettingOutputChannels'),
      })

      promise.then(() => dispatch(setCurrentProject(pid)))
    },
  )

  /**
   *
   * @param payload
   * @returns false if phone name already exist
   * @returns true if phone name not exist
   */
  const handleAddPhoneNumber = (payload: Record<string, string>) => {
    const { phone, name } = payload

    const phones = form.getValues('phoneNumbers') ?? []
    const isExist = phones.some((e) => e.phone === phone)
    if (isExist) {
      toast.error(t('phoneAlreadyExist'))
      return false
    }
    const newPhones = [
      ...phones,
      {
        name,
        phone,
        checked: false,
      },
    ]
    // check if phone is already exist
    form.setValue('phoneNumbers', newPhones)
    return true
  }

  const handleRemovePhoneNumber = (phone: string) => {
    const removePhoneNumber = () => {
      const listPhone = form.getValues('phoneNumbers') ?? []
      const indexPhone = listPhone.findIndex((e) => e.phone === phone)
      if (indexPhone != -1) {
        listPhone.splice(indexPhone, 1)
        form.setValue('phoneNumbers', listPhone)
      }
    }

    confirmRodal({
      children: ({ onClose }) => {
        return (
          <Rodal
            message={t('descriptionDeletePhone')}
            onCancel={onClose}
            onClose={onClose}
            onConfirm={() => {
              removePhoneNumber()
            }}
            title={t('titleDeletePhone')}
          />
        )
      },
    })
  }

  const handleCheckPhoneNumber = (phone: string, isChecked: boolean) => {
    const listPhone = form.getValues('phoneNumbers') ?? []
    const indexPhone = listPhone.findIndex((e) => e.phone === phone)
    if (indexPhone != -1) {
      listPhone[indexPhone].checked = isChecked
      form.setValue('phoneNumbers', listPhone)
    }
  }

  return {
    isActivateWhatsapp,
    isActivateBusiness,
    form,
    onSubmit,
    handleAddPhoneNumber,
    phones,
    handleRemovePhoneNumber,
    handleCheckPhoneNumber,
  }
}

export default useWhatsappChannel
