import IcUpload from '@/components/Icon/upload.svg?react'
import { Button } from '@/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useTrans } from '@/hooks/useTranslation'

import { useDatasetCreateContext } from '../datasetCreate.context'
import DatasetCreateLayout from '../datasetCreateLayout/datasetCreateLayout'
import {
  useUpdateSpreadsheetFile,
  useUpdateSpreadsheetLeavePage,
  useUpdateSpreadsheetOnlineLink,
} from './updateSpreadsheet.hook'
import { UpdateSpreadsheetProps } from './updateSpreadsheet.props'
import { useUpdateSpreadsheetSchema } from './updateSpreadsheet.validate'

const UpdateSpreadsheet = (props: UpdateSpreadsheetProps) => {
  const { t } = useTrans()
  const {
    form,
    file,
    onChangeUploadFiles,
    handleDragAndDropFiles,
    overrideEventDefaults,
  } = useUpdateSpreadsheetFile({
    form: props.form,
  })
  const { handleUploadWithLink } = useUpdateSpreadsheetOnlineLink({
    form: props.form,
  })

  const { dataset } = useDatasetCreateContext()
  useUpdateSpreadsheetLeavePage({
    form: form,
    meta: dataset,
  })

  return (
    <div className="flex flex-col justify-center gap-8">
      <div className="flex justify-center gap-8">
        <FormField
          control={form.control}
          name="files"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel
                  className="cursor-pointer"
                  htmlFor="upload-file"
                  onDragEnter={overrideEventDefaults}
                  onDragLeave={overrideEventDefaults}
                  onDragOver={overrideEventDefaults}
                  onDrop={overrideEventDefaults}
                >
                  <div
                    className="w-full py-4 px-8 border rounded-xl"
                    onDragEnter={overrideEventDefaults}
                    onDragLeave={overrideEventDefaults}
                    onDragOver={overrideEventDefaults}
                    onDrop={handleDragAndDropFiles}
                  >
                    <div className="flex gap-2">
                      <IcUpload />
                      <p className="text-base text-lg">
                        {t('chooseAFileOrDragItHere')}
                      </p>
                    </div>
                    <p className="text-sm mt-2">
                      {t('chooseAFileOrDragItHereDescription')}
                    </p>
                    <div className="py-2 mt-6">
                      <p className="text-sm text-secondary-1">
                        {file?.name ?? 'Choose a file'}
                      </p>
                    </div>
                  </div>
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    accept=".xlsx, .xls, .csv"
                    className="!hidden"
                    hidden
                    id="upload-file"
                    multiple
                    onChange={onChangeUploadFiles}
                    type="file"
                  />
                </FormControl>
              </FormItem>
            )
          }}
        />
      </div>
      <div className="flex justify-center gap-8 w-full">
        <FormField
          control={form.control}
          name="fileLink"
          render={({ field }) => {
            return (
              <FormItem className="max-w-[784px] w-full">
                <FormLabel className="cursor-pointer" htmlFor="onlineLink">
                  {t('onlineLink')}
                </FormLabel>
                <div className="flex gap-2 items-center">
                  <FormControl>
                    <Input
                      {...field}
                      id="onlineLink"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.stopPropagation()
                          e.preventDefault()
                          handleUploadWithLink(e)
                        }
                      }}
                      placeholder={t('placeholderLink')}
                    />
                  </FormControl>
                  <Button
                    className="shrink-0 min-w-[80px]"
                    onClick={handleUploadWithLink}
                  >
                    {t('get')}
                  </Button>
                </div>
              </FormItem>
            )
          }}
        />
      </div>
    </div>
  )
}

const UpdateSpreadsheetContainer = () => {
  const { t } = useTrans()
  const { schema } = useUpdateSpreadsheetSchema()
  const { dataset } = useDatasetCreateContext()
  const isNextStep = (dataset?.meta?.columns as Array<string>)?.length > 0

  return (
    <DatasetCreateLayout
      isDisabledNext={!isNextStep}
      schema={schema}
      title={t('uploadYourSpreadsheet')}
    >
      {(props) => <UpdateSpreadsheet {...props} />}
    </DatasetCreateLayout>
  )
}

export default UpdateSpreadsheetContainer
