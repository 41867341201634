import { Outlet } from 'react-router'
import { Navigate } from 'react-router-dom'

import { useSession } from '@/contexts/sessionContext'

const PrivateRoute = () => {
  const { session } = useSession()
  if (!session) return <Navigate to="/login" />
  return <Outlet />
}

export default PrivateRoute
