import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons'

import { Button } from '@/components/ui/button'
import { FormLabel } from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { onEnterPress } from '@/helpers/common'
import { useTrans } from '@/hooks/useTranslation'

import { useRowUpdateDescriptionForm } from './rowUpdateDescriptionForm.hook'
import { RowUpdateDescriptionFormProps } from './rowUpdateDescriptionForm.props'

const RowUpdateDescriptionForm = (props: RowUpdateDescriptionFormProps) => {
  const { isOpen, onClose } = props
  const { t } = useTrans()
  const { onChange, handleSubmit } = useRowUpdateDescriptionForm(props)

  if (!isOpen) return <></>
  return (
    <div className="w-full mt-2">
      <div className="space-y-2">
        <FormLabel className="font-semibold">{t('description')}</FormLabel>
        <Textarea
          id={props.IdFocus}
          onChange={onChange}
          onKeyDown={onEnterPress((e) => {
            handleSubmit(e)
          })}
          placeholder={t('enterYourContent')}
        />
      </div>

      <div className="w-full flex justify-end gap-2 mt-4">
        <Button borderRadius="large" onClick={handleSubmit} type="button">
          <CheckIcon className="h-4 w-4" />
        </Button>
        <Button
          borderRadius="large"
          className="border border-n-2"
          onClick={onClose}
          type="button"
          variant="link"
        >
          <Cross2Icon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  )
}

export default RowUpdateDescriptionForm
