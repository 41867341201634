import { ScrollArea } from '@/components/ui/scroll-area'
import { RowCheckbox } from '@/pages/dataWarehouse/components'

import { useTableUpdateFormFactoryContext } from '../../tableUpdateFormFactory.context'
import { SHOW_MAX_ITEMS } from './tableUpdateFormItem.constant'
import { TableFormItemProps } from './tableUpdateFormItem.props'

const TableUpdateFormItem = (props: TableFormItemProps) => {
  const { item, tableIndex, datasetId, isHasCheckedAll = false } = props
  const {
    isCheckedAll,
    handleUpdateColumnsChecked,
    handleUpdateAllColumnsChecked,
  } = useTableUpdateFormFactoryContext()

  const size = item.columns?.length ?? 0

  return (
    <div>
      {isHasCheckedAll && (
        <RowCheckbox
          checked={isCheckedAll[`${datasetId}_${tableIndex}`]}
          className="!p-0"
          isRowSelectALL={true}
          label="Check all"
          onChange={(checked) => {
            handleUpdateAllColumnsChecked(
              datasetId,
              tableIndex,
              Boolean(checked),
            )
          }}
        />
      )}

      <div className="mt-4">
        <ScrollArea
          className="w-full"
          style={{
            // 55 is the height of the row
            // 16 is the margin between rows
            height: !(size <= SHOW_MAX_ITEMS)
              ? `${Math.min(size, SHOW_MAX_ITEMS) * 62 + (Math.min(size, SHOW_MAX_ITEMS) - 1) * 16}px`
              : 'auto',
          }}
        >
          <div className="w-full flex flex-col gap-4 pr-4">
            {item.columns?.map((col: string, index: number) => {
              const checked = item?.selectedColumns?.[col] ?? false
              const dataType = item.columnDataTypes?.[col]

              const description = item?.descriptions?.[col] ?? ''
              const labelName = dataType ? `${col} (${dataType})` : col

              return (
                <RowCheckbox
                  checked={checked}
                  description={description}
                  key={item.name + index}
                  label={labelName}
                  onChange={() => {
                    handleUpdateColumnsChecked(
                      datasetId,
                      tableIndex,
                      col,
                      !checked,
                    )
                  }}
                />
              )
            })}
          </div>
        </ScrollArea>
      </div>
    </div>
  )
}

export default TableUpdateFormItem
