import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { useTrans } from '@/hooks/useTranslation'

import DataWarehouseCreateLayout from '../dataWarehouseCreateLayout/dataWarehouseCreateLayout'
import { KnowledgeList, QuestionList } from './components'
import { QuestionAndKnowledgeProps } from './questionAndKnowledge.props'
import { useQuestionAndKnowledgeSchema } from './questionAndKnowledge.validate'

const QuestionAndKnowledge = (props: QuestionAndKnowledgeProps) => {
  const { form } = props
  const { t } = useTrans()

  return (
    <div className="w-full">
      <Accordion
        className="w-full"
        collapsible
        defaultValue={'questions'}
        type="single"
      >
        <AccordionItem className="mb-6" key={'questions'} value={'questions'}>
          <AccordionTrigger>{t('questionsWarehouse')}</AccordionTrigger>
          <AccordionContent>
            <QuestionList datasetId={'dataset_id'!} form={form} pid={'pid'!} />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem className="mb-6" key={'knowledge'} value={'knowledge'}>
          <AccordionTrigger>{t('knowledgeWarehouse')}</AccordionTrigger>
          <AccordionContent>
            <KnowledgeList datasetId={'dataset_id'!} form={form} pid={'pid'!} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

const QuestionAndKnowledgeContainer = () => {
  const { t } = useTrans()
  const { schema } = useQuestionAndKnowledgeSchema()

  return (
    <DataWarehouseCreateLayout
      schema={schema}
      title={t('titleDataWarehouseName')}
    >
      {(props) => <QuestionAndKnowledge {...props} />}
    </DataWarehouseCreateLayout>
  )
}

export default QuestionAndKnowledgeContainer
