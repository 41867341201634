import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import {
  setCurrentProject,
  uploadProject,
} from '@/features/project/projectSlice'
import { isAuth } from '@/helpers/auth'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types'

import { useProjectEditContext } from './projectEdit.context'

const useProjectEdit = () => {
  const dispatch = useAppDispatch()
  const project = useAppSelector((state) => state?.project?.currentProject)

  const { t } = useTrans()
  const { location, params, navigate } = useRouter()
  const { form, setFilenameThumbnail, filenameThumbnail } =
    useProjectEditContext()

  const pid = params.pid
  const author = isAuth().email

  const isCreateNewProject = useMemo(() => {
    return location.pathname === '/project/new'
  }, [location.pathname])

  useEffect(() => {
    if (!pid) return

    dispatch(setCurrentProject(pid))
  }, [dispatch, pid])

  useEffect(() => {
    if (form && author) {
      form.setValue('email', [author])
      form.setValue('author', author)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [author])

  useEffect(() => {
    if (project && pid) {
      setFilenameThumbnail(project.coverFile || '')
      form.setValue('name', project.name || '')
      form.setValue('description', project.description || '')
      form.setValue('email', project.users)
      form.setValue('author', project.author)
    }
  }, [form, pid, project, setFilenameThumbnail])

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  const onSubmit = form.handleSubmit(async (payload: any) => {
    const { thumbnail, name, description, email } = payload

    const data = new FormData()
    data.append('name', name)
    data.append('description', description || '')
    data.append('author', author)
    data.append('company', isAuth().company)
    data.append('users', email)
    if (pid) {
      data.append('pid', pid)
    }

    if (thumbnail) {
      data.append('file', thumbnail)
      data.append('coverFile', thumbnail.name)
    } else if (!filenameThumbnail || !pid) data.append('coverFile', '')

    const dataCreate = {
      data,
    }

    const promise = dispatch(uploadProject(dataCreate)).then((res: ANY) => {
      if (isCreateNewProject) {
        navigate(`/project/${res.pid}/dataset`)
      } else {
        navigate(`/projects`)
      }
    })

    toast.promise(promise, {
      pending: t('processingUpdateProject'),
      success: t('successUpdateProject'),
      error: t('errorUpdateProject'),
    })
  })

  return {
    isCreateNewProject,
    form,
    onSubmit,
  }
}

export default useProjectEdit
