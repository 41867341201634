import { Button } from '@/components/ui/button'
import {
  createKnowledge,
  updateKnowledge,
} from '@/features/project/projectSlice'
import { useTrans } from '@/hooks/useTranslation'

import KnowledgeFormItem from './knowledgeItem/knowledgeFormItem/knowledgeFormItem'
import KnowledgeItem from './knowledgeItem/knowledgeItem'
import { useKnowledgeList } from './knowledgeList.hook'
import { KnowledgeListProps } from './knowledgeList.props'

const KnowledgeList = (props: KnowledgeListProps) => {
  const { list } = props
  const { t } = useTrans()
  const {
    pid,
    isCreate,
    toggleCreate,
    handleSaveKnowledge,
    handleRemoveKnowledge,
  } = useKnowledgeList()

  return (
    <div className="w-full">
      <div className="w-full flex flex-col gap-6">
        {list.map((item, index) => {
          return (
            <KnowledgeItem
              content={item.knowledge}
              datasetId={props.datasetId}
              dispatchCallback={updateKnowledge}
              index={index}
              key={index}
              onDelete={() =>
                handleRemoveKnowledge({
                  index,
                  datasetId: props.datasetId,
                })
              }
              pid={pid}
              title={item.name}
            />
          )
        })}
      </div>
      {isCreate && (
        <KnowledgeFormItem
          defaultValues={{
            pid: pid,
            datasetId: props.datasetId,
            knowledge: '',
            index: list.length,
          }}
          dispatchCallback={createKnowledge}
          toggleEdit={toggleCreate}
        />
      )}
      <div className="w-full flex justify-end gap-6 mt-8">
        {!isCreate && (
          <Button onClick={toggleCreate} variant="link2">
            {t('addNewKnowledge')}
          </Button>
        )}

        <Button onClick={handleSaveKnowledge}>{t('save')}</Button>
      </div>
    </div>
  )
}

export default KnowledgeList
