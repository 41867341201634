import { TrashIcon } from '@radix-ui/react-icons'
import { useRef } from 'react'

import { Button } from '@/components/ui/button'
import { useTrans } from '@/hooks/useTranslation'

import ImagePreview from './imagePreview/imagePreview'
import useProjectThumbnail from './projectThumbnail.hook'

const ProjectThumbnail = () => {
  const { t } = useTrans()
  const ref_inputFile = useRef<HTMLInputElement | null>(null)
  const { coverFile, onRemoveThumbnail, onChangeCover } = useProjectThumbnail()

  return (
    <div className="w-full py-9">
      <div className="w-full flex flex-col md:flex-row gap-6 items-center">
        <div className="relative">
          <ImagePreview path={coverFile} />
          {coverFile && (
            <div
              className="absolute top-0 right-0 bg-red-200 p-2 rounded-full translate-x-[30%] -translate-y-[30%] cursor-pointer"
              onClick={() => {
                onRemoveThumbnail()
                if (ref_inputFile.current) {
                  ref_inputFile.current.value = ''
                }
              }}
            >
              <TrashIcon className="h-4 w-4 text-red-500" />
            </div>
          )}
        </div>

        <input
          accept="image/jpeg, image/png"
          hidden
          id="inputGroupFile01"
          onChange={onChangeCover}
          ref={ref_inputFile}
          type="file"
        />

        <div className="w-full justify-center md:justify-start flex-wrap flex items-center gap-4">
          <Button asChild variant="secondary">
            <label className="cursor-pointer" htmlFor="inputGroupFile01">
              {t('uploadNewPicture')}
            </label>
          </Button>
          {coverFile && (
            <Button
              onClick={() => {
                onRemoveThumbnail()
                if (ref_inputFile.current) {
                  ref_inputFile.current.value = ''
                }
              }}
              variant="outline"
            >
              <TrashIcon className="mr-2.5 h-4 w-4" />
              {t('Delete')}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProjectThumbnail
