import { Header } from '@/components/layout'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input, InputPassword } from '@/components/ui/input'
import { useTrans } from '@/hooks/useTranslation'

import useResetPassword from './resetPassword.hook'

const ResetPassword = () => {
  const { handleSubmit, form } = useResetPassword()
  const { t } = useTrans()

  return (
    <div className="bg-white h-screen">
      <Header />
      <div className="text-gray-900 flex justify-center pt-4">
        <div
          className="max-w-xl pt-10 pb-20 m-0 sm:m-20 bg-white shadow sm:rounded-lg 
        flex justify-center flex-1"
        >
          <div className="mt-4 flex flex-col items-center">
            <div className="w-full flex-1 mt-2 text-indigo-500">
              <p className="h2 w-full text-center mb-[16px]">
                {t('resetYourPassword')}
              </p>
              <div className="w-full flex-1 mt-5 text-indigo-500">
                <Form {...form}>
                  <form
                    className="mx-auto max-w-xs relative space-y-6"
                    onSubmit={handleSubmit}
                  >
                    <div className="space-y-4 w-full">
                      <FormField
                        control={form.control}
                        name="password1"
                        render={({ field }) => {
                          return (
                            <FormItem className="w-full">
                              <FormLabel className="caption2" requiredField>
                                {t('password')}
                              </FormLabel>
                              <FormControl>
                                <InputPassword
                                  {...field}
                                  className="h-[40px]"
                                  placeholder={t('placeholderPassword')}
                                  type="password"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )
                        }}
                      />
                      <FormField
                        control={form.control}
                        name="password2"
                        render={({ field }) => {
                          return (
                            <FormItem className="w-full">
                              <FormLabel className="caption2" requiredField>
                                {t('confirmPassword')}
                              </FormLabel>
                              <FormControl>
                                <Input
                                  {...field}
                                  className="h-[40px]"
                                  placeholder={t('placeholderPassword')}
                                  type="password"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )
                        }}
                      />
                    </div>

                    <div className="w-full flex justify-center">
                      <Button
                        className="min-w-[180px]"
                        type="submit"
                        variant="default"
                      >
                        {form.formState.isSubmitting
                          ? t('isSubmitting')
                          : t('submit')}
                      </Button>
                    </div>
                  </form>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
