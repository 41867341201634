import { FormField } from '@/components/ui/form'
import {
  AddQuestionFormItem,
  QUESTION_LIST_FORM_KEY,
  QuestionItem,
  QuestionListProvider,
} from '@/pages/dataWarehouse/dataWarehouseCreate/questionAndKnowledge'

import { useQuestionList } from './questionList.hook'
import { QuestionListContainerProps } from './questionList.props'

const QuestionList = () => {
  const { form, handleAddQuestion, questionFields, handleRemoveQuestion } =
    useQuestionList()

  return (
    <div className="w-full h-full space-y-6">
      <AddQuestionFormItem dispatchCallback={handleAddQuestion} />

      <div className="w-full flex flex-col gap-6">
        {questionFields.map((item, index: number) => {
          return (
            <FormField
              control={form.control}
              key={item.id}
              name={`${QUESTION_LIST_FORM_KEY.QUESTIONS}.${index}`}
              render={({ field }) => {
                return (
                  <QuestionItem
                    content={field.value?.content ?? field.value}
                    field={field}
                    index={index}
                    onDelete={() => {
                      handleRemoveQuestion(index)
                    }}
                  />
                )
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

const QuestionListContainer = (props: QuestionListContainerProps) => {
  return (
    <QuestionListProvider value={props}>
      <QuestionList />
    </QuestionListProvider>
  )
}

export default QuestionListContainer
