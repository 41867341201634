import { cn } from '@/lib/utils'

import { SidebarBodyProps, SidebarItemProps } from './sidebarBody.props'

const SideBarItem = (props: SidebarItemProps) => {
  const { icon, label, active } = props
  const classContainer = cn('w-full px-8 py-2 flex gap-6 cursor-pointer', {
    'bg-accent-1': active,
  })

  const classText = cn('h5 text-white', {
    'font-bold': active,
  })
  return (
    <div className={classContainer}>
      {icon && <>{icon}</>}
      <p className={classText}>{label}</p>
    </div>
  )
}

const SideBarBody = (props: SidebarBodyProps) => {
  const { children } = props
  return <div className="w-full mt-[12px]">{children}</div>
}

SideBarBody.displayName = 'SideBarBody'

SideBarBody.Item = SideBarItem
export default SideBarBody
