import { ChangeEvent, KeyboardEvent, useState } from 'react'

import { useProjectEditContext } from '../../projectEdit.context'
import addMemberValidate from './addMember.validate'

const useAddMember = () => {
  const { schema } = addMemberValidate()
  const { form } = useProjectEditContext()
  const [isAdding, setAdding] = useState(false)
  const [email, setEmail] = useState('')

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const onAddMember = () => {
    schema
      .validate({ email })
      .then((valid) => {
        const newEmail = valid.email
        const emailExist = form.getValues('email') || []
        if (emailExist.includes(newEmail)) {
          return form.setError('email', { message: 'Email exist' })
        }

        form.setValue('email', [...emailExist, newEmail])
        form.clearErrors('email')
        setAdding(false)
        setEmail('')
      })
      .catch((err) => {
        form.setError('email', { message: err.message })
      })
  }

  const onCancelAddMember = () => {
    setAdding(false)
    form.clearErrors('email')
  }

  return {
    isAdding,
    setAdding,
    onAddMember,
    onChangeInput,
    onCancelAddMember,
  }
}

export default useAddMember
