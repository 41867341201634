const ConsoleHelper = (data: unknown, obj = {}) => {
  //console.log("Mode: ", process.env.REACT_APP_NODE_ENV)
  if (import.meta.env.VITE_NODE_ENV === 'production') return
  // eslint-disable-next-line no-console
  if (obj !== undefined) console.log(data, obj)
  // eslint-disable-next-line no-console
  else console.log(data)
}

export default ConsoleHelper
