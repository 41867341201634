import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { gptModelKey } from '@/constants/common'
import { updateBasicInfoConfig } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { ANY } from '@/types'

import { UseBasicInfoFormProps } from './basicInfoForm.props'
import { useBasicInfoFormValidate } from './basicInfoForm.validate'

export const useBasicInfoForm = (props: UseBasicInfoFormProps) => {
  const { schema } = useBasicInfoFormValidate()
  const dispatch = useAppDispatch()
  const _props = useForm({
    defaultValues: props.defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = _props.handleSubmit((data: ANY) => {
    dispatch(updateBasicInfoConfig(data))
    props.toggleEdit()
  })

  return {
    form: _props,
    onSubmit,
  }
}
