import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useRouter } from '@/hooks/useRouter'
import { chatWebAppSocket } from '@/services/socket/chatWebApp/chatWebApp.socket'

import { IConversationContext } from './conversation.props'

const ConversationContext = createContext<IConversationContext | null>(null)

export const ConversationProvider = ({ children }: { children: ReactNode }) => {
  const { params } = useRouter()

  const [isHideSidebar, setHideSidebar] = useState<boolean>(false)
  const [projectId, setProjectId] = useState<string | undefined>(params.pid)

  const handleToggleSidebar = () => {
    setHideSidebar(!isHideSidebar)
  }

  useEffect(() => {
    chatWebAppSocket.connect()

    return () => {
      chatWebAppSocket.disconnect()
    }
  }, [])

  useEffect(() => {
    if (!params.pid) return
    setProjectId(params.pid)
  }, [params.pid])

  const value = {
    pid: projectId,
    isHideSidebar,
    handleToggleSidebar,
  }

  return (
    <ConversationContext.Provider value={value}>
      {children}
    </ConversationContext.Provider>
  )
}

export const useConversationContext = () => {
  const ctx = useContext(ConversationContext)
  if (!ctx) {
    throw new Error(
      'useConversationContext must be used within ConversationProvider',
    )
  }

  return { ...ctx }
}
