import { FIXED_COST } from '../usage.constant'
import { InteractionProps } from './interaction.props'

const Interaction = (props: InteractionProps) => {
  const { value, isShownCost } = props

  if (!value) return null

  return (
    <>
      {value.n_interaction}{' '}
      {isShownCost && `/ ${value.total_cost.toFixed(FIXED_COST)}`}
    </>
  )
}

export default Interaction
