import { CheckedState } from '@radix-ui/react-checkbox'
import clsx from 'clsx'
import { ReactNode } from 'react'

import Trash from '@/assets/trash.svg'
// import { Checkbox } from '@/components/ui/checkbox'

interface IProps {
  checked: boolean
  label: string | ReactNode
  isRowSelectALL?: boolean

  // eslint-disable-next-line no-unused-vars
  onChange: (checked: CheckedState) => void
  onRemove: () => void
  disableCheckbox?: boolean
}
const RowPhoneCheckbox = (props: IProps) => {
  const isTheRowSelectALL = props.isRowSelectALL
  const classNameLabel = clsx(
    'w-full flex justify-between items-center py-4 px-6 border rounded-lg border-border-1 gap gap-6 cursor-pointer',
    {
      '!border-0': isTheRowSelectALL,
      '!gap-2': isTheRowSelectALL,
    },
  )
  return (
    <label className={classNameLabel}>
      <div className="flex gap-6 items-center">
        {/* <Checkbox
          checked={props.checked}
          disabled={!props.disableCheckbox || false}
          onCheckedChange={props.onChange}
        /> */}
        <div>{props.label}</div>
      </div>
      <img
        alt="trash"
        className="w-full h-auto select-none cursor-pointer"
        onClick={props.onRemove}
        src={Trash as unknown as string}
        style={{ width: '14px' }}
      />
    </label>
  )
}

export default RowPhoneCheckbox
