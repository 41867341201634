import { ANY } from '@/types'

export function convertTextToLink(text: string) {
  // Define a regular expression to match [content](content_2) patterns
  const linkPattern = /\[(.*?)\]\((.*?)\)/g

  // Use the replace method to find and replace the patterns with anchor tags
  const replacedText = text.replace(
    linkPattern,
    '<a class="chat-message-link" target="_blank" href="$2">$1</a>',
  )

  return replacedText
}

export function detectLinksAndReplace(paragraph = '') {
  const aTagsRegex = /<a\b[^>]*>[\s\S]*?<\/a>/gi

  const aTags = paragraph.match(aTagsRegex)

  aTags?.forEach((a) => (paragraph = paragraph.replace(a, '[aTag-replaced]')))

  const urlPattern =
    // eslint-disable-next-line max-len
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g

  const urls = paragraph.match(urlPattern)

  if (urls) {
    urls.forEach((url) => {
      let _url = url
      // Check if the URL starts with "http://" or "https://", if not, add "http://"
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        _url = 'http://' + url
      }

      // Replace the URL with an HTML <a> tag
      const linkText = `<a target="_blank" class="chat-message-link" href="${_url}">${url}</a>`
      paragraph = paragraph.replace(url, linkText)
    })
  }

  const aTagReplaces = paragraph.match(/\[aTag-replaced\]/g)
  aTagReplaces?.forEach(
    (replaced, i) =>
      (paragraph = paragraph.replace(replaced, aTags?.[i] ?? '')),
  )

  return paragraph
}

export function detectEmailAndReplace(paragraph = '') {
  const regex = /[\w-.]+@([\w-]+\.)+[\w-]{2,4}/g

  const emails = paragraph.match(regex)

  emails?.forEach((email) => {
    const linkText = `<a class="chat-message-link" href="mailto:${email}" >${email}</a>`
    paragraph = paragraph.replace(email, linkText)
  })

  return paragraph
}

export function onFocusInputById(elementId: string) {
  const tagsNameAccept = ['INPUT', 'TEXTAREA']
  setTimeout(() => {
    const element = document.getElementById(elementId)
    if (!element || !tagsNameAccept.includes(element.tagName)) return
    const inputElement = element as HTMLInputElement

    inputElement.focus()
    const len = inputElement.value.length
    inputElement.selectionStart = len
    inputElement.selectionEnd = len
  }, 100)
}

export function onEnterPress(callback: (e?: ANY) => void) {
  return (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && event.shiftKey) return
    if (event.key === 'Enter') {
      event.preventDefault()
      callback(event)
    }
  }
}
