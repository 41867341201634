import { PersonIcon } from '@radix-ui/react-icons'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Input, InputPassword } from '@/components/ui/input'

import useRegister from './Register.hook'

const Register = () => {
  const { t } = useTranslation()
  const {
    isAcceptPrivacy,
    name,
    email,
    password1,
    password2,
    textChange,
    company,
    handleChange,
    handleSubmit,
    setAcceptPrivacy,
  } = useRegister()

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <div className="space-y-[16px]">
        <div className="space-y-[6px]">
          <label className="caption2" htmlFor="name">
            {t('name')}
          </label>
          <Input
            autoComplete="name"
            className="h-[40px]"
            id="name"
            onChange={handleChange('name')}
            placeholder={t('name')}
            type="text"
            value={name}
          />
        </div>
        <div className="space-y-[6px]">
          <label className="caption2" htmlFor="email">
            {t('email')}
          </label>
          <Input
            autoComplete="email"
            className="h-[40px]"
            id="email"
            onChange={handleChange('email')}
            placeholder={t('email')}
            type="email"
            value={email}
          />
        </div>
        <div className="space-y-[6px]">
          <label className="caption2" htmlFor="company">
            {t('company')}
          </label>
          <Input
            autoComplete="organization"
            className="h-[40px]"
            id="company"
            onChange={handleChange('company')}
            placeholder={t('company')}
            type="text"
            value={company}
          />
        </div>
        <div className="space-y-[6px] grow">
          <label className="caption2" htmlFor="new-password">
            {t('password')}
          </label>
          <InputPassword
            autoComplete="new-password"
            className="h-[40px]"
            height={40}
            id="new-password"
            onChange={handleChange('password1')}
            placeholder={t('password')}
            type="password"
            value={password1}
          />
        </div>
        <div className="space-y-[6px] grow">
          <label className="caption2" htmlFor="password2">
            {t('confirmPassword')}
          </label>
          <InputPassword
            autoComplete="off"
            className="h-[40px]"
            height={40}
            id="password2"
            onChange={handleChange('password2')}
            placeholder={t('confirmPassword')}
            type="password"
            value={password2}
          />
        </div>
      </div>
      <div className="flex space-x-2 mt-[48px]">
        <Checkbox
          checked={isAcceptPrivacy}
          className="mt-1"
          id="isAcceptPolicy"
          onClick={() => setAcceptPrivacy(!isAcceptPrivacy)}
        />
        <label
          className="caption1 font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          dangerouslySetInnerHTML={{
            __html: t('termsAndPrivacyRegister', {
              termsLink:
                // eslint-disable-next-line max-len
                'https://webapp-terms-condition-privacypolicy.s3.amazonaws.com/TERAMOT+-+Terms+and+Conditions.pdf',
              privacyLink:
                'https://webapp-terms-condition-privacypolicy.s3.amazonaws.com/Teramot+-+PrivacyPol.pdf',
              className: 'text-text-blue',
            }),
          }}
          htmlFor="isAcceptPolicy"
        ></label>
      </div>
      <div className="flex justify-center mt-[53px]">
        <Button
          className="min-w-[180px]"
          disabled={!isAcceptPrivacy}
          type="submit"
          variant="default"
        >
          <PersonIcon className="mr-2.5 h-4 w-4" /> {textChange}
        </Button>
      </div>
    </form>
  )
}

export default Register
