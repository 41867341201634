import { FormField } from '@/components/ui/form'
import {
  AddKnowledgeFormItem,
  KNOWLEDGE_LIST_FORM_KEY,
  KnowledgeItem,
  KnowledgeListProvider,
} from '@/pages/dataWarehouse/dataWarehouseCreate/questionAndKnowledge'

import { useKnowledgeList } from './knowledgeList.hook'
import { KnowledgeListContainerProps } from './knowledgeList.props'

const KnowledgeList = () => {
  const { form, handleAddKnowledge, knowledgeFields, handleRemoveKnowledge } =
    useKnowledgeList()

  return (
    <div className="w-full h-full space-y-6">
      <AddKnowledgeFormItem dispatchCallback={handleAddKnowledge} />

      <div className="w-full flex flex-col gap-6">
        {knowledgeFields?.map((item, index: number) => {
          return (
            <FormField
              control={form.control}
              key={item.id}
              name={`${KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE}.${index}`}
              render={({ field }) => {
                return (
                  <KnowledgeItem
                    content={field.value?.content ?? field.value}
                    field={field}
                    index={index}
                    onDelete={() => {
                      handleRemoveKnowledge(index)
                    }}
                  />
                )
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

const KnowledgeListContainer = (props: KnowledgeListContainerProps) => {
  return (
    <KnowledgeListProvider value={props}>
      <KnowledgeList />
    </KnowledgeListProvider>
  )
}

export default KnowledgeListContainer
