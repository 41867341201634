import { useTrans } from '@/hooks/useTranslation'
import yup from '@/validates'

const addMemberValidate = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTrans()

  return {
    schema: yup.object().shape({
      email: yup
        .string()
        .required(t('emailRequired'))
        .email(t('emailInvalid'))
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Email is not valid domain',
        ),
    }),
  }
}

export default addMemberValidate
