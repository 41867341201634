function IcLogoWhite() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
        d="M8.5 3.834v9.333M3.833 8.5h9.334"
      ></path>
    </svg>
  )
}

export default IcLogoWhite
