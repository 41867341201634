import { isAuth } from '@/helpers/auth'
import { IProject } from '@/types'

export const useAuthorProject = () => {
  const author = isAuth().email
  const isAdmin = isAuth().role === 'admin'

  const isAuthorProject = (project: IProject) => {
    return (
      (author === project.author ||
        project.users?.includes(author) ||
        isAdmin) ??
      false
    )
  }

  return {
    isAuthorProject,
  }
}
