import { useTranslation } from 'react-i18next'

import PageHeader from '@/components/pageHeader/pageHeader'
import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'

import BasicInfo from '../components/basicInfo/basicInfo'
import KnowledgeGeneralList from '../components/knowledgeGeneralList/knowledgeGeneralList'

const TuningGeneral = () => {
  const { params } = useRouter()
  const { t } = useTrans()
  const { pid, dataset_id } = params
  const { project } = useGetProject({ pid: pid! })
  const configuration = project?.configuration
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language

  const knowledgeGeneral =
    configuration?.knowledgeGeneral?.map((item) => {
      return {
        name: t('generalKnowledge'),
        knowledge: item,
      }
    }) ?? []

  const botName = configuration?.botName
  const lang = configuration?.lang ?? currentLanguage?.split('-')?.[0] ?? 'en'

  return (
    <PageHeader haveSubSidebar subTitle={t('subTitleTuningGeneral')}>
      <div className="space-y-4">
        <BasicInfo botName={botName ?? ''} lang={lang} pid={pid!} />
        <KnowledgeGeneralList
          configuration={configuration!}
          datasetId={dataset_id!}
          list={knowledgeGeneral}
          pid={pid!}
          project={project!}
        />
      </div>
    </PageHeader>
  )
}

export default TuningGeneral
