import { EETLStatus } from '@/types'

import { useGetCurrentProject } from '../useGetCurrentProject/useGetCurrentProject'

export const useWarehouseSomeStatus = (listStatus: Array<EETLStatus>) => {
  const { warehouse } = useGetCurrentProject()
  return {
    status: warehouse?.some((wh) => {
      return listStatus.includes(wh.etl?.status) || wh.singleData
    }),
  }
}
