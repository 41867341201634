import { useState } from 'react'

import { useDebounce } from '@/hooks/useDebounce'
import { ANY } from '@/types'

import { useTableFormFactoryContext } from '../../../tableFormFactory.context'
import { UseUpdateInfoFormProps } from './updateInfoForm.props'

export const useUpdateInfoForm = (props: UseUpdateInfoFormProps) => {
  const [formData, setFormData] = useState({
    displayName: props.displayName,
    description: props.description,
  })

  const { handleUpdateInfoTable } = useTableFormFactoryContext()

  const onChange = useDebounce((key: string, e: ANY) => {
    setFormData((old) => {
      return {
        ...old,
        [key]: e.target.value,
      }
    })
  }, 200)

  const handleSubmit = (e: ANY) => {
    e.preventDefault()
    e.stopPropagation()
    handleUpdateInfoTable(props.tableIndex, formData)
    // props.handleUpdateDescription(formData)
    props.onClose()
  }

  return {
    formData,
    onChange,
    handleSubmit,
  }
}
