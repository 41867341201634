import { useEffect } from 'react'

import { CHATBOT_SOCKET_EMIT } from '@/constants/common'
import { useRouter } from '@/hooks/useRouter'
import { chatBotSocket } from '@/services/socket/chatbot.socket'
import { ANY } from '@/types'

import { useChatBotContext } from '../chatbot.context'

export const useConnectChatbot = () => {
  const { setMessages } = useChatBotContext()

  const { params } = useRouter()

  const clientId = localStorage.getItem('clientId')
  const { pid } = params
  useEffect(() => {
    if (chatBotSocket.isConnected) return

    const listenAfterConnect = () => {
      chatBotSocket.sendMessage(
        CHATBOT_SOCKET_EMIT.CHAT_HISTORY,
        { clientId, pid: pid },
        (pendingMessages: ANY) => {
          if (pendingMessages.length > 0) {
            setMessages(pendingMessages)
          }
        },
      )
    }
    chatBotSocket.connect(listenAfterConnect)

    return () => {
      chatBotSocket.disconnect()
    }
  }, [])
  return {}
}
