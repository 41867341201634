import { useCallback, useContext, useState } from 'react'
import { createContext } from 'react'

import { UPDATE_TABLE_STEP } from './updateTable.constant'
import { IUpdateTableContext, IUpdateTableProvider } from './updateTable.props'

export const UpdateTableContext = createContext<IUpdateTableContext | null>(
  null,
)

export const UpdateTableProvider = ({ children }: IUpdateTableProvider) => {
  const [currentStep, setStep] = useState<UPDATE_TABLE_STEP>(
    UPDATE_TABLE_STEP.VIEW_UPDATE,
  )

  const handleChangeStep = useCallback((step: UPDATE_TABLE_STEP) => {
    setStep(step)
  }, [])

  return (
    <UpdateTableContext.Provider
      value={{
        currentStep,
        handleChangeStep,
      }}
    >
      {children}
    </UpdateTableContext.Provider>
  )
}

export const useUpdateTableContext = () => {
  const ctx = useContext(UpdateTableContext)

  if (!ctx)
    throw new Error(
      'useDatasetCreate must be used within a DatasetCreateProvider',
    )

  return {
    currentStep: ctx.currentStep,
    handleChangeStep: ctx.handleChangeStep,
  }
}
