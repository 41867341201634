import EmptyData from '@/components/emptyData/emptyData'
import { Button } from '@/components/ui/button'
import { useTrans } from '@/hooks/useTranslation'

import { useDatasetEmpty } from './datasetEmpty.hook'

const DatasetEmpty = () => {
  const { t } = useTrans()
  const { goToCreateDataset } = useDatasetEmpty()

  return (
    <EmptyData
      subTitle={t('subTitleEmptyDataset')}
      title={t('titleEmptyDataset')}
    >
      <div className="inline-block">
        <Button onClick={goToCreateDataset} variant="secondary">
          {t('goToCreateDataset')}
        </Button>
      </div>
    </EmptyData>
  )
}

export default DatasetEmpty
