import { ANY, IProcessedData, ITable } from '@/types'

export class WarehouseBuildSource {
  private table: IProcessedData
  constructor(table: IProcessedData) {
    this.table = table
  }

  private get listHasSelected(): Array<[string, boolean]> {
    return this.table.columns.map<[string, boolean]>((column) => [
      column.name,
      column.selected || false,
    ])
  }

  private isHasSelected(listSelected: Array<[string, boolean]>): boolean {
    return listSelected.length > 0
  }

  get descriptions(): Record<string, string> {
    return this.table.columns.reduce<Record<string, string>>((acc, column) => {
      acc[column.name] = column.description
      return acc
    }, {})
  }

  get columnDataTypes(): Record<string, string> {
    return this.table.columns.reduce<Record<string, string>>((acc, column) => {
      acc[column.name] = ''
      return acc
    }, {})
  }

  get selectedColumns(): Record<string, boolean> {
    return this.table.columns.reduce<Record<string, boolean>>((acc, column) => {
      acc[column.name] = true
      return acc
    }, {})
  }

  private get getTable(): ITable {
    const listSelected = this.listHasSelected
    const cols = listSelected.map<string>(([col]) => col)
    return {
      table_id: this.table.id,
      columns: cols,
      descriptions: this.descriptions,
      columnDataTypes: this.columnDataTypes,
      name: this.table.name,
      selectedColumns: this.selectedColumns,
    }
  }

  public buildSource(): {
    isHasSelected: boolean
    meta: { table: ITable }
  } {
    return {
      isHasSelected: this.isHasSelected(this.listHasSelected),
      meta: {
        table: this.getTable,
      },
    }
  }
}

export class WarehouseBuildTable {
  private dataset: IProcessedData
  constructor(dataset: IProcessedData) {
    this.dataset = dataset
  }

  public sources(): Array<ITable> {
    const newSource = new WarehouseBuildSource(this.dataset).buildSource()
    if (newSource.isHasSelected) {
      return [newSource.meta.table]
    }
    return []
  }
}

export class WarehouseBuildDataset {
  private listDataset: Array<IProcessedData>
  constructor(listDataset: Array<IProcessedData>) {
    this.listDataset = listDataset
  }

  public buildDataset(): Array<ANY> {
    return this.listDataset.reduce<Array<ANY>>((acc, dataset) => {
      const newWarehouseBuildTable = new WarehouseBuildTable(dataset)
      const tables = newWarehouseBuildTable.sources()
      if (tables.length > 0) {
        acc.push({
          input_id: dataset.id,
          name: dataset.name,
          description: dataset.id,
          tables: tables,
        })
      }

      return acc
    }, [])
  }
}
