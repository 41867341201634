import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'

import { EBillingStatus } from './billing.constant'
import { useGetBillingParams } from './billing.hook'
import BillingCanceled from './components/billingCanceled/billingCanceled'
import BillingForm from './components/billingForm/billingForm'
import BillingInfo from './components/billingInfo/billingInfo'
import BillingSuccess from './components/billingSuccess/billingSuccess'

const Billing = () => {
  const { params } = useRouter()
  const pid = params['pid']
  const { success, canceled } = useGetBillingParams()
  const { project } = useGetProject({ pid: pid! })
  if (success) {
    return <BillingSuccess />
  }
  if (canceled) {
    return <BillingCanceled />
  }

  const subscription = project?.subscription

  if (
    [EBillingStatus.ACTIVE, EBillingStatus.TRIALING].includes(
      subscription?.status as EBillingStatus,
    )
  ) {
    return <BillingInfo />
  }
  return <BillingForm />
}

export default Billing
