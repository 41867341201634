import { UPDATE_TABLE_STEP } from './updateTable.constant'
import { useUpdateTableContext } from './updateTable.context'

export const useChangeStepUpdateTable = () => {
  const { handleChangeStep } = useUpdateTableContext()
  const handleReplace = () => {
    handleChangeStep(UPDATE_TABLE_STEP.UPLOAD_FILE)
  }
  const handleViewUpdate = () => {
    handleChangeStep(UPDATE_TABLE_STEP.VIEW_UPDATE)
  }
  return {
    handleReplace,
    handleViewUpdate,
  }
}
