// import { useTranslation } from 'react-i18next'

import { useTrans } from '@/hooks/useTranslation'

const Footer = () => {
  const { t } = useTrans()
  return (
    <div className="container w-full">
      <div className="w-full flex justify-center space-x-[20px] py-3 border-t mt-[30px]">
        <a
          href="https://webapp-terms-condition-privacypolicy.s3.amazonaws.com/TERAMOT+-+Terms+and+Conditions.pdf"
          rel="noreferrer"
          target="_blank"
        >
          {t('termsAndConditions')}
        </a>
        <a
          href="https://webapp-terms-condition-privacypolicy.s3.amazonaws.com/Teramot+-+PrivacyPol.pdf"
          rel="noreferrer"
          target="_blank"
        >
          {t('privacyPolicy')}
        </a>
      </div>
    </div>
  )
}

export default Footer
