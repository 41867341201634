import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { BASE_URL } from '@/constants/common'
import { ERROR_MESSAGE } from '@/constants/errorCode'
import axios from '@/services/http/axiosInstance'

const useRegister = () => {
  const { t } = useTranslation()
  const [isAcceptPrivacy, setAcceptPrivacy] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    company: '',
    password1: '',
    password2: '',
    textChange: t('signUp'),
  })

  const { name, email, password1, password2, textChange, company } = formData

  const handleChange = (text: string) => (e: FormEvent<HTMLInputElement>) => {
    let value = (e.target as HTMLInputElement).value
    if (text === 'company')
      value = value.charAt(0).toUpperCase() + value.slice(1)
    if (text === 'email') value = value.toLowerCase()
    setFormData((prevForm) => ({ ...prevForm, [text]: value }))
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!isAcceptPrivacy) {
      toast.error('Please accept the terms before registering')
      return
    }

    if (name && email && password1) {
      if (password1 === password2) {
        setFormData((prevForm) => ({ ...prevForm, textChange: 'Submitting' }))
        axios
          .post(`${BASE_URL}/backend/register`, {
            email,
            name,
            company,
            password: password1,
          })
          .then((res) => {
            setFormData((prevForm) => ({
              ...prevForm,
              email: '',
              name: '',
              company: '',
              password1: '',
              password2: '',
              textChange: 'Submitted',
            }))

            toast.success(res.data.message)
          })
          .catch((err) => {
            setFormData((prevForm) => ({
              ...prevForm,
              textChange: 'Sign Up',
            }))
            if (err.response.data.errorCode) {
              const keyMesage = ERROR_MESSAGE[err.response.data.errorCode]
              if (keyMesage) toast.error(t(keyMesage, { email: email }))
              else toast.error(err.response.data.message)
              toast.error(err.response.data.error)
            } else if (err.response.data.errors)
              toast.error(err.response.data.errors)
            else if (err.response) {
              toast.error(
                'Error connecting to Server - ' +
                  err.response.status +
                  ' ' +
                  err.response.statusText,
              )
            } else {
              toast.error('Error: No connection to server. Contact support')
            }
          })
      } else {
        toast.error(t('errorMessagePasswordsDoNotMatch'))
      }
    } else {
      toast.error(t('fillAllFields'))
    }
  }

  return {
    isAcceptPrivacy,
    name,
    email,
    password1,
    password2,
    textChange,
    company,
    handleChange,
    handleSubmit,
    setAcceptPrivacy,
  }
}

export default useRegister
