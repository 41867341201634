import useChattingBody from './chattingBody.hook'
import ChattingMessage from './chattingMessage/chattingMessage'

const ChattingBody = () => {
  const { listMessage } = useChattingBody()

  return (
    <div className="grow">
      <div className="w-full flex flex-col gap-10">
        {listMessage.map((item, index) => (
          <ChattingMessage data={item} key={index} />
        ))}
      </div>
    </div>
  )
}

export default ChattingBody
