import { useTrans } from '@/hooks/useTranslation'
import yup from '@/validates'

export const useWhatsappChannelValidate = () => {
  const { t } = useTrans()

  return {
    schema: yup.object().shape({
      activateWhatsapp: yup.boolean(),
      activateBusiness: yup.boolean(),
      businessAllPhones: yup.boolean(),
      businessID: yup.string().when('activateBusiness', {
        is: true,
        then: () => yup.string().required(t('businessIdRequireActiveBusiness')),
      }),
      graphQLURL: yup.string().when('activateBusiness', {
        is: true,
        then: () => yup.string().required(t('graphQLURLRequireActiveBusiness')),
      }),
      graphQLToken: yup.string().when('activateBusiness', {
        is: true,
        then: () =>
          yup.string().required(t('graphQLTokenRequireActiveBusiness')),
      }),
      phoneNumbers: yup.array().of(
        yup.object().shape({
          phone: yup.string().required(t('phoneNumberRequired')),
          name: yup.string().required(t('nameRequired')),
          checked: yup.boolean().default(false),
        }),
      ),
    }),
  }
}
