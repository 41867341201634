import sessionStore, { SessionKey } from '@/helpers/sessionStore'
import { useTrans } from '@/hooks/useTranslation'
import useUnload from '@/hooks/useUnLoad/useUnLoad'

export const useQuestion = () => {
  const { t } = useTrans()

  // listen to the window unload event
  // if the user tries to leave the page, we will show a confirmation message
  useUnload((e) => {
    const confirmationMessage = t('unsavedChanges')
    const isNewQuestion =
      sessionStore.get(SessionKey.IS_NEW_QUESTION) === 'true'
    if (isNewQuestion) {
      e.preventDefault()
      e.returnValue = confirmationMessage
      return confirmationMessage
    }
  })
}
