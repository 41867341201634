import useGetBotName from '@/components/chatbot/chatbotHeader/useGetBotName.hook'

import { IChattingMessage } from '../chattingMessage.props'

const BotMessage = (props: Partial<IChattingMessage>) => {
  const { botName } = useGetBotName()
  const content = (props?.data?.text as string) || ''

  return (
    <div className="w-full flex gap-2">
      <div className="border shrink-0 w-[28px] h-[28px] rounded-full flex justify-center items-center">
        <img alt="Logo" className="h-[15px] shrink-0" src={'/favicon.ico'} />
      </div>
      <div className="w-full">
        <span className="text-base mb-4 font-bold">{botName}</span>
        <div
          className="w-full text-base flex flex-col gap-2 chat-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  )
}

export default BotMessage
