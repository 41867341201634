import { cn } from '@/lib/utils'

import OnboardingCover from '../OnboardingCover/OnboardingCover'
import { EmptyDataProps } from './emptyData.props'

const EmptyData = (props: EmptyDataProps) => {
  const { title, subTitle, className, ...restProps } = props
  return (
    <OnboardingCover backgroundVariant="variant3">
      <div className="relative w-full h-full flex flex-col items-center justify-center gap-12">
        <div className="w-full flex flex-col justify-center items-center gap-8">
          <h2 className="h2 text-center !text-white max-w-4xl md:h1">
            {title}
          </h2>
          <p className="h4 text-center !text-white md:h2">{subTitle}</p>
        </div>
        <div
          className={cn('flex justify-center', className)}
          {...restProps}
        ></div>
      </div>
    </OnboardingCover>
  )
}

export default EmptyData
