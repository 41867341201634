import { ScrollArea } from '@/components/ui/scroll-area'

import RowCheckbox from '../../rowCheckbox/rowCheckbox'
import { useTableFormFactoryContext } from '../tableFormFactory.context'
import { SHOW_MAX_ITEMS } from './tableFormItem.constant'
import { TableFormItemProps } from './tableFormItem.props'
const TableFormItem = (props: TableFormItemProps) => {
  const { item, tableIndex } = props

  const { handleUpdateColumnsChecked, handleUpdateDescription } =
    useTableFormFactoryContext()

  const size = item.columns?.length ?? 0

  return (
    <div>
      <div className="mt-4">
        <ScrollArea
          className="w-full"
          style={{
            // 55 is the height of the row
            // 16 is the margin between rows
            // minHeight: '400px',
            height: !(size <= SHOW_MAX_ITEMS)
              ? `${Math.min(size, SHOW_MAX_ITEMS) * 62 + (Math.min(size, SHOW_MAX_ITEMS) - 1) * 16}px`
              : 'auto',
          }}
        >
          <div className="w-full flex flex-col gap-4 pr-4">
            {item.columns?.map((col: string, index: number) => {
              const checked = item?.selectedColumns?.[col] ?? false
              const dataType = item.columnDataTypes[col]

              const description = item?.descriptions?.[col] ?? ''
              const labelName = dataType ? `${col} (${dataType})` : col

              return (
                <RowCheckbox
                  checked={checked}
                  description={description}
                  handleUpdateDescription={(description) => {
                    handleUpdateDescription(tableIndex, col, description)
                  }}
                  key={item.name + index}
                  label={labelName}
                  onChange={() => {
                    handleUpdateColumnsChecked(tableIndex, col, !checked)
                  }}
                />
              )
            })}
          </div>
        </ScrollArea>
      </div>
    </div>
  )
}

export default TableFormItem
