import { useRef, useState } from 'react'

import { Header } from '@/components/layout'
import { Separator } from '@/components/ui/separator'
import { useTrans } from '@/hooks/useTranslation'

import FormForgotPassword from './formForgotPassword/formForgotPassword'
import ModalSuccessForgotPassword from './modalSuccessForgotPassword/modalSuccessForgotPassword'

const ForgotPassword = () => {
  const ref_sendButton = useRef()
  const { t } = useTrans()
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)
  const onOpen = () => setIsOpen(true)

  return (
    <div className="bg-white h-screen">
      <Header />
      <div className="container max-w-[791px] py-[40px]">
        <div className="py-[32px] w-full">
          <p className="w-full text-center h4 text-text-gray">
            {t('forgotYourPassword')}
          </p>
        </div>
        <Separator />
        <div className="mx-auto max-w-[420px] flex flex-col items-center mt-[28px]">
          <div className="p-[24px] border-border border w-full rounded-[8px]">
            <FormForgotPassword onOpen={onOpen} ref={ref_sendButton} />
          </div>
        </div>
      </div>
      <ModalSuccessForgotPassword
        isOpen={isOpen}
        onClose={onClose}
        onResend={() => {
          if (!ref_sendButton.current) {
            return
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ;(ref_sendButton.current as any).onResend()
        }}
      />
    </div>
  )
}

export default ForgotPassword
