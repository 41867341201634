import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'

import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import yup from '@/validates'

import { useChatBotContext } from '../chatbot.context'
import {
  IMessageItemProps,
  MESSAGE_TYPE,
} from '../messageItem/messageItem.props'
import { useChatMessage } from './useChatMessage.hook'

const useChatbotBody = () => {
  const { params } = useRouter()
  const { configuration } = useGetProject({ pid: params.pid! })

  const isDisableChatbot = useMemo(() => {
    return Boolean(configuration?.botName)
  }, [configuration])

  const { messages, isShowMessage, setMessages, lang } = useChatBotContext()
  const { t } = useTrans({ config: { lng: lang } })

  const ref_listMessage = useRef<HTMLDivElement>(null)
  const isChatting = useMemo(
    () => messages?.some((e) => e.sender !== MESSAGE_TYPE.SYSTEM),
    [messages],
  )

  const form = useForm({
    resolver: yupResolver(
      yup.object().shape({ content: yup.string().required() }),
    ),
  })

  useEffect(() => {
    if (isShowMessage) handleScrollToBottom()
  }, [isShowMessage])

  const updateListMessage = (newMessage: IMessageItemProps) => {
    setMessages((pre) => [...pre, newMessage])
    setTimeout(() => handleScrollToBottom(), 100)
    form.setValue('content', '')
  }
  const { handleSendMessage } = useChatMessage({
    updateMessage: updateListMessage,
  })

  const onSubmit = form.handleSubmit(
    async (payload: Record<string, unknown>) => {
      const { content } = payload
      handleSendMessage(content as string)
    },
  )

  const onClickSystemMessageButton = async (
    messageSelected: Record<string, unknown>,
  ) => {
    const content = messageSelected.content || ''
    const newMessage = {
      text: content as string,
      sender: MESSAGE_TYPE.USER,
    }
    updateListMessage(newMessage)
  }

  const handleScrollToBottom = () => {
    if (!ref_listMessage.current) return

    ref_listMessage.current.scrollTo(0, ref_listMessage.current.scrollHeight)
  }

  return {
    t,
    isDisableChatbot,
    messages,
    form,
    ref_listMessage,
    onSubmit,
    isChatting,
    onClickSystemMessageButton,
  }
}

export default useChatbotBody
