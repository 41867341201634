import { ChangeEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useTrans } from '@/hooks/useTranslation'

import { useProjectEditContext } from '../projectEdit.context'

const useProjectThumbnail = () => {
  const { t } = useTrans()
  const { form, filenameThumbnail, setFilenameThumbnail } =
    useProjectEditContext()
  const [coverFile, setCoverFile] = useState<File | string | undefined>(
    undefined,
  )

  const onChangeCover = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0] || undefined
    const type = file?.type
    if (!type || !type.includes('image')) {
      toast.error(t('uploadImageFileTypeError'))
      return
    }
    setCoverFile(file)
    if (file) form.setValue('thumbnail', file)
  }

  useEffect(() => {
    setCoverFile(filenameThumbnail)
  }, [filenameThumbnail])

  const onRemoveThumbnail = () => {
    setCoverFile(undefined)
    form.setValue('thumbnail', null)
    setFilenameThumbnail('')
  }

  return {
    coverFile,
    setCoverFile,
    onChangeCover,
    onRemoveThumbnail,
  }
}

export default useProjectThumbnail
