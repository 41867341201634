import { TrashIcon } from '@radix-ui/react-icons'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { useTrans } from '@/hooks/useTranslation'
import { cn } from '@/lib/utils'

import { useChatBotContext } from '../chatbot.context'
import { useDeleteConversation } from './useDeleteConversation.hook'
import useGetBotName from './useGetBotName.hook'

const ChatBotHeader = () => {
  const { t } = useTrans()
  const { isShowMessage, newMessage, toggleShowMessage } = useChatBotContext()
  const { isDisableChatbot, handleDeleteConversation } = useDeleteConversation()
  const { botName } = useGetBotName()
  return (
    <div
      className={cn(
        'w-full flex gap-2 px-4 py-1 shadow-md cursor-pointer bg-white sticky left-0 top-0 z-10',
      )}
    >
      <div className="flex w-full" onClick={toggleShowMessage}>
        <div className="h-[40px] w-[40px] shrink-0 flex justify-center items-center">
          <img alt="Logo" className="w-[25px] h-auto" src={'/favicon.ico'} />
        </div>
        <div className="w-full flex justify-between items-center gap-3">
          <div className="flex items-center gap-2.5">
            <p className={cn('base1 font-bold text-black')}>{botName}</p>
            <div className="w-3 h-3 bg-accent-1 rounded-full"></div>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          {newMessage > 0 && (
            <div className="w-4 h-4 flex justify-center items-center bg-red-500 rounded-full shadow-xl">
              <p
                className={cn('text-xs font-bold pb-[2px]', {
                  'text-white': newMessage,
                  'text-black': !newMessage,
                })}
              ></p>
            </div>
          )}
        </div>
      </div>
      {isShowMessage && isDisableChatbot && (
        <AlertDialog>
          <AlertDialogTrigger
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <TrashIcon className="mr-2.5 h-5 w-5 text-accent-1 cursor-pointer" />
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                {t('titleWarningDeleteChatHistory')}
              </AlertDialogTitle>
              <AlertDialogDescription>
                {t('descriptionWarningDeleteChatHistory')}
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>{t('cancel')}</AlertDialogCancel>
              <AlertDialogAction onClick={handleDeleteConversation}>
                {t('delete')}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </div>
  )
}

export default ChatBotHeader
