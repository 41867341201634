import { Pencil1Icon, TrashIcon } from '@radix-ui/react-icons'

import IconTooltip from '@/components/iconTooltip'
import { onFocusInputById } from '@/helpers/common'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types'

import KnowledgeFormItem from './knowledgeFormItem/knowledgeFormItem'
import useKnowledgeItem from './knowledgeItem.hook'

interface IProps {
  title?: string
  content: string
  pid: string
  datasetId: string
  index: number
  dispatchCallback: ANY
  onDelete: ANY
}
const KnowledgeItem = (props: IProps) => {
  const { t } = useTrans()
  const { isEdit, toggleEdit, handDeleteKnowledge } = useKnowledgeItem({
    onDelete: props.onDelete,
  })

  return (
    <div className="w-full px-8 py-4 relative shadow-md rounded-md border">
      {!isEdit && (
        <div className="flex justify-end">
          <IconTooltip
            icon={
              <Pencil1Icon
                className="cursor-pointer absolute top-4 right-8"
                fontWeight={700}
                onClick={() => {
                  toggleEdit()
                  onFocusInputById(`focus-knowledge-${props.index}`)
                }}
              />
            }
            message={t('iconUpdateKnowledge')}
          />
          <IconTooltip
            icon={
              <TrashIcon
                className="cursor-pointer absolute top-11 right-8"
                fontWeight={700}
                onClick={handDeleteKnowledge}
              />
            }
            message={t('iconDeleteKnowledge')}
          />
        </div>
      )}
      <div className="w-full pr-6">
        {props.title && <p className="caption2 mb-1">{props.title}</p>}
        {!isEdit && props.content && (
          <p className="text-sm break-words">{props.content}</p>
        )}
        {isEdit && (
          <KnowledgeFormItem
            IdFocus={`focus-knowledge-${props.index}`}
            defaultValues={{
              pid: props.pid,
              datasetId: props.datasetId,
              knowledge: props.content,
              index: props.index,
            }}
            dispatchCallback={props.dispatchCallback}
            toggleEdit={toggleEdit}
          />
        )}
      </div>
    </div>
  )
}

export default KnowledgeItem
