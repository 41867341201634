import React, { ReactNode, useState } from 'react'

import { defaultLanguage } from '@/constants/common'
import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'
import { ELang } from '@/types'

import { IMessageItemProps } from './messageItem/messageItem.props'

interface ChatBotContextProps {
  lang: keyof typeof ELang
  messages: IMessageItemProps[]
  setMessages: React.Dispatch<React.SetStateAction<IMessageItemProps[]>>
  setNewMessage: React.Dispatch<React.SetStateAction<number>>
  newMessage: number
  isShowMessage: boolean
  toggleShowMessage: () => void
}

export const ChatBotContext = React.createContext<ChatBotContextProps | null>(
  null,
)

export const ChatBotProvider = ({ children }: { children: ReactNode }) => {
  const [newMessage, setNewMessage] = useState(0)
  const [isShowMessage, setIsShowMessage] = useState(false)
  const [messages, setMessages] = useState<IMessageItemProps[]>([])

  const { params } = useRouter()
  const { pid } = params

  const { configuration } = useGetProject({ pid: pid! })

  const toggleShowMessage = () => {
    setIsShowMessage((old) => !old)
    setNewMessage(0)
  }

  return (
    <ChatBotContext.Provider
      value={{
        messages,
        setMessages,
        setNewMessage,
        newMessage: newMessage,
        isShowMessage,
        toggleShowMessage,
        lang: (configuration?.lang as keyof typeof ELang) ?? defaultLanguage,
      }}
    >
      {children}
    </ChatBotContext.Provider>
  )
}

export const useChatBotContext = () => {
  const ctx = React.useContext(ChatBotContext)
  if (!ctx)
    throw new Error('ChatBotContext must be used within a ChatBotProvider')

  return ctx
}

export default ChatBotContext
