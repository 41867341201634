import { CheckedState } from '@radix-ui/react-checkbox'
import { Pencil1Icon } from '@radix-ui/react-icons'
import clsx from 'clsx'
import { ReactNode } from 'react'

import IconTooltip from '@/components/iconTooltip'
import { Checkbox } from '@/components/ui/checkbox'
import { onFocusInputById } from '@/helpers/common'
import { useToggle } from '@/hooks/useToggle'
import { useTrans } from '@/hooks/useTranslation'

import RowUpdateDescriptionForm from './rowUpdateDescriptionForm/rowUpdateDescriptionForm'

interface IProps {
  checked: boolean
  label: ReactNode | string
  isRowSelectALL?: boolean

  // eslint-disable-next-line no-unused-vars
  onChange: (checked: CheckedState) => void
  description?: string
  handleUpdateDescription?: (description: string) => void
  classNameContainer?: string
}
const RowCheckbox = (props: IProps) => {
  const isTheRowSelectALL = props.isRowSelectALL
  const { t } = useTrans()
  const classNameContainer = clsx(
    'py-4 px-6 border rounded-lg border-border-1 space-y-4 w-full',
    {
      '!border-0': isTheRowSelectALL,
      '!gap-2': isTheRowSelectALL,
    },
    props.classNameContainer,
  )
  const classNameLabel = clsx('w-full flex gap gap-6 cursor-pointer', {
    '!border-0': isTheRowSelectALL,
    '!gap-2': isTheRowSelectALL,
  })

  const { toggle, handleToggle } = useToggle()

  return (
    <div className={classNameContainer}>
      <label className={classNameLabel} onClick={(e) => e.stopPropagation()}>
        <Checkbox
          checked={props.checked}
          className="mt-1"
          onChange={(e) => e.stopPropagation()}
          onCheckedChange={props.onChange}
          onClick={(e) => e.stopPropagation()}
        />
        <div className="w-full flex justify-between gap-1">
          <div className="flex flex-col gap-2">
            <p>{props.label}</p>
            {props?.description && (
              <p>
                <span>Description: </span>
                {props.description}
              </p>
            )}
          </div>

          {!isTheRowSelectALL && (
            <div
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                handleToggle()
                onFocusInputById(('focus-' + props.label) as string)
              }}
            >
              <IconTooltip
                icon={<Pencil1Icon />}
                message={t('iconUpdateDescription')}
              />
            </div>
          )}
        </div>
      </label>
      {!isTheRowSelectALL && (
        <RowUpdateDescriptionForm
          IdFocus={('focus-' + props.label) as string}
          description={props.description!}
          handleUpdateDescription={props.handleUpdateDescription!}
          isOpen={toggle}
          onClose={handleToggle}
        />
      )}
    </div>
  )
}

export default RowCheckbox
