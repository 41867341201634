import { useEffect } from 'react'

import { ANY } from '@/types'

import { UseUpdateDatabaseViewProps } from './updateDatabaseView.props'

export const useUpdateDatabaseView = (props: UseUpdateDatabaseViewProps) => {
  const { form, detail } = props

  useEffect(() => {
    const _detail = detail as ANY
    form.reset({
      dbConfig: {
        dbName: _detail.dbName,
        dbType: _detail.dbType,
        host: _detail.host,
        user: _detail.user,
        port: _detail.port,
        pwd: _detail.pwd,
      },
      ..._detail,
    })
  }, [JSON.stringify(detail)])

  return {}
}
