import { useMemo } from 'react'
import { toast } from 'react-toastify'

import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { chatBotSocket } from '@/services/socket/chatbot.socket'
import { ANY } from '@/types'

import { useChatBotContext } from '../chatbot.context'

export const useDeleteConversation = () => {
  const { messages, setMessages } = useChatBotContext()
  const { t } = useTrans()
  const { params } = useRouter()

  const clientId = localStorage.getItem('clientId')
  const { pid } = params

  const { configuration } = useGetProject({ pid: params.pid! })

  const isDisableChatbot = useMemo(() => {
    return Boolean(configuration?.botName)
  }, [configuration])

  const handleDeleteConversation = () => {
    const size = messages.length
    if (size === 0) return
    chatBotSocket.sendMessage(
      'delete chat history',
      { clientId, pid },
      (response: ANY) => {
        if (response && response.success) {
          setMessages([])
          toast.success(t('chatHistoryDeleted'))
        } else {
          toast.error(t('errorDeletingChatHistory'))
        }
      },
    )
  }

  return { isDisableChatbot, handleDeleteConversation }
}
