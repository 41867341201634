import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Navigate } from 'react-router-dom'

import { useSession } from '@/contexts/sessionContext'
import { useTrans } from '@/hooks/useTranslation'

import useActive from './Activate.hook'

const Activate = () => {
  const { session } = useSession()
  const { name, handleSubmit } = useActive()
  const { t } = useTrans()

  return (
    <div className="min-h-full pt-4 text-gray-900 flex justify-center">
      {session ? <Navigate to="/projects" /> : null}
      <div className="flex justify-center flex-1">
        <div className="pt-10 pb-20 px-10 m-0 sm:m-20 bg-white shadow sm:rounded-lg">
          <div className="mt-4 flex flex-col items-center">
            <div className="w-full flex-1 mt-2 text-indigo-500">
              <h2 className="text-2xl xl:text-3xl font-extrabold">
                {t('welcome')} {name}
              </h2>

              <form
                className="w-full flex-1 mt-8 text-indigo-500"
                onSubmit={handleSubmit}
              >
                <div className="mx-auto max-w-xs relative ">
                  <button
                    className="mt-2 tracking-wide font-semibold bg-indigo-500 text-gray-100 
                  w-full py-2 rounded-lg hover:bg-indigo-700 transition-all duration-300 
                  ease-in-out flex items-center justify-center focus:shadow-outline 
                  focus:outline-none"
                    type="submit"
                  >
                    <FontAwesomeIcon className="w-6 ml-3" icon={faUserPlus} />
                    <span className="ml-3 mr-3">
                      {t('activateYourAccount')}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Activate
