import TableUpdateFormItem from '../tableUpdateFormItem/tableUpdateFormItem'
import { TableFormAccordingProps } from './tableUpdateFormAccording.props'

const TableFormAccording = (props: TableFormAccordingProps) => {
  const { list, datasetId } = props

  return (
    <TableUpdateFormItem datasetId={datasetId} item={list[0]} tableIndex={0} />
  )
}

export default TableFormAccording
