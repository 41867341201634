import { yupResolver } from '@hookform/resolvers/yup'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import projectEditValidate from './projectEdit.validate'

interface IProjectEditContext {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  form: any
  filenameThumbnail: string
  setFilenameThumbnail: Dispatch<SetStateAction<string>>
}
export const ProjectEditContext = createContext<IProjectEditContext | null>(
  null,
)

interface IProjectEditProvider {
  children: ReactNode
}
export const ProjectEditProvider = ({ children }: IProjectEditProvider) => {
  const { schema } = projectEditValidate()
  const [filenameThumbnail, setFilenameThumbnail] = useState<string>('')

  const form = useForm({
    resolver: yupResolver(schema),
  })

  return (
    <ProjectEditContext.Provider
      value={{ form, filenameThumbnail, setFilenameThumbnail }}
    >
      {children}
    </ProjectEditContext.Provider>
  )
}

export const useProjectEditContext = () => {
  const ctx = useContext(ProjectEditContext)

  if (!ctx)
    throw new Error(
      'useProjectEditContext must be used within a ProjectEditProvider',
    )

  return {
    form: ctx.form,
    filenameThumbnail: ctx.filenameThumbnail,
    setFilenameThumbnail: ctx.setFilenameThumbnail,
  }
}
