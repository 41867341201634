import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'

const useGetBotName = () => {
  const { params } = useRouter()
  const { t } = useTrans()
  const { pid } = params

  const { project } = useGetProject({ pid: pid! })

  const configuration = project?.configuration
  const botName = configuration?.botName ?? t('botName')

  return {
    botName: botName,
  }
}

export default useGetBotName
