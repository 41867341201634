import ExtraDataETL from '@/components/extraDataETL/extraDataETL'
import { useTrans } from '@/hooks/useTranslation'

import { DATASET_TYPE } from '../datasetUpdate.constant'
import DatasetUpdateLayout from '../datasetUpdateLayout/datasetUpdateLayout'
import { UPDATE_DATABASE_STEP } from './updateDatabase.constant'
import {
  UpdateDatabaseProvider,
  useUpdateDatabaseContext,
} from './updateDatabase.context'
import {
  useChangeStepUpdateDatabase,
  useUpdateDatabase,
} from './updateDatabase.hook'
import {
  UpdateABSDatasetProps,
  UpdateDatabaseProps,
} from './updateDatabase.props'
import { useUpdateConnectionSchema } from './updateDatabase.validate'
import UpdateDatabaseConnection from './updateDatabaseConnect/updateDatabaseConnect'
import UpdateDatabaseView from './updateDatabaseView/updateDatabaseView'

const UpdateDatabaseFactory = (props: UpdateDatabaseProps) => {
  const generateProps = props

  const { currentStep } = useUpdateDatabaseContext()

  switch (currentStep) {
    case UPDATE_DATABASE_STEP.VIEW_UPDATE:
      return <UpdateDatabaseView {...generateProps} />
    case UPDATE_DATABASE_STEP.CHANGE_DATABASE:
      return <UpdateDatabaseConnection {...generateProps} />
    default:
      return <UpdateDatabaseView {...generateProps} />
  }
}

const UpdateDatabaseContainer = ({ detail }: UpdateABSDatasetProps) => {
  const { schema } = useUpdateConnectionSchema()
  const { t } = useTrans()
  const { handleViewUpdate } = useChangeStepUpdateDatabase()
  const formatDbDetail = useUpdateDatabase({ detail })
  const cbSummit = () => {
    handleViewUpdate()
  }
  return (
    <DatasetUpdateLayout
      cbSubmit={cbSummit}
      datasetType={DATASET_TYPE.DATABASE}
      defaultValues={{
        ...formatDbDetail,
      }}
      description={t('reviewYourDatasetInformation')}
      extra={
        <ExtraDataETL
          id={formatDbDetail?.etl?.id}
          status={formatDbDetail?.etl?.status}
        />
      }
      name={detail.name}
      schema={schema}
    >
      {(props) => <UpdateDatabaseFactory {...props} detail={detail} />}
    </DatasetUpdateLayout>
  )
}

const UpdateDatabaseWrapCtx = ({ detail }: UpdateABSDatasetProps) => {
  if (!detail) return null

  return (
    <UpdateDatabaseProvider>
      <UpdateDatabaseContainer detail={detail} />
    </UpdateDatabaseProvider>
  )
}
export default UpdateDatabaseWrapCtx
