import PageHeader from '@/components/pageHeader/pageHeader'
import Spin from '@/components/spin/spin'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { useTrans } from '@/hooks/useTranslation'

import { DATA_WAREHOUSE_CREATE_STEP } from '../dataWarehouseCreate.constant'
import { useDataWarehouseCreateLayout } from './dataWarehouseCreateLayout.hook'
import { DataWarehouseCreateLayoutProps } from './dataWarehouseCreateLayout.props'

const DataWarehouseCreateLayout = (props: DataWarehouseCreateLayoutProps) => {
  const { title, children, schema, defaultValues } = props
  const { t } = useTrans()

  const { currentStep, onSubmit, form, isSubmitting, handlePreStep } =
    useDataWarehouseCreateLayout({
      schema,
      defaultValues,
    })

  return (
    <Form {...form}>
      <PageHeader haveSubSidebar subTitle={title}>
        <form className="w-full relative" onSubmit={onSubmit}>
          {children({ form })}
          <div className="flex justify-end gap-4 sticky bottom-0 left-0  mt-5">
            {/* Type is first step should dont show Pre */}
            {currentStep != DATA_WAREHOUSE_CREATE_STEP.NAME && (
              <Button
                className="min-w-[80px]"
                disabled={isSubmitting}
                onClick={() => handlePreStep()}
                type="button"
                variant={'secondary'}
              >
                {t('previous')}
              </Button>
            )}

            <Button
              className="min-w-[80px] flex gap-2"
              disabled={isSubmitting || (props.isDisabledNext ?? false)}
              type="submit"
            >
              {isSubmitting && <Spin className="size-4 mr-2" />}
              {t('next')}
            </Button>
          </div>
        </form>
      </PageHeader>
    </Form>
  )
}

export default DataWarehouseCreateLayout
