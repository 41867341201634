import cookie from 'js-cookie'

// Set in Cookie
export const setCookie = (key: string, value: string) => {
  if (typeof window !== 'undefined') {
    cookie.set(key, value, {
      // 1 Day
      expires: 7,
    })
  }
}
// remove from cookie
export const removeCookie = (key: string) => {
  if (typeof window !== 'undefined') {
    cookie.remove(key, {
      expires: 1,
    })
  }
}

// Get from cookie such as stored token
// Will be useful when we need to make request to server with token
export const getCookie = (key: string) => {
  if (typeof window !== 'undefined') {
    return cookie.get(key)
  }
}

// Set in localstorage
export const setLocalStorage = (key: string, value: unknown) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export const setStringToLocalStorage = (key: string, value: string) => {
  if (window !== undefined) {
    localStorage.setItem(key, value)
  }
}

// get from localstorage
export const getLocalStorage = (key: string, defaultValue = {}) => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem(key)) {
      return localStorage.getItem(key)
    } else {
      return defaultValue
    }
  } else {
    return defaultValue
  }
}

// Remove from localstorage
export const removeLocalStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key)
  }
}

// Authenticate user by passing data to cookie and localstorage during signin
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const authenticate = (response: any, next: any) => {
  setCookie('token', response.data.token)
  setStringToLocalStorage('clientId', response.data.user.email)
  setLocalStorage('user', response.data.user)
  next()
}

export const clearAuth = () => {
  removeCookie('token')
  removeLocalStorage('user')
  removeLocalStorage('clientId')
}

// Access user info from localstorage
export const isAuth = () => {
  if (typeof window !== 'undefined') {
    const cookieChecked = getCookie('token')
    if (cookieChecked) {
      if (localStorage.getItem('user')) {
        const data = JSON.parse(
          localStorage.getItem('user') ?? JSON.stringify({}),
        )
        data.nickName = data.email.match(/^([^@]*)@/)[1]
        return data
      } else {
        return false
      }
    }
  }
  return false
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const signout = (next: any) => {
  removeCookie('token')
  removeLocalStorage('user')
  next()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateUser = (response: any, next: any) => {
  if (typeof window !== 'undefined') {
    const auth = response.data
    localStorage.setItem('user', JSON.stringify(auth))
  }
  next()
}
