import { Button } from '@/components/ui/button'
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { onEnterPress } from '@/helpers/common'
import { useTrans } from '@/hooks/useTranslation'

import { useAddQuestionForm } from './addQuestionForm.hook'
import { AddQuestionFormProps } from './addQuestionForm.props'

const AddQuestionForm = (props: AddQuestionFormProps) => {
  const { form, onSubmit } = useAddQuestionForm(props)
  const { t } = useTrans()
  return (
    <Form {...form}>
      <form className="w-full mt-2 space-y-4" onSubmit={onSubmit}>
        <FormField
          control={form.control}
          name="question"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold">
                {t('typeYourQuestion')}
              </FormLabel>
              <Textarea
                placeholder={t('placeholderNewQuestion')}
                {...field}
                onKeyDown={onEnterPress(() => {
                  form.handleSubmit(onSubmit)()
                })}
                rows={3}
              />
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="w-full flex justify-end gap-2 mt-4">
          <Button type="submit">{t('add')}</Button>
        </div>
      </form>
    </Form>
  )
}

export default AddQuestionForm
