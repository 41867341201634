import { createContext, useCallback, useRef, useState } from 'react'

import { useCtx } from '@/hooks/useCtx'
import { useRouter } from '@/hooks/useRouter'
import { IWarehouse } from '@/types'

import { DATA_WAREHOUSE_CREATE_STEP } from './dataWarehouseCreate.constant'
import {
  getNextCreateDatasetFlow,
  isEndStepFlow,
  IStepMap,
} from './dataWarehouseCreate.helper'
import {
  IDatasetCreateProvider,
  IDataWarehouseCreateContext,
} from './dataWarehouseCreate.props'

export const DataWarehouseCreateContext =
  createContext<IDataWarehouseCreateContext | null>(null)

export const DataWarehouseCreateProvider = ({
  children,
}: IDatasetCreateProvider) => {
  const { params } = useRouter()
  const pid = params.pid as string

  const [currentStep, setStep] = useState<DATA_WAREHOUSE_CREATE_STEP>(
    DATA_WAREHOUSE_CREATE_STEP.NAME,
  )
  const historyStep = useRef<DATA_WAREHOUSE_CREATE_STEP[]>([])

  const [warehouseTable, setWarehouseTable] = useState<IWarehouse>()

  const isEndFlow = isEndStepFlow(currentStep as IStepMap)
  const resetCurrentStep = useCallback(() => {
    historyStep.current = []
    setWarehouseTable(undefined)
  }, [])

  const handleNextStep = useCallback(() => {
    const nextStep = getNextCreateDatasetFlow(currentStep as IStepMap)
    historyStep.current.push(currentStep)
    if (nextStep === DATA_WAREHOUSE_CREATE_STEP.NAME) {
      resetCurrentStep()
    }

    setStep(nextStep as DATA_WAREHOUSE_CREATE_STEP)
  }, [currentStep, resetCurrentStep])

  const handlePreStep = useCallback(() => {
    const preStep = historyStep.current.pop()

    setStep(preStep as DATA_WAREHOUSE_CREATE_STEP)
  }, [])

  const handleUpdateCurrentDataset = useCallback(
    (payload: Partial<IWarehouse>) => {
      setWarehouseTable((prev) => {
        return {
          ...(prev as IWarehouse),
          ...payload,
        }
      })
    },
    [],
  )

  return (
    <DataWarehouseCreateContext.Provider
      value={{
        pid,
        isEndFlow,
        warehouseTable: warehouseTable as IWarehouse,
        currentStep,
        handleNextStep,
        handlePreStep,
        handleUpdateCurrentDataset,
      }}
    >
      {children}
    </DataWarehouseCreateContext.Provider>
  )
}

export const useDataWarehouseCreateContext = () => {
  const ctx = useCtx<IDataWarehouseCreateContext>(DataWarehouseCreateContext)

  return {
    pid: ctx.pid,
    isEndFlow: ctx.isEndFlow,
    warehouseTable: ctx.warehouseTable,
    currentStep: ctx.currentStep,
    handleNextStep: ctx.handleNextStep,
    handlePreStep: ctx.handlePreStep,
    handleUpdateCurrentDataset: ctx.handleUpdateCurrentDataset,
  }
}
