import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { getProject, setTableFile } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types/common.type'

import { useChangeStepUpdateTable } from '../updateTable.hook'
import { IUseABSUpdateSpreadsheetProps } from './updateTableUploadFile.props'

export const useUpdateSpreadsheetFile = (
  props: IUseABSUpdateSpreadsheetProps,
) => {
  const { pid, iid, form } = props
  const [file, setFile] = useState<File | undefined>(undefined)
  const dispatch = useAppDispatch()
  const { t } = useTrans()
  const { handleViewUpdate } = useChangeStepUpdateTable()

  const onChangeUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _file = e.target.files?.[0]
    if (_file) {
      // set value check is upload file
      form.setValue('isUploadFile', _file?.name ? _file?.name : 'false')
      setFile(_file)
      return
    }
  }

  const uploadFileToServer = useCallback(
    async (file: File) => {
      if (!pid) return
      const data = new FormData()
      data.append('files', file)
      data.append('iid', iid)
      const promise = dispatch(
        setTableFile(pid, data, (val) => {
          console.log('process', val)
        }),
      )
      toast.promise(promise, {
        pending: t('processingData'),
        success: t('tableAddToProject'),
        error: t('errorCreateDataset'),
      })

      promise
        .then((project) => {
          project && pid && dispatch(getProject(pid))
          handleViewUpdate()
        })
        .catch((error) => {
          toast.error(error)
        })
    },
    [dispatch],
  )

  useEffect(() => {
    if (!file) return
    uploadFileToServer(file)
  }, [file])

  const overrideEventDefaults = (event: ANY) => {
    event.preventDefault()
    event.stopPropagation()
  }
  const handleDragAndDropFiles = (event: ANY) => {
    overrideEventDefaults(event)
    if (!event.dataTransfer) return
    handleFiles(event.dataTransfer.files)
  }
  const handleFiles = (fileList: ANY) => {
    if (fileList) {
      const files = Array.from(fileList)
      setFile(files[0] as ANY)
    }
  }

  return {
    file,
    onChangeUploadFile,
    handleDragAndDropFiles,
    overrideEventDefaults,
  }
}

export const useUpdateSpreadsheetOnlineLink = (
  props: IUseABSUpdateSpreadsheetProps,
) => {
  const { t } = useTrans()
  const { pid, iid, form } = props
  const { handleViewUpdate } = useChangeStepUpdateTable()

  const dispatch = useAppDispatch()
  const handleUploadWithLink = () => {
    const linkUrl = form.getValues('fileLink')
    const meta = {
      linkUrl,
      iid,
    }

    const promise = dispatch(
      setTableFile(pid!, meta, (val) => {
        console.log('process', val)
      }),
    )
    toast.promise(promise, {
      pending: t('processingData'),
      success: t('tableAddToProject'),
      error: t('errorCreateDataset'),
    })

    promise
      .then((project) => {
        project && pid && dispatch(getProject(pid))
        handleViewUpdate()
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  return {
    handleUploadWithLink,
  }
}
