import { useTrans } from '@/hooks/useTranslation'
import yup from '@/validates'

export const useFormAddEmailValidate = () => {
  const { t } = useTrans()

  return {
    schema: yup.object().shape({
      email: yup.string().email().required(t('emailRequired')),
      name: yup.string().required(t('nameRequired')),
    }),
  }
}
