import { Button } from '@/components/ui/button'
import { useProject } from '@/contexts/projectContext'
import { removeQuestion } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useTrans } from '@/hooks/useTranslation'

import QuestionItem from './questionItem/questionItem'
import { useQuestionList } from './questionList.hook'

const QuestionList = () => {
  const { t } = useTrans()
  const dispatch = useAppDispatch()
  const {
    pid,
    datasetId,
    listQuestion,
    sizeListQuestion,
    handleSaveQuestionToDB,
  } = useQuestionList()
  const { toggleFlagDataPageQuestion } = useProject()

  return (
    <div className="w-full">
      <div className="w-full flex flex-col gap-6">
        {listQuestion.map((item, index) => {
          return (
            <QuestionItem
              content={item.question}
              index={index}
              key={index}
              onDelete={() => {
                dispatch(removeQuestion({ pid, index, datasetId: datasetId }))
                toggleFlagDataPageQuestion()
              }}
              pid={pid!}
              title={item.name}
            />
          )
        })}
      </div>
      {sizeListQuestion > 0 && (
        <div className="w-full flex justify-end gap-6 mt-8">
          <Button onClick={handleSaveQuestionToDB}>{t('next')}</Button>
        </div>
      )}
    </div>
  )
}

export default QuestionList
