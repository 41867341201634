import { useTrans } from '@/hooks/useTranslation'
import yup from '@/validates'

export const useBasicInfoFormValidate = () => {
  const { t } = useTrans()

  return {
    schema: yup.object().shape({
      botName: yup.string().required(t('botNameRequired')),
    }),
  }
}
