import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'

import { IConversationChattingContext } from './conversationChatting.props'

const ConversationChattingContext =
  createContext<IConversationChattingContext | null>(null)

export const ConversationChattingProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [listMessage, setListMessage] = useState<Record<string, unknown>[]>([])

  const pushNewMessage = useCallback((message: Record<string, unknown>) => {
    setListMessage((old) => [...old, message])
  }, [])

  const value = {
    listMessage,
    setListMessage,
    pushNewMessage,
  }

  return (
    <ConversationChattingContext.Provider value={value}>
      {children}
    </ConversationChattingContext.Provider>
  )
}

export const useConversationChattingContext = () => {
  const ctx = useContext(ConversationChattingContext)
  if (!ctx) {
    throw new Error(
      'useConversationChattingContext must be used within ConversationChattingProvider',
    )
  }

  return { ...ctx }
}
