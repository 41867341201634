import { DATA_WAREHOUSE_CREATE_STEP } from './dataWarehouseCreate.constant'

const NextStepMap = {
  [DATA_WAREHOUSE_CREATE_STEP.NAME]: DATA_WAREHOUSE_CREATE_STEP.SELECT_INPUT,
  [DATA_WAREHOUSE_CREATE_STEP.SELECT_INPUT]:
    DATA_WAREHOUSE_CREATE_STEP.QUESTION_AND_KNOWLEDGE,
  [DATA_WAREHOUSE_CREATE_STEP.QUESTION_AND_KNOWLEDGE]:
    DATA_WAREHOUSE_CREATE_STEP.NAME,
}

export type IStepMap =
  | DATA_WAREHOUSE_CREATE_STEP.NAME
  | DATA_WAREHOUSE_CREATE_STEP.SELECT_INPUT
  | DATA_WAREHOUSE_CREATE_STEP.QUESTION_AND_KNOWLEDGE

export const isEndStepFlow = (step: IStepMap) => {
  return step === DATA_WAREHOUSE_CREATE_STEP.QUESTION_AND_KNOWLEDGE
}

export const getNextCreateDatasetFlow = (step: IStepMap) => {
  return NextStepMap[step]
}
