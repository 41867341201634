import PageHeader from '@/components/pageHeader/pageHeader'
import { useProject } from '@/contexts/projectContext'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'

import AddQuestionForm from './components/addQuestionForm/addQuestionForm'
import QuestionList from './components/questionList/questionList'
import { useQuestion } from './question.hook'

const Question = () => {
  const { params } = useRouter()
  const { t } = useTrans()
  const { pid, dataset_id } = params
  const { toggleFlagDataPageQuestion } = useProject()
  useQuestion()

  return (
    <div className="w-full grow flex h-full">
      <PageHeader subTitle={t('subTitleQuestion')} title={t('titleQuestion')}>
        <div className="space-y-16">
          <AddQuestionForm
            callback={() => {
              toggleFlagDataPageQuestion()
            }}
            defaultValues={{
              pid: pid!,
              datasetId: dataset_id!,
              question: '',
            }}
          />

          <QuestionList />
        </div>
      </PageHeader>
    </div>
  )
}

export default Question
