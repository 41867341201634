import { PersonIcon } from '@radix-ui/react-icons'

import { IChattingMessage } from '../chattingMessage.props'

const UserMessage = (props: Partial<IChattingMessage>) => {
  const content = (props?.data?.text as string) || ''

  return (
    <div className="w-full flex gap-2">
      <div className="shrink-0 w-[28px] h-[28px] rounded-full flex justify-center items-center bg-text-blue">
        <PersonIcon className="w-4 h-4 text-white" />
      </div>
      <div className="w-full">
        <span className="text-base mb-4 font-bold">You</span>
        <div
          className="w-full text-base flex flex-col gap-2 chat-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  )
}

export default UserMessage
