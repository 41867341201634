import { IcTeramotProps } from './icTeramot.props'

const IcTeramot = (props: IcTeramotProps) => {
  return (
    <div
      className={props.className}
      style={{
        width: props.width ?? 40,
        height: props.height ?? 40,
      }}
    >
      <img
        alt="IC Teramot"
        className="w-full h-full object-contain"
        src="/favicon-32x32.png"
      />
    </div>
  )
}

export default IcTeramot
