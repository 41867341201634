import { useTrans } from '@/hooks/useTranslation'
import { useWarningBeforeLeave } from '@/hooks/useValidateCreateDatasetBeforeLeave'

import { DATASET_TYPE } from '../datasetCreate.constant'
import { UseCreateNameLeavePage } from './updateName.props'

export const useGetDataSource = () => {
  const { t } = useTrans()
  const dataSources = [
    {
      type: DATASET_TYPE.SPREADSHEET,
      title: t('spreadsheet'),
    },
    {
      type: DATASET_TYPE.DATABASE,
      title: t('database'),
    },
  ]
  return { dataSources }
}

export const useCreateNameLeavePage = (props: UseCreateNameLeavePage) => {
  const { form, meta } = props

  const validate = () => {
    const name = form.getValues('name')
    const description = form.getValues('description')
    const type = form.getValues('type')

    return name || description || type || Object.keys(meta ?? {}).length > 0
  }

  const { blocker } = useWarningBeforeLeave({
    validate,
  })

  return { blocker }
}
