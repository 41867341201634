import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { tiersOptions } from '@/constants/common'
import { setSubscription } from '@/features/project/projectSlice'
import { isAuth } from '@/helpers/auth'
import { useAppDispatch } from '@/hooks'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'

export const useBillingForm = () => {
  const [packageActive, setPackageActive] = useState(0)
  const [freeTrial, setFreeTrial] = useState(false)
  const { params } = useRouter()
  const { email } = isAuth()
  const dispatch = useAppDispatch()

  const pid = params['pid']
  const onChoosePackage = (index: number) => {
    setPackageActive(index)
    index !== 0 && setFreeTrial(false)
  }

  const onChangeFreeTrial = (value: boolean) => {
    setFreeTrial(value)
  }

  const handleTeramotCheckout = () => {
    const data = {
      pid: pid,
      tierName: tiersOptions[packageActive].key,
      email: email,
      status: 'active',
      sessionId: 'teramot',
    }
    dispatch(setSubscription(pid!, data)).catch(() => {
      toast.error("Your subscription couldn't be created")
    })
  }

  return {
    packageActive,
    freeTrial,
    onChoosePackage,
    onChangeFreeTrial,
    handleTeramotCheckout,
  }
}

export const useGetTiersOptions = () => {
  const { t } = useTrans()
  const tiersOptionsTranslated = useMemo(() => {
    return [
      {
        key: tiersOptions[0].key,
        name: t('tier.explorer.name'),
        list: t('tier.explorer.list').split(';'),
        pricing: tiersOptions[0].pricing,
        usersLimit: tiersOptions[0].usersLimit,
        inputLimit: tiersOptions[0].inputLimit,
        questionsLimit: tiersOptions[0].questionsLimit,
      },
      {
        key: tiersOptions[1].key,
        name: t('tier.pro.name'),
        list: t('tier.pro.list').split(';'),
        pricing: tiersOptions[1].pricing,
        usersLimit: tiersOptions[1].usersLimit,
        inputLimit: tiersOptions[1].inputLimit,
        questionsLimit: tiersOptions[1].questionsLimit,
      },
      {
        key: tiersOptions[2].key,
        name: t('tier.business.name'),
        list: t('tier.business.list').split(';'),
        pricing: tiersOptions[2].pricing,
        usersLimit: tiersOptions[2].usersLimit,
        inputLimit: tiersOptions[2].inputLimit,
        questionsLimit: tiersOptions[2].questionsLimit,
      },
    ]
  }, [])

  return { tiersOptions: tiersOptionsTranslated }
}
