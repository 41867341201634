import ExtraDataETL from '@/components/extraDataETL/extraDataETL'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useTrans } from '@/hooks/useTranslation'

import { DataWarehouseUpdateProvider } from './dataWarehouseUpdate.context'
import { useDataWarehouseUpdate } from './dataWarehouseUpdate.hook'
import { useDataWarehouseSchema } from './dataWarehouseUpdate.validate'
import { DataWarehouseUpdateLayout } from './dataWarehouseUpdateLayout'
import { KnowledgeList } from './knowledgeList'
import { QuestionList } from './questionList'
import UpdateBasic from './updateBasic/updateBasic'

const DataWarehouseUpdate = () => {
  const { t } = useTrans()
  return (
    <Tabs className="w-full" defaultValue="basicInfo">
      <TabsList className="flex justify-start w-full space-x-2">
        <TabsTrigger value="basicInfo">{t('basicInfo')}</TabsTrigger>
        <TabsTrigger value="question">{t('questions')}</TabsTrigger>
        <TabsTrigger value="knowledge">{t('knowledge')}</TabsTrigger>
      </TabsList>
      <TabsContent value="basicInfo">
        <UpdateBasic />
      </TabsContent>
      <TabsContent value="question">
        <QuestionList />
      </TabsContent>
      <TabsContent value="knowledge">
        <KnowledgeList />
      </TabsContent>
    </Tabs>
  )
}

const DataWarehouseUpdateContainer = () => {
  const { t } = useTrans()
  const { schema } = useDataWarehouseSchema()
  const { warehouse } = useDataWarehouseUpdate()
  if (!warehouse) return null

  return (
    <DataWarehouseUpdateLayout
      defaultValues={{ ...warehouse }}
      description={t('titleDataWarehouseName')}
      extra={
        <ExtraDataETL id={warehouse?.etl?.id} status={warehouse?.etl?.status} />
      }
      name={warehouse?.name || ''}
      schema={schema}
    >
      {(props) => (
        <DataWarehouseUpdateProvider {...props} warehouse={warehouse!}>
          <DataWarehouseUpdate />
        </DataWarehouseUpdateProvider>
      )}
    </DataWarehouseUpdateLayout>
  )
}

export default DataWarehouseUpdateContainer
