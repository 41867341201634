import { useEffect } from 'react'

import { UseUpdateTableViewProps } from './updateTableView.props'

export const useUpdateTableView = (props: UseUpdateTableViewProps) => {
  const { form, detail } = props
  useEffect(() => {
    form.reset(detail)

    //calc isCheckedAll
    // setIsCheckedAll(calcIsCheckedAll())
  }, [JSON.stringify(detail)])

  return {}
}
