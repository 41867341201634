import PageHeader from '@/components/pageHeader/pageHeader'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormField, FormMessage } from '@/components/ui/form'
import { FormControl } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useTrans } from '@/hooks/useTranslation'

import FormAddPhoneNumber from './formAddPhoneNumber/formAddPhoneNumber'
import RowPhoneCheckbox from './rowPhoneCheckbox/rowCheckbox'
import useWhatsappChannel from './whatsappChannel.hook'

const WhatsappChannelForm = () => {
  const { t } = useTrans()

  const {
    isActivateBusiness,
    isActivateWhatsapp,
    form,
    onSubmit,
    handleAddPhoneNumber,
    phones,
    handleRemovePhoneNumber,
    handleCheckPhoneNumber,
  } = useWhatsappChannel()

  return (
    <>
      <Form {...form}>
        <form className="w-full mt-10" onSubmit={onSubmit}>
          <div className="flex space-x-2">
            <FormField
              control={form.control}
              name="activateWhatsapp"
              render={({ field }) => {
                return (
                  <>
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        id="activateWhatsapp"
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <Label
                      // eslint-disable-next-line max-len
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      htmlFor="activateWhatsapp"
                    >
                      {t('Active')}
                    </Label>
                  </>
                )
              }}
            />
          </div>

          {isActivateWhatsapp && (
            <>
              <div className="flex space-x-2 mt-6">
                <FormField
                  control={form.control}
                  name="activateBusiness"
                  render={({ field }) => {
                    return (
                      <>
                        <FormControl>
                          <Checkbox
                            checked={field.value}
                            disabled
                            id="activateBusiness"
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <Label
                          // eslint-disable-next-line max-len
                          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                          htmlFor="activateBusiness"
                        >
                          {t('useBusinessAccount')}
                        </Label>
                      </>
                    )
                  }}
                />
              </div>
              {isActivateBusiness && (
                <>
                  <div className="flex flex-col space-y-4 mt-9">
                    <FormField
                      control={form.control}
                      name="businessID"
                      render={({ field }) => (
                        <div className="w-full">
                          <Label
                            // eslint-disable-next-line max-len
                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            htmlFor="businessID"
                          >
                            {t('phoneNumber')}
                          </Label>
                          <Input
                            placeholder="PHONE_NUMBER_ID"
                            type="text"
                            {...field}
                          />
                          <FormMessage />
                        </div>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="graphQLURL"
                      render={({ field }) => (
                        <div className="w-full">
                          <Label
                            // eslint-disable-next-line max-len
                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            htmlFor="businessID"
                          >
                            {t('graphqlURL')}
                          </Label>
                          <Input
                            placeholder="GRAPHQL_URL"
                            type="text"
                            {...field}
                          />
                          <FormMessage />
                        </div>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="graphQLToken"
                      render={({ field }) => (
                        <div className="w-full">
                          <Label
                            // eslint-disable-next-line max-len
                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            htmlFor="businessID"
                          >
                            {t('graphqlToken')}
                          </Label>
                          <Input
                            placeholder="GRAPHQL_TOKEN"
                            type="text"
                            {...field}
                          />
                          <FormMessage />
                        </div>
                      )}
                    />
                  </div>
                  <div className="w-full mt-9">
                    <div className="flex space-x-2">
                      <FormField
                        control={form.control}
                        name="businessAllPhones"
                        render={({ field }) => {
                          return (
                            <>
                              <FormControl>
                                <Checkbox
                                  checked={field.value}
                                  id="businessAllPhones"
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                              <Label
                                // eslint-disable-next-line max-len
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                htmlFor="businessAllPhones"
                              >
                                {t('title_businessAccount')}
                              </Label>
                            </>
                          )
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          <div className="w-full flex flex-col space-y-4 mt-9">
            {phones?.map((item) => (
              <RowPhoneCheckbox
                checked={item.checked as boolean}
                disableCheckbox={isActivateBusiness}
                key={item.phone}
                label={
                  <p className="text-sm font-medium">
                    {item.phone}
                    <span className="ml-6 text-text-gray">{item.name}</span>
                  </p>
                }
                onChange={(checked) =>
                  handleCheckPhoneNumber(item.phone, Boolean(checked))
                }
                onRemove={() => handleRemovePhoneNumber(item.phone)}
              />
            ))}
          </div>
        </form>
      </Form>
      <div className="mt-9">
        <FormAddPhoneNumber
          disabled={!isActivateWhatsapp}
          submitCallback={handleAddPhoneNumber}
        />
      </div>

      <div className="mt-[84px] flex justify-end pb-4">
        <Button
          className="min-w-[80px]"
          onClick={onSubmit}
          type="submit"
          variant={'primary'}
        >
          {t('save')}
        </Button>
      </div>
    </>
  )
}

const WhatsappChannel = () => {
  const { t } = useTrans()
  return (
    <PageHeader haveSubSidebar subTitle={t('title_updateTypeInUserChange')}>
      <div className="w-fill">
        <WhatsappChannelForm />
      </div>
    </PageHeader>
  )
}

export default WhatsappChannel
