import { CheckIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons'

import IconTooltip from '@/components/iconTooltip'
import { Button } from '@/components/ui/button'
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { onEnterPress, onFocusInputById } from '@/helpers/common'
import { useTrans } from '@/hooks/useTranslation'

import useQuestionItem from './questionItem.hook'
import { QuestionItemProps } from './questionItem.props'

const QuestionItem = (props: QuestionItemProps) => {
  const { t } = useTrans()
  const { onUpdate } = props
  const { isEdit, toggleEdit, handleRemoveQuestion } = useQuestionItem({
    onDelete: props.onDelete,
  })

  return (
    <div className="w-full px-8 py-4 relative shadow-md rounded-md border">
      {!isEdit && (
        <div className="flex justify-between gap-4 lg:gap-10">
          <div className="w-full pr-6">
            {!isEdit && props.content && (
              <p className="text-sm break-words">{props.content}</p>
            )}
          </div>
          <div className="flex gap-2 lg:gap-3">
            <IconTooltip
              icon={
                <Pencil1Icon
                  className="cursor-pointer"
                  fontWeight={700}
                  onClick={() => {
                    toggleEdit()
                    onFocusInputById(`focus-knowledge-${props.index}`)
                  }}
                />
              }
              message={t('iconUpdateKnowledge')}
            />
            <IconTooltip
              icon={
                <TrashIcon
                  className="cursor-pointer"
                  fontWeight={700}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    handleRemoveQuestion()
                  }}
                />
              }
              message={t('iconDeleteKnowledge')}
            />
          </div>
        </div>
      )}
      {isEdit && (
        <>
          <FormItem>
            <FormLabel className="font-semibold" requiredField>
              {t('question')}
            </FormLabel>
            <FormControl>
              <Textarea
                {...props.field}
                defaultValue={props.content}
                id={`focus-question-${props.index}`}
                onChange={(e) => {
                  props.field.onChange({
                    target: {
                      value: {
                        ...props.field.value,
                        content: e.target.value,
                      },
                      name: props.field.name,
                    },
                    type: 'change',
                  })
                }}
                onKeyDown={onEnterPress(() => {
                  toggleEdit()
                  onUpdate({
                    _id: props.field.value?._id,
                    content: props.field.value?.content,
                  })
                })}
                placeholder={t('enterYourContent')}
                value={props.field.value.content}
              />
            </FormControl>
            <FormMessage />
          </FormItem>

          <div className="w-full flex justify-end gap-2 mt-4">
            <Button
              borderRadius="large"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                toggleEdit()
              }}
            >
              <CheckIcon className="h-4 w-4" />
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default QuestionItem
