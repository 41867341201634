import { Pencil1Icon } from '@radix-ui/react-icons'

import IconTooltip from '@/components/iconTooltip'
import { AccordionTrigger } from '@/components/ui/accordion'
import { onFocusInputById } from '@/helpers/common'
import { useTrans } from '@/hooks/useTranslation'
import RowCheckbox from '@/pages/dataset/rowCheckbox/rowCheckbox'

import { useTableFormFactoryContext } from '../../tableFormFactory.context'
import useAccordionHeader from './accordionHeader.hook'
import { AccordionHeaderProps } from './accordionHeader.props'
import UpdateInfoForm from './updateInfoForm/updateInfoForm'

const AccordionTriggerHeader = (props: AccordionHeaderProps) => {
  const { tableIndex } = props
  const { isEdit, handleToggleEdit } = useAccordionHeader()
  const { isCheckedAll, handleUpdateAllColumnsChecked } =
    useTableFormFactoryContext()
  const { t } = useTrans()
  return (
    <>
      <AccordionTrigger className="items-start">
        <div className="w-full flex justify-between items-center pr-6">
          <div className="flex flex-col justify-start">
            <h3 className="text-left">{props.name}</h3>
            {props.description && (
              <p className="text-left">
                {t('description')}: {props.description}
              </p>
            )}
          </div>

          <div className="flex items-center gap-6">
            <RowCheckbox
              checked={isCheckedAll[tableIndex]}
              classNameContainer="!p-0"
              isRowSelectALL={true}
              label="Check all"
              onChange={(checked) => {
                handleUpdateAllColumnsChecked(tableIndex, Boolean(checked))
              }}
            />

            {!isEdit && (
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  handleToggleEdit()
                  onFocusInputById(`focus-name-${props.tableIndex}`)
                }}
              >
                <IconTooltip
                  icon={<Pencil1Icon />}
                  message={t('iconUpdateName')}
                />
              </div>
            )}
          </div>
        </div>
      </AccordionTrigger>
      <UpdateInfoForm
        description={props.description}
        displayName={props.name}
        idDescriptionFocus={`focus-description-${props.tableIndex}`}
        idNameFocus={`focus-name-${props.tableIndex}`}
        isOpen={isEdit}
        onClose={handleToggleEdit}
        tableIndex={props.tableIndex}
      />
    </>
  )
}

export default AccordionTriggerHeader
