import { Outlet } from 'react-router'

import { SidebarLabelItem } from '@/components/sidebarLabelItem'
import SubSidebar from '@/components/subSidebar/subSidebar'
import { useTrans } from '@/hooks/useTranslation'

import { useDataWarehouse } from './dataWarehouse.hook'

const DataWarehouse = () => {
  const { t } = useTrans()
  const { warehouses, ref_scrollElement, goToCreateDataWarehouse } =
    useDataWarehouse()

  return (
    <div className="w-full grow flex h-full">
      <SubSidebar
        addButton={{
          label: t('addTable'),
          onClick: goToCreateDataWarehouse,
        }}
        buttons={warehouses}
        renderLabel={(item, isActive) => (
          <SidebarLabelItem isActive={isActive} item={item} />
        )}
        title={t('table')}
      />
      <div className="w-full" ref={ref_scrollElement}>
        <Outlet />
      </div>
    </div>
  )
}

export default DataWarehouse
