import { PlusIcon } from '@radix-ui/react-icons'

import FolderIcon from '@/components/Icon/folder.svg?react'
import FoldersIcon from '@/components/Icon/folders.svg?react'
import Logo from '@/components/Logo/Logo'
import MainCard from '@/components/MainCard/mainCard'
import OnboardingCover from '@/components/OnboardingCover/OnboardingCover'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Separator } from '@/components/ui/separator'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Textarea } from '@/components/ui/textarea'

const ComponentDemo = () => {
  const cardData = {
    imageSrc: '',
    // eslint-disable-next-line max-len
    /*       'https://static.fundacion-affinity.org/cdn/farfuture/PVbbIC-0M9y4fPbbCsdvAD8bcjjtbFc0NSP3lRwlWcE/mtime:1643275542/sites/default/files/los-10-sonidos-principales-del-perro.jpg',
     */ title: 'Project Name Card',
    description: 'Brief project description',
  }

  return (
    <div className="flex flex-col space-y-4 p-4">
      <div>
        <h2 className="text-xl font-bold mb-2">Typography</h2>
        <section>
          <h3 className="text-md font-semibold mb-2">H1</h3>
          <p className="h1">Taxing Laughter: The Joke Tax Chronicles</p>
        </section>

        <section>
          <h3 className="text-md font-semibold mb-2">H2</h3>
          <p className="h2">The People of the Kingdom</p>
        </section>

        <section>
          <h3 className="text-md font-semibold mb-2">H3</h3>
          <p className="h3">The Joke Tax</p>
        </section>

        <section>
          <h3 className="text-md font-semibold mb-2">H4</h3>
          <p className="h4">People stopped telling jokes</p>
        </section>

        <section>
          <h3 className="text-md font-semibold mb-2">Paragraph</h3>
          <p>
            The king, seeing how much happier his subjects were, realized the
            error of his ways and repealed the joke tax.
          </p>
        </section>

        <section>
          <h3 className="text-md font-semibold mb-2">Base1</h3>
          <p className="base1">
            A modal dialog that interrupts the user with important content and
            expects a response.
          </p>
        </section>

        <section>
          <h3 className="text-md font-semibold mb-2">Caption1</h3>
          <p className="caption1">Email address</p>
        </section>

        <section>
          <h3 className="text-md font-semibold mb-2">Caption2</h3>
          <p className="caption2">Are you sure absolutely sure?</p>
        </section>

        <section>
          <h3 className="text-md font-semibold mb-2">Caption3</h3>
          <p className="caption3">Enter your email address.</p>
        </section>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">Logos</h2>
        <div className="flex space-x-4">
          <div>
            <h3 className="text-md font-semibold mb-2">logoLight</h3>
            <Logo />
          </div>
          <div>
            <h3 className="text-md font-semibold mb-2">logoDark</h3>
            <Logo dark={true} />
          </div>
        </div>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">Buttons</h2>
        <div className="flex space-x-4 mb-4">
          <Button>Default</Button>
          <Button variant="secondary">Secondary</Button>
          <Button variant="third">Third</Button>
          <Button variant="link">Link</Button>
          <Button variant="link2">Link2</Button>
          <Button variant="secondary">
            <PlusIcon className="mr-2.5 h-4 w-4" /> Create a Project
          </Button>
        </div>
        <div className="flex space-x-4">
          <Button borderRadius="large" size="lg">
            Default
          </Button>
          <Button borderRadius="large" size="lg" variant="secondary">
            Secondary
          </Button>
          <Button
            borderRadius="large"
            className="border border-n-2"
            size="lg"
            variant="link"
          >
            Link
          </Button>
        </div>
      </div>
      <section>
        <h2 className="text-xl font-bold mb-2">Icons</h2>
        <div className="flex space-x-4">
          <FolderIcon className="h-8.5 w-auto" />
          <FoldersIcon className="h-8.5 w-auto" />
        </div>
      </section>
      <section>
        <h2 className="text-xl font-bold mb-2">Cards</h2>
        <div className="flex space-x-4 mb-4">
          <MainCard
            description={cardData.description}
            imageSrc={cardData.imageSrc}
            title={cardData.title}
          />
          <MainCard
            description={cardData.description}
            // eslint-disable-next-line max-len
            imageSrc="https://static.fundacion-affinity.org/cdn/farfuture/PVbbIC-0M9y4fPbbCsdvAD8bcjjtbFc0NSP3lRwlWcE/mtime:1643275542/sites/default/files/los-10-sonidos-principales-del-perro.jpg"
            title={cardData.title}
          />
        </div>
      </section>
      <h2 className="text-xl font-bold mb-2">Separator</h2>
      <div className="flex space-x-4">
        <Separator />
      </div>

      <h2 className="text-xl font-bold mb-2">Checkbox</h2>
      <div className="flex space-x-2">
        <Checkbox id="terms" />
        <Label
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          htmlFor="terms"
        >
          Accept terms and conditions
        </Label>
      </div>
      <h2 className="text-xl font-bold mb-2">Tabs</h2>
      <div className="flex space-x-4">
        <Tabs className="w-[345px]" defaultValue="whatsapp">
          <TabsList className="grid w-full grid-cols-3 space-x-2">
            <TabsTrigger value="whatsapp">Whatsapp</TabsTrigger>
            <TabsTrigger value="telegram">Telegram</TabsTrigger>
            <TabsTrigger value="slack">Slack</TabsTrigger>
          </TabsList>
          <TabsContent value="whatsapp">Whatsapp</TabsContent>
          <TabsContent value="telegram">Telegram</TabsContent>
          <TabsContent value="slack">Slack</TabsContent>
        </Tabs>
      </div>
      <h2 className="text-xl font-bold mb-2">Textarea</h2>
      <div className="flex space-x-4">
        <Textarea
          placeholder={
            'Type your knowledge here (ex. when someone asks for produced volume, ' +
            'it means premium money)'
          }
        />
      </div>
      <div className="grid w-full gap-3">
        <label className="caption2" htmlFor="message">
          Type your 2nd question here
        </label>
        <Textarea id="message" placeholder="Type your message here." />
      </div>
      <div className="grid w-full gap-2">
        <Textarea placeholder="Type the name here" />
        <div className="flex justify-end">
          <Button className="w-20">Next</Button>
        </div>
      </div>
      <h2 className="text-xl font-bold mb-2">Inputs</h2>
      <div>
        <Input placeholder="Email" type="email" />
        <div>
          <Label className="caption2" htmlFor="email">
            Email
          </Label>
          <Input
            className="caption2"
            id="email"
            placeholder="Email"
            type="email"
          />
        </div>
      </div>
      <div>
        <AlertDialog>
          <AlertDialogTrigger>Open AlertDialogTrigger</AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This action cannot be undone. This will permanently delete your
                account and remove your data from our servers.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction>Continue</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
      <div>
        <Label htmlFor="password">Password</Label>
        <Input id="password" placeholder="password" type="password" />
      </div>
      <h2 className="text-xl font-bold mb-2">Dropdown</h2>
      <Accordion className="w-full" collapsible type="single">
        <AccordionItem className="mb-6" value="item-1">
          <AccordionTrigger>
            This is the placeholder that will keep the first question asked by
            the user?
          </AccordionTrigger>
          <AccordionContent>
            <h3 className="caption3 pb-4">Input Answer</h3>
            This is the placeholder that will keep the first question asked by
            the user. This is the placeholder that will keep the first question
            asked by the user.This is the placeholder that will keep the first
            question asked by the user
            <h3 className="caption3 py-4">Current Answer</h3>
            This is the placeholder that will keep the first question asked by
            the user. This is the placeholder that will keep the first question
            asked by the user.This is the placeholder that will keep the first
            question asked by the user
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-2">
          <AccordionTrigger>
            This is the placeholder that will keep the first question asked by
            the user?
          </AccordionTrigger>
          <AccordionContent>
            <h3 className="caption3 pb-4">Current Answer</h3>
            Yes. It comes with default styles that matches the other
            components&apos; aesthetic.
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <h2 className="text-xl font-bold mb-2">Card</h2>
      <div className="flex space-x-2">
        <Card className="w-[350px]">
          <CardHeader>
            <CardTitle>CardTitle</CardTitle>
            <CardDescription>CardDescription</CardDescription>
          </CardHeader>
          <CardContent>
            <form>
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="name">Name</Label>
                  <Input id="name" placeholder="Name" />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="framework">Framework</Label>
                </div>
              </div>
            </form>
          </CardContent>
          <CardFooter className="flex justify-between">
            <Button variant="outline">Button</Button>
            <Button>Button</Button>
          </CardFooter>
        </Card>
        <Card className="w-[350px]">
          <CardContent>
            <Label className="text-n-2" htmlFor="name">
              Example
            </Label>
          </CardContent>
        </Card>
      </div>
      <OnboardingCover
        backgroundVariant="variant1"
        // eslint-disable-next-line react/no-children-prop
        children={undefined}
      ></OnboardingCover>
      <OnboardingCover
        backgroundVariant="variant2"
        // eslint-disable-next-line react/no-children-prop
        children={undefined}
      ></OnboardingCover>
      <OnboardingCover
        backgroundVariant="variant3"
        // eslint-disable-next-line react/no-children-prop
        children={undefined}
      ></OnboardingCover>
      <OnboardingCover backgroundVariant="variant3">
        <div className="relative w-full h-full flex flex-col items-center justify-center gap-12">
          <div className="w-full flex flex-col justify-center items-center gap-8">
            <h2 className="h2 text-center !text-white max-w-4xl md:h1">
              Let’s set up your Teramot Data Assistant!{' '}
            </h2>
            <p className="h4 text-center !text-white md:h2">
              You’ll be done in 5 quick steps{' '}
            </p>
          </div>
          <div className="flex justify-center">
            <div className="inline-block">
              <Button variant="secondary">Continue</Button>
            </div>
          </div>
        </div>
      </OnboardingCover>
      <OnboardingCover backgroundVariant="variant3">
        <div className="relative w-full h-full flex flex-col items-center justify-center gap-12">
          <div className="w-full flex flex-col justify-center items-center gap-8">
            <h2 className="h2 text-center !text-white max-w-4xl md:h1">
              Those are great questions! Now help me find the info to answer
              them...
            </h2>
            <p className="h4 text-center !text-white md:h2">
              Only 4 steps to go
            </p>
          </div>
          <div className="flex justify-center">
            <div className="inline-block">
              <Button variant="third">Continue</Button>
            </div>
          </div>
        </div>
      </OnboardingCover>
    </div>
  )
}

export default ComponentDemo
