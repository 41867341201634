import { Card } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'

import { BillingPackageProps } from './billingPackage.props'

const BillingPackage = ({
  active,
  list,
  pricing,
  title,
  onChoose,
}: BillingPackageProps) => {
  const baseContainerClasses = 'px-4 py-8 border-n-2 rounded-lg cursor-pointer'
  const baseTextClasses = 'w-full text-left'
  const baseTitleClasses = 'h4'
  const baseItemClasses = 'caption1'
  const basePricingClasses = 'h2'

  const classContainer = cn(baseContainerClasses, {
    'bg-accent-1': active,
  })

  const textColorClass = active ? 'text-n-1' : 'text-n-3'
  const classTitle = cn(baseTitleClasses, baseTextClasses, textColorClass)
  const classItem = cn(baseItemClasses, baseTextClasses, textColorClass)
  const classPricing = cn(basePricingClasses, baseTextClasses, textColorClass)

  return (
    <Card
      aria-pressed={active}
      className={classContainer}
      onClick={onChoose}
      role="button"
      tabIndex={0}
    >
      <div className="flex flex-col gap-4">
        <h2 className={classTitle}>{title}</h2>
        <Separator className="bg-slate-200" />
        <ul aria-label={title} className="min-h-[213px] pl-6 list-disc">
          {list.map((item, index) => (
            <li className={classItem} key={index}>
              {item}
            </li>
          ))}
        </ul>
        <strong className={classPricing}>{pricing}</strong>
      </div>
    </Card>
  )
}

export default BillingPackage
