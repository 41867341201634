import { createSlice } from '@reduxjs/toolkit'

import { BASE_URL } from '@/constants/common'
import axios from '@/services/http/axiosInstance'
import { ANY, IDispatch, IProject } from '@/types'

import { getCookie, isAuth } from '../../helpers/auth'
import ConsoleHelper from '../../helpers/ConsoleHelper'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let uploadSource: any = undefined
let isGettingProjects = false

interface ProjectSlideState {
  projects: Record<string, IProject>
  currentPID: string
  currentProject: IProject | null
}

export const projectSlice = createSlice({
  name: 'project',
  initialState: {
    projects: {},
    currentPID: '',
    currentProject: null,
  } as ProjectSlideState,
  reducers: {
    setCurrentPID: (state, action) => {
      state.currentPID = action.payload
    },
    getProjectsSuccess: (state, action) => {
      state.projects = action.payload
    },
    setProjectsByPid: (state, action) => {
      const oldProjects = state.projects
      const pid = action.payload.pid
      const newProjects = { ...oldProjects, [pid]: action.payload.meta }
      state.projects = newProjects
    },

    updateColumn: (state, action) => {
      const data = action.payload
      const oldProjects = state.projects
      const pid = data.pid
      const datasetId = data.datasetId
      const column = data.column
      const description = data.description

      const newProjects = {
        ...oldProjects,
        [pid]: {
          ...oldProjects[pid],
          inputData: {
            ...oldProjects[pid].inputData,
            [datasetId]: {
              ...oldProjects[pid].inputData[datasetId],
              descriptions: {
                ...oldProjects[pid].inputData[datasetId].descriptions,
                [column]: description,
              },
            },
          },
        },
      }
      state.projects = newProjects
    },
    removeQuestion: (state, action) => {
      const { pid, index } = action.payload || {}
      const oldProjects = state.projects
      const questions = oldProjects[pid]?.questions ?? []

      questions.splice(index, 1)

      const newProjects = {
        ...oldProjects,
        [pid]: {
          ...oldProjects[pid],
          questions,
        },
      }
      state.projects = newProjects
    },
    updateQuestion: (state, action) => {
      const data = action.payload
      const oldProjects = state.projects
      const pid = data.pid
      const index = data.index
      const questions = oldProjects[pid].questions ?? []
      questions[index] = data.question

      const newProjects = {
        ...oldProjects,
        [pid]: {
          ...oldProjects[pid],
          questions,
        },
      }
      state.projects = newProjects
    },
    createQuestion: (state, action) => {
      const data = action.payload
      // console.log('data', data)
      const oldProjects = state.projects
      const pid = data.pid
      const questions = oldProjects[pid].questions ?? []
      questions.push(data.question)
      const newProjects = {
        ...oldProjects,
        [pid]: {
          ...oldProjects[pid],
          questions,
        },
      }
      state.projects = newProjects
    },
    removeKnowledgeGeneral: (state, action) => {
      const { pid, index } = action.payload || {}
      const oldProjects = state.projects
      const configuration = oldProjects[pid].configuration
      const knowledgeGeneral = configuration?.knowledgeGeneral ?? []

      knowledgeGeneral.splice(index, 1)

      const newProjects = {
        ...oldProjects,
        [pid]: {
          ...oldProjects[pid],
          configuration: {
            ...oldProjects[pid].configuration,
            knowledgeGeneral,
          },
        },
      }
      state.projects = newProjects
    },
    updateKnowledgeGeneral: (state, action) => {
      const data = action.payload
      const oldProjects = state.projects
      const pid = data.pid
      const index = data.index
      const configuration = oldProjects[pid].configuration
      const knowledgeGeneral = configuration?.knowledgeGeneral ?? []
      knowledgeGeneral[index] = data.knowledge

      const newProjects = {
        ...oldProjects,
        [pid]: {
          ...oldProjects[pid],
          configuration: {
            ...oldProjects[pid].configuration,
            knowledgeGeneral,
          },
        },
      }
      state.projects = newProjects
    },

    createKnowledgeGeneral: (state, action) => {
      const data = action.payload
      const oldProjects = state.projects
      const pid = data.pid
      const configuration = oldProjects[pid].configuration
      const knowledgeGeneral = configuration?.knowledgeGeneral ?? []
      knowledgeGeneral.push(data.knowledge)
      const newProjects = {
        ...oldProjects,
        [pid]: {
          ...oldProjects[pid],
          configuration: {
            ...oldProjects[pid].configuration,
            knowledgeGeneral,
          },
        },
      }
      state.projects = newProjects
    },
    removeKnowledge: (state, action) => {
      const { pid, index, datasetId } = action.payload || {}

      const oldProjects = state.projects
      const configuration = oldProjects[pid].configuration
      const knowledges = configuration?.knowledge[datasetId] ?? []

      knowledges.splice(index, 1)

      const newProjects = {
        ...oldProjects,
        [pid]: {
          ...oldProjects[pid],
          configuration: {
            ...oldProjects[pid].configuration,
            knowledge: {
              ...(oldProjects[pid].configuration?.knowledge ?? {}),
              [datasetId]: knowledges,
            },
          },
        },
      }
      state.projects = newProjects
    },
    updateKnowledge: (state, action) => {
      const data = action.payload
      const oldProjects = state.projects
      const pid = data.pid
      const index = data.index
      const datasetId = data.datasetId
      const configuration = oldProjects[pid].configuration
      const knowledges = configuration?.knowledge[datasetId] ?? []
      knowledges[index] = data.knowledge

      const newProjects = {
        ...oldProjects,
        [pid]: {
          ...oldProjects[pid],
          configuration: {
            ...oldProjects[pid].configuration,
            knowledge: {
              ...(oldProjects[pid].configuration?.knowledge ?? {}),
              [datasetId]: knowledges,
            },
          },
        },
      }
      state.projects = newProjects
    },
    createKnowledge: (state, action) => {
      const data = action.payload
      const oldProjects = state.projects
      const pid = data.pid
      const configuration = oldProjects[pid].configuration
      const datasetId = data.datasetId
      const knowledges = configuration?.knowledge?.[datasetId] ?? []
      knowledges.push(data.knowledge)

      const newProjects = {
        ...oldProjects,
        [pid]: {
          ...oldProjects[pid],
          configuration: {
            ...oldProjects[pid].configuration,
            knowledge: {
              ...(oldProjects[pid].configuration?.knowledge ?? {}),
              [datasetId]: knowledges,
            },
          },
        },
      }
      state.projects = newProjects
    },
    updateBasicInfoConfig: (state, action) => {
      const data = action.payload
      const oldProjects = { ...JSON.parse(JSON.stringify(state.projects)) }
      const pid = data.pid
      const configuration = oldProjects[pid]?.configuration
      const newProjects = {
        ...oldProjects,
        [pid]: {
          ...oldProjects[pid],
          configuration: {
            ...configuration,
            botName: data.botName,
            lang: data.lang,
          },
        },
      }
      state.projects = newProjects
    },
    getCurrentProjectSuccess: (state, action) => {
      const projectData = action.payload
      state.currentProject = { ...projectData }
    },
    setInputDataSuccess: (state, action) => {
      const data = action.payload
      const inputData = {
        ...(state.currentProject?.inputData ?? {}),
        ...data.inputData,
      }
      state.currentProject = { ...state.currentProject, ...data, inputData }
    },
    setConnectDbSuccess: (state, action) => {
      const data = action.payload
      const inputData = {
        ...(state.currentProject?.inputData ?? {}),
        ...data.inputData,
      }
      state.currentProject = { ...state.currentProject, ...data, inputData }
    },
    setTableFileSuccess: (state, action) => {
      const data = action.payload
      const inputData = {
        ...(state.currentProject?.inputData ?? {}),
        ...data.inputData,
      }
      state.currentProject = { ...state.currentProject, ...data, inputData }
    },
    setOutputChannelsSuccess: (state, action) => {
      const data = action.payload
      if (data?.outputChannels)
        if (state.currentProject) {
          state.currentProject = {
            ...state.currentProject,
            outputChannels: data?.outputChannels ?? [],
          }
        }
      if (data?.needDeploy)
        if (state.currentProject)
          state.currentProject['needDeploy'] = data.needDeploy
    },
    setSubscriptionSuccess: (state, action) => {
      const subscription = action.payload.subscription
      const pid = action.payload.pid

      state.projects[pid].subscription = subscription
    },
    setConfigurationSuccess: (state, action) => {
      const data = action.payload
      if (data?.configuration)
        if (state.currentProject)
          state.currentProject['configuration'] = data.configuration
      if (data?.needDeploy)
        if (state.currentProject)
          state.currentProject['needDeploy'] = data.needDeploy
      if (data?.chatDisabled !== undefined)
        if (state.currentProject)
          state.currentProject['chatDisabled'] = data.chatDisabled
    },
    deployProjectSuccess: (state, action) => {
      if (action.payload?.needDeploy !== undefined) {
        state.projects[action.payload.pid].needDeploy =
          action.payload.needDeploy
      }
    },
    uploadProjectSuccess: (state, action) => {
      const newProject = action.payload
      state.projects[newProject.pid] = { ...newProject }
      if (state.currentPID === newProject.pid)
        state.currentProject = { ...newProject }
    },
    deleteProjectSuccess: (state, action) => {
      const pid = action.payload.pid
      delete state.projects[pid]
      state.currentPID = ''
      state.currentProject = null
    },
    updateTestQuestionsSuccess: (state, action) => {
      const data = action.payload
      if (data?.testQuestions)
        if (state.currentProject)
          state.currentProject = {
            ...state.currentProject,
            testQuestions: data?.testQuestions ?? [],
          }
    },
    updatePoolWarehouseSuccess: (state, action) => {
      const { pid, payload } = action.payload
      state.projects[pid] = {
        ...state.projects[pid],
        inputData: payload.inputData,
        warehouse: payload.warehouse,
      }
    },
    updateWarehouseSuccess: (state, action) => {
      const { pid, warehouse } = action.payload

      state.projects[pid] = {
        ...state.projects[pid],
        warehouse: warehouse,
      }
    },
  },
})

export const {
  setCurrentPID,
  getProjectsSuccess,
  getCurrentProjectSuccess,
  setInputDataSuccess,
  setConnectDbSuccess,
  setTableFileSuccess,
  setOutputChannelsSuccess,
  setSubscriptionSuccess,
  setConfigurationSuccess,
  deployProjectSuccess,
  uploadProjectSuccess,
  deleteProjectSuccess,
  updateTestQuestionsSuccess,
  setProjectsByPid,
  updateColumn,
  updateKnowledgeGeneral,
  removeKnowledgeGeneral,
  createKnowledgeGeneral,
  removeKnowledge,
  updateKnowledge,
  createKnowledge,
  updateBasicInfoConfig,
  updateQuestion,
  createQuestion,
  removeQuestion,
  updatePoolWarehouseSuccess,
  updateWarehouseSuccess,
} = projectSlice.actions

export const getProjects =
  (payload: Record<string, unknown>) => (dispatch: IDispatch) => {
    if (!isGettingProjects) {
      const { resolve, ...rest } = payload || {}
      isGettingProjects = true
      const pattern = JSON.stringify(rest || {})
      const token = getCookie('token')
      const data = isAuth()
      axios
        .get(`${BASE_URL}/backend/${data.email}/all/${pattern}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch(getProjectsSuccess(res.data))
          isGettingProjects = false
          if (resolve) (resolve as ANY)()
        })
        .catch((error) => {
          ConsoleHelper('Error getProjects' + error)
          isGettingProjects = false
        })
    }
  }

export const getProject = (pid: string) => (dispatch: IDispatch) => {
  const token = getCookie('token')
  const data = isAuth()
  axios
    .get(`${BASE_URL}/backend/${data.email}/${pid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(setProjectsByPid({ pid, meta: res.data }))
      dispatch(getCurrentProjectSuccess(res.data))
    })
    .catch((error) => {
      ConsoleHelper(`Error getProject: ${error}`)
    })
}

export const setCurrentProject = (pid: string) => (dispatch: IDispatch) => {
  dispatch(setCurrentPID(pid))
  dispatch(getProject(pid))
}

export const uploadProject =
  (payload: Record<string, unknown>) => (dispatch: IDispatch) => {
    return new Promise((resolve, reject) => {
      const data = isAuth()
      axios
        .post(`${BASE_URL}/backend/${data.email}/upload`, payload.data, {
          headers: {
            Authorization: `Bearer ${getCookie('token')}`,
          },
        })
        .then((res) => {
          dispatch(uploadProjectSuccess(res.data))
          dispatch(setCurrentProject(res.data.pid))
          resolve(res.data)
        })
        .catch((err) => {
          ConsoleHelper(`Error creating Project: ${err}`)
          reject(err)
        })
    })
  }

export const deleteProject =
  (payload: Record<string, unknown>) => (dispatch: IDispatch) => {
    return new Promise((resolve, reject) => {
      const data = isAuth()
      axios
        .post(`${BASE_URL}/backend/${data.email}/delete`, payload, {
          headers: {
            Authorization: `Bearer ${getCookie('token')}`,
          },
        })
        .then((res) => {
          dispatch(deleteProjectSuccess(res.data))
          resolve(res.data)
        })
        .catch((err) => {
          ConsoleHelper(`Error removing Project: ${err}`)
          reject(err)
        })
    })
  }

export const connectDB =
  (pid: string, data: Record<string, unknown>) => (dispatch: IDispatch) => {
    return new Promise((resolve, reject) => {
      const token = getCookie('token')
      const auth = isAuth()
      axios
        .post(`${BASE_URL}/backend/${auth.email}/${pid}/connect-db`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          // Returns the dataset with iid
          dispatch(setConnectDbSuccess(res.data))
          resolve(res.data)
        })
        .catch((error) => {
          ConsoleHelper(`Error connectDb: ${error}`)
          reject(error)
        })
    })
  }

export const getColumns =
  (pid: string, data: Record<string, unknown>) => () => {
    return new Promise((resolve, reject) => {
      const token = getCookie('token')
      const auth = isAuth()
      axios
        .post(`${BASE_URL}/backend/${auth.email}/${pid}/get-columns`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          ConsoleHelper(`Error getColumns: ${error}`)
          reject(error)
        })
    })
  }

export const setInputData =
  (pid: string, data: Record<string, unknown>) => (dispatch: IDispatch) => {
    return new Promise((resolve, reject) => {
      const token = getCookie('token')
      const auth = isAuth()
      axios
        .post(
          `${BASE_URL}/backend/${auth.email}/${pid}/update-input-data`,
          data,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then((res) => {
          const data = res.data
          dispatch(getProject(pid))
          // dispatch(setInputDataSuccess(res.data))
          resolve(data)
        })
        .catch((e) => {
          ConsoleHelper(`Error setInputData: ${e}`)
          reject(e.response?.data?.error || e?.message)
        })
    })
  }

export const deleteInputData =
  (pid: string, data: Record<string, unknown>) => (dispatch: IDispatch) => {
    return new Promise((resolve, reject) => {
      const token = getCookie('token')
      const auth = isAuth()
      axios
        .post(
          `${BASE_URL}/backend/${auth.email}/${pid}/delete-input-data`,
          data,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then((res) => {
          const payload = {
            pid,
            meta: res.data,
          }

          dispatch(setProjectsByPid(payload))
          resolve(res.data)
        })
        .catch((e) => {
          ConsoleHelper(`Error deleteInputData: ${e}`)
          reject(e.response?.data?.error || e?.message)
        })
    })
  }

export const setTableFile =
  (
    pid: string,
    data: Record<string, unknown> | FormData | null,
    setProgress: React.Dispatch<React.SetStateAction<number>>,
  ) =>
  (dispatch: IDispatch) => {
    const isFormData = data instanceof FormData

    return new Promise((resolve, reject) => {
      const token = getCookie('token')
      const auth = isAuth()
      uploadSource = axios.CancelToken.source()
      setProgress(0)
      axios
        .post(
          `${BASE_URL}/backend/${auth.email}/${pid}/upload-table-file`,
          data,
          {
            onUploadProgress: (ProgressEvent) => {
              if (!ProgressEvent.total) return
              const progress = Math.round(
                (ProgressEvent.loaded / ProgressEvent.total) * 100,
              )
              setProgress(progress)
            },
            cancelToken: uploadSource.token,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': isFormData
                ? 'multipart/form-data'
                : 'application/json',
            },
          },
        )
        .then((res) => {
          setProgress(100)
          dispatch(setTableFileSuccess(res.data))
          data = null
          uploadSource = undefined
          resolve(res.data)
        })
        .catch((e) => {
          data = null
          uploadSource = undefined
          reject(e.response?.data?.error || e?.message)
        })
    })
  }

export const cancelUpload = () => () => {
  if (uploadSource !== undefined) {
    uploadSource.cancel('Cancel by user')
    uploadSource = undefined
  }
}

export const setOutputChannels =
  (pid: string, data: Record<string, unknown>) => (dispatch: IDispatch) => {
    return new Promise((resolve, reject) => {
      ConsoleHelper('setOutputChannels')
      const token = getCookie('token')
      const auth = isAuth()
      axios
        .post(`${BASE_URL}/backend/${auth.email}/${pid}/outputChannels`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          dispatch(setOutputChannelsSuccess(res.data))
          resolve(res.data)
        })
        .catch((error) => {
          ConsoleHelper(`Error setOutputChannels: ${error}`)
          reject(error)
        })
    })
  }

export const setConfiguration =
  (pid: string, data: Record<string, unknown>) => (dispatch: IDispatch) => {
    return new Promise((resolve, reject) => {
      ConsoleHelper('setConfiguration')
      const token = getCookie('token')
      const clientId = localStorage.getItem('clientId')
      const auth = isAuth()
      axios
        .post(
          `${BASE_URL}/backend/${auth.email}/${pid}/configuration`,
          { data, clientId },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then((res) => {
          dispatch(setConfigurationSuccess(res.data))
          resolve(res.data)
        })
        .catch((e) => {
          ConsoleHelper(`Error setConfiguration: ${e}`)
          reject(e.response?.data?.error || e?.message)
        })
    })
  }

export const setQuestions =
  (pid: string, data: Record<string, unknown>) => (dispatch: IDispatch) => {
    return new Promise((resolve, reject) => {
      ConsoleHelper('setQuestions')
      const token = getCookie('token')
      const clientId = localStorage.getItem('clientId')
      const auth = isAuth()
      axios
        .post(
          `${BASE_URL}/backend/${auth.email}/${pid}/questions`,
          { data, clientId },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then((res) => {
          console.log('res', res)
          dispatch(getProject(pid))
          // dispatch(setConfigurationSuccess(res.data))
          resolve(res.data)
        })
        .catch((e) => {
          ConsoleHelper(`Error setQuestions: ${e}`)
          reject(e.response?.data?.error || e?.message)
        })
    })
  }

export const setSubscription =
  (pid: string, data: Record<string, unknown>) => (dispatch: IDispatch) => {
    return new Promise((resolve, reject) => {
      const token = getCookie('token')
      const auth = isAuth()
      axios
        .post(`${BASE_URL}/backend/${auth.email}/${pid}/subscription`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          dispatch(setSubscriptionSuccess(res.data))
          resolve(true)
        })
        .catch((e) => {
          ConsoleHelper(`Error setSubscription: ${e}`)
          reject(e.response?.data?.error || e?.message)
        })
    })
  }

export const deleteSubscription =
  (pid: string, data: Record<string, unknown>) => (dispatch: IDispatch) => {
    return new Promise((resolve, reject) => {
      const token = getCookie('token')
      const auth = isAuth()
      axios
        .post(
          `${BASE_URL}/backend/${auth.email}/${pid}/delete-subscription`,
          { data },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then((res) => {
          dispatch(setSubscriptionSuccess(res.data))
          resolve(true)
        })
        .catch((error) => {
          ConsoleHelper(`Error setSubscription: ${error}`)
          reject(error)
        })
    })
  }

export const upgradeSubscription =
  (pid: string, data: Record<string, unknown>) => (dispatch: IDispatch) => {
    return new Promise((resolve, reject) => {
      const token = getCookie('token')
      const auth = isAuth()
      axios
        .post(
          `${BASE_URL}/backend/${auth.email}/${pid}/upgrade-subscription`,
          { ...data, clientId: localStorage.getItem('clientId') },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then((res) => {
          dispatch(setSubscriptionSuccess(res.data))
          resolve(true)
        })
        .catch((e) => {
          ConsoleHelper(`Error setSubscription: ${e}`)
          reject(e)
        })
    })
  }

export const getMessages = (pid: string) => {
  const token = getCookie('token')
  const clientId = localStorage.getItem('clientId')
  const auth = isAuth()
  return axios.post(
    `${BASE_URL}/backend/chat/${auth.email}/${pid}/getMessages`,
    { clientId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

export const sendMessages = (pid: string, message: unknown) => {
  const token = getCookie('token')
  const clientId = localStorage.getItem('clientId')
  const auth = isAuth()
  return axios.post(
    `${BASE_URL}/backend/chat/${auth.email}/${pid}/sendMessages`,
    {
      clientId,
      message,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

export const getProducts = () => {
  return new Promise((resolve, reject) => {
    const token = getCookie('token')
    axios
      .get(`${BASE_URL}/backend/get-products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        ConsoleHelper(`getProducts: ${res.data}`)
        resolve(res.data)
      })
      .catch((error) => {
        ConsoleHelper(`Error getProducts: ${error}`)
        reject(error)
      })
  })
}

export const deployProject = (pid: string) => (dispatch: IDispatch) => {
  return new Promise((resolve, reject) => {
    ConsoleHelper('deployProject')
    const token = getCookie('token')
    const auth = isAuth()
    axios
      .get(`${BASE_URL}/backend/${auth.email}/${pid}/deploy`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(deployProjectSuccess(res.data))
        resolve(res.data)
      })
      .catch((error) => {
        ConsoleHelper(`Error deployProject: ${error}`)
        reject(error)
      })
  })
}

export const updateTestQuestions =
  (pid: string, data: Record<string, unknown>) => (dispatch: IDispatch) => {
    return new Promise((resolve, reject) => {
      ConsoleHelper('updateTestQuestions')
      const token = getCookie('token')
      const auth = isAuth()
      axios
        .post(
          `${BASE_URL}/backend/${auth.email}/${pid}/update-test-questions`,
          data,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then((res) => {
          dispatch(updateTestQuestionsSuccess(res.data))
          resolve(res.data)
        })
        .catch((e) => {
          ConsoleHelper(`Error updateTestQuestions: ${e}`)
          reject(e.response.data.error || e.message)
        })
    })
  }

// createTable
export const createTable =
  (pid: string, data: Record<string, unknown>) => (dispatch: IDispatch) => {
    return new Promise((resolve, reject) => {
      const token = getCookie('token')
      const auth = isAuth()
      axios
        .post(`${BASE_URL}/backend/${auth.email}/${pid}/create-table`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          dispatch(
            updateWarehouseSuccess({
              pid,
              warehouse: res.data,
            }),
          )
          resolve(res.data)
        })
        .catch((e) => {
          ConsoleHelper(`Error updateTestQuestions: ${e}`)
          reject(e?.response?.data?.error || e.message)
        })
    })
  }

// updateTable
export const updateTable =
  (pid: string, data: Record<string, unknown>) => (dispatch: IDispatch) => {
    return new Promise((resolve, reject) => {
      const token = getCookie('token')
      const auth = isAuth()
      axios
        .put(`${BASE_URL}/backend/${auth.email}/${pid}/update-table`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          dispatch(
            updateWarehouseSuccess({
              pid,
              warehouse: res.data,
            }),
          )
          resolve(res.data)
        })
        .catch((e) => {
          ConsoleHelper(`Error updateTestQuestions: ${e}`)
          reject(e?.response?.data?.error || e.message)
        })
    })
  }

export const deleteTable =
  (pid: string, warehouseId: string) => (dispatch: IDispatch) => {
    return new Promise((resolve, reject) => {
      const token = getCookie('token')
      const auth = isAuth()
      axios
        .delete(
          `${BASE_URL}/backend/${auth.email}/${pid}/delete-table/${warehouseId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then((res) => {
          dispatch(
            updateWarehouseSuccess({
              pid,
              warehouse: res.data,
            }),
          )
          resolve(res.data)
        })
        .catch((e) => {
          ConsoleHelper(`Error updateTestQuestions: ${e}`)
          reject(e?.response?.data?.error || e.message)
        })
    })
  }

export const getWarehouseStatus = (pid: string) => (dispatch: IDispatch) => {
  return new Promise((resolve, reject) => {
    const token = getCookie('token')
    const auth = isAuth()
    axios
      .post(
        `${BASE_URL}/backend/${auth.email}/${pid}/get-warehouse-status`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        dispatch(
          updatePoolWarehouseSuccess({
            pid,
            payload: res.data,
          }),
        )
        resolve(res.data)
      })
      .catch((e) => {
        ConsoleHelper(`Error getWarehouseStatus: ${e}`)
        reject(e?.response?.data?.error || e.message)
      })
  })
}

export default projectSlice.reducer
