import PageHeader from '@/components/pageHeader/pageHeader'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { useTrans } from '@/hooks/useTranslation'

import { CREATE_DATASET_STEP } from '../datasetCreate.constant'
import useFormDatasetCreate from './datasetCreateLayout.hook'
import { DatasetCreateLayoutProps } from './datasetCreateLayout.props'

const DatasetCreateLayout = (props: DatasetCreateLayoutProps) => {
  const { title, children, schema, defaultValues } = props
  const { t } = useTrans()

  const { handlePreStep, currentStep, onSubmit, form } = useFormDatasetCreate({
    schema,
    defaultValues,
  })

  return (
    <Form {...form}>
      <PageHeader haveSubSidebar subTitle={title}>
        <form className="w-full relative" onSubmit={onSubmit}>
          {children({ form })}
          <div className="flex justify-end gap-4 sticky bottom-0 left-0  mt-5">
            {/* Type is first step should dont show Pre */}
            {currentStep != CREATE_DATASET_STEP.NAME && (
              <Button
                className="min-w-[80px]"
                onClick={() => handlePreStep()}
                type="button"
                variant={'secondary'}
              >
                {t('previous')}
              </Button>
            )}

            <Button
              className="min-w-[80px]"
              disabled={props.isDisabledNext ?? false}
              type="submit"
            >
              {t('next')}
            </Button>
          </div>
        </form>
      </PageHeader>
    </Form>
  )
}

export default DatasetCreateLayout
