import { DATASET_TYPE } from './datasetUpdate.constant'
import { useGetDatasetDetail } from './datasetUpdate.hook'
import UpdateDatabase from './updateDatabase/updateDatabase'
import UpdateTable from './updateTable/updateTable'

const UpdateDataset = () => {
  const { inputDataDetail } = useGetDatasetDetail()
  if (!inputDataDetail) return <div></div>

  const generateProps = {
    detail: inputDataDetail,
  }

  const type = inputDataDetail.type.toUpperCase()

  switch (type) {
    case DATASET_TYPE.DATABASE:
      return <UpdateDatabase {...generateProps} />
    case DATASET_TYPE.TABLE:
      return <UpdateTable {...generateProps} />
    default:
      return <div></div>
  }
}

const UpdateDatasetContainer = () => {
  return <UpdateDataset />
}

export default UpdateDatasetContainer
