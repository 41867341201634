import { useEffect } from 'react'

import { confirmRodal } from '@/components/rodal'
import Rodal from '@/components/rodal/rodal'

import useNavBlocker from '../useNavBlocker/useNavBlocker'
import { useTrans } from '../useTranslation'
import {
  UseValidateCreateDatasetBeforeLeaveProps,
  UseValidateCreateDatasetBeforeLeaveReturn,
} from './useValidateCreateDatasetBeforeLeave.props'

export const useWarningBeforeLeave = (
  props: UseValidateCreateDatasetBeforeLeaveProps,
): UseValidateCreateDatasetBeforeLeaveReturn => {
  const { validate, dependencies } = props
  const { blocker } = useNavBlocker({
    cb: () => {
      return (location) => {
        return validate(location) as boolean
      }
    },
    dependencies,
  })

  const { t } = useTrans()

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirmRodal({
        children: ({ onClose }) => {
          const _onClose = () => {
            onClose()
          }
          return (
            <Rodal
              message={t('unsavedChanges')}
              onCancel={_onClose}
              onClose={_onClose}
              onConfirm={() => {
                blocker.proceed()
              }}
              title={t('warning')}
            />
          )
        },
      })
    }
  }, [blocker])

  return { blocker }
}
