import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons'
import { t } from 'i18next'

import { Button } from '@/components/ui/button'
import { Form, FormField, FormItem, FormMessage } from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { onEnterPress } from '@/helpers/common'

import { useQuestionItemForm } from './questionItemForm.hook'
import { ColumnFormItemProps } from './questionItemForm.props'

const QuestionItemForm = (props: ColumnFormItemProps) => {
  const { toggleEdit } = props
  const { form, onSubmit } = useQuestionItemForm({
    defaultValues: props.defaultValues,
    toggleEdit,
  })
  return (
    <Form {...form}>
      <form className="w-full mt-1" onSubmit={onSubmit}>
        <FormField
          control={form.control}
          name="question"
          render={({ field }) => (
            <FormItem>
              <Textarea
                id={props.IdFocus}
                placeholder={t('enterYourContent')}
                {...field}
                onKeyDown={onEnterPress(() => {
                  form.handleSubmit(onSubmit)()
                })}
              />
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="w-full flex justify-end gap-2 mt-4">
          <Button borderRadius="large" type="submit">
            <CheckIcon className="h-4 w-4" />
          </Button>
          <Button
            borderRadius="large"
            className="border border-n-2"
            onClick={toggleEdit}
            type="button"
            variant="link"
          >
            <Cross2Icon className="h-4 w-4" />
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default QuestionItemForm
