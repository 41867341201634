import { HamburgerMenuIcon } from '@radix-ui/react-icons'
import { Link } from 'react-router-dom'

import Logo from '@/components/Logo/Logo'
import { Button } from '@/components/ui/button'

import { useConversationLogo } from './conversationLogo.hook'

const ConversationLogo = () => {
  const { handleToggleSidebar } = useConversationLogo()

  return (
    <div className="sticky top-0 z-10 w-full flex items-center py-4">
      <div className="w-full flex items-center">
        <Button
          className="rounded-lg flex md:hidden"
          onClick={handleToggleSidebar}
          size={'icon'}
          variant={'ghost'}
        >
          <HamburgerMenuIcon className="w-5 h-5" />
        </Button>
      </div>
      <div className="w-full flex justify-center items-center">
        <Link to={'/'}>
          <Logo className="h-8 md:h-10" dark={false} />
        </Link>
      </div>
      <div className="w-full"></div>
    </div>
  )
}

export default ConversationLogo
