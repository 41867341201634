import { toast } from 'react-toastify'

import { setSubscription } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import useEffectOne from '@/hooks/useEfectOne'

import { useGetBillingParams } from '../../billing.hook'

export const useBillingSuccess = () => {
  const { success, tierName, sid, pid, canceled, clearSearchParams } =
    useGetBillingParams()
  const dispatch = useAppDispatch()

  useEffectOne(() => {
    if (tierName && (canceled || success) && pid && sid) {
      if (canceled)
        toast.error(`A problem has occurred with your payment for ${tierName}`)
      if (success) {
        toast.success(`You have purchased the ${tierName} successfully`)
        const paymentData = {
          tierName,
          sessionId: sid,
          pid,
        }
        dispatch(setSubscription(pid!, paymentData))
      }

      clearSearchParams(['tier_name', 'pid', 'sid'])
    }
  })
}
