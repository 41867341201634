import { useMemo } from 'react'

import { isAuth } from '@/helpers/auth'
import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'
import { ANY, ChannelInfo } from '@/types'

import { EChannel } from './usage.constant'
import { getInteractions } from './usage.helper'

export const useUsage = () => {
  const { params } = useRouter()
  const { role } = isAuth()
  const { pid } = params
  const { project } = useGetProject({ pid: pid! })
  const interactions = project?.interactions?.current_month

  const channelInfo = useMemo(() => {
    const _channelInfo = Object.entries(interactions?.channel_info ?? {})
    return _channelInfo.reduce(getInteractions as ANY, {}) as ANY as Record<
      EChannel,
      ChannelInfo
    >
  }, [interactions?.channel_info])

  const usersChannel = useMemo(() => {
    return (interactions?.user_info ?? []).map((userChannel) => {
      const _channelInfo = Object.entries(userChannel.channel_info).reduce(
        getInteractions as ANY,
        {},
      ) as ANY as Record<EChannel, ChannelInfo>

      return {
        user: userChannel.user,
        channel_info: _channelInfo,
      }
    })
  }, [interactions?.user_info])

  const isAdmin = role === 'admin'
  const isTeramot = role === 'teramot'

  return {
    channelInfo: channelInfo,
    usersChannel: usersChannel,
    isAdminOrTeramot: isAdmin || isTeramot,
  }
}
