import { TrashIcon } from '@radix-ui/react-icons'

import Spin from '@/components/spin/spin'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { useTrans } from '@/hooks/useTranslation'
import { cn } from '@/lib/utils'

import ChattingBody from './chattingBody/chattingBody'
import ChattingFooter from './chattingFooter/chattingFooter'
import { ConversationChattingProvider } from './conversationChatting.context'
import useConversationChatting from './conversationChatting.hook'

const ConversationChatting = () => {
  const { sizeMessage, isLoading, ref_listMessage, handleDeleteConversation } =
    useConversationChatting()

  const { t } = useTrans()

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="w-full py-2">
        <div className="flex justify-end px-3">
          {sizeMessage > 0 && (
            <AlertDialog>
              <AlertDialogTrigger
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <TrashIcon className="mr-2.5 h-5 w-5 text-red-500 cursor-pointer" />
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    {t('titleWarningDeleteChatHistory')}
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    {t('descriptionWarningDeleteChatHistory')}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>{t('cancel')}</AlertDialogCancel>
                  <AlertDialogAction onClick={handleDeleteConversation}>
                    {t('delete')}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </div>
      </div>
      <div className="w-full grow overflow-auto" ref={ref_listMessage}>
        <div
          className={cn('px-3 w-full flex flex-col max-w-[800px] mx-auto', {
            'h-full': isLoading,
          })}
        >
          {isLoading ? (
            <div className="w-full h-full flex justify-center items-center text-white">
              <Spin />
            </div>
          ) : (
            <ChattingBody />
          )}
        </div>
      </div>

      <div className="w-full px-3 max-w-[800px] mx-auto">
        <ChattingFooter />
      </div>
    </div>
  )
}

const ConversationChattingContainer = () => {
  return (
    <ConversationChattingProvider>
      <ConversationChatting />
    </ConversationChattingProvider>
  )
}

export default ConversationChattingContainer
