import * as React from 'react'

import IconMenu from '@/components/Icon/menu.svg?react'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/UserNav/dropdown-menu'
import { useTrans } from '@/hooks/useTranslation'
import { cn } from '@/lib/utils'

import FolderIcon from '../Icon/folder.svg?react'
import useMainCard from './mainCard.hook'
import { MainCardProps, MainCardRefProps } from './mainCard.props'

const MainCard = React.forwardRef<MainCardRefProps, MainCardProps>(
  (
    { className, imageSrc, title, description, pid, isAuthor = true, ...props },
    ref,
  ) => {
    const { t } = useTrans()
    const { goToSettingPage, goToDetailsPage, handleDeleteProject } =
      useMainCard({ pid })

    return (
      <div className="relative w-full">
        {isAuthor && (
          <DropdownMenu>
            <DropdownMenuTrigger
              asChild
              className="focus-visible:border-none focus-visible:ring-0"
            >
              <Button
                borderRadius="full"
                className="absolute top-8 right-8 transform -translate-y-1/2 translate-x-1/2 
          opacity-50 hover:opacity-100 active:opacity-100"
                style={{ height: '32px', width: '32px' }}
                variant="secondary"
              >
                <IconMenu className="absolute" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-60"
              style={{
                position: 'absolute',
                left: 'calc(100% - 16px)',
              }}
            >
              <DropdownMenuItem
                className="py-1.5 px-8"
                onClick={goToSettingPage}
              >
                {t('projectSettings')}
              </DropdownMenuItem>
              <DropdownMenuItem
                className="py-1.5 px-8"
                onClick={handleDeleteProject}
              >
                <span className="text-red-600"> {t('deleteProject')}</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}

        <div
          className={cn(
            'w-full rounded-lg border border-n-2 bg-card shadow cursor-pointer	',
            className,
          )}
          onClick={goToDetailsPage}
          ref={ref}
          {...props}
        >
          <div
            className={`flex justify-center overflow-hidden h-full max-h-[139px] ${imageSrc ? 'p-0' : 'p-4 pt-6'}`}
          >
            {imageSrc ? (
              <img
                alt="Card image"
                className="w-full object-cover rounded-t-lg"
                src={imageSrc}
              />
            ) : (
              <FolderIcon />
            )}
          </div>
          <div className="mx-4">
            <Separator />
          </div>
          <div className="px-4 py-2 w-full overflow-hidden">
            <div className="pb-2">
              <h3 className="h4 break-words line-clamp-1 break-all">{title}</h3>
            </div>
            <div className="pb-2">
              <p className="text-n-3 caption1 line-clamp-1 break-all">
                {description || ''}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  },
)
MainCard.displayName = 'MainCard'

export default MainCard
