import { Header } from '@/components/layout'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Skeleton } from '@/components/ui/skeleton'

import useUserSetting, { useDeleteAccountUser } from './userSetting.hook'

const UserSetting = () => {
  const { t, isLoading, form, onSubmit, onGoBack } = useUserSetting()
  const { handleDeleteAccount } = useDeleteAccountUser()

  return (
    <div className="h-screen w-screen flex flex-col">
      <Header />
      <div className="container">
        <div className="w-full border-b flex items-center justify-between py-4">
          <p className="text-h1">{t('userSettings')}</p>
          <Button onClick={onGoBack} variant={'outline'}>
            {t('goBack')}
          </Button>
        </div>
        <div className="w-full md:w-3/4 mt-8">
          {isLoading ? (
            <div className="flex flex-col gap-y-4 w-full">
              <Skeleton className="h-[50px] w-full rounded-xl" />
              <Skeleton className="h-[50px] w-full rounded-xl" />
              <Skeleton className="h-[50px] w-full rounded-xl" />
            </div>
          ) : (
            <Form {...form}>
              <form className="w-full" onSubmit={onSubmit}>
                <div className="flex flex-col gap-y-4 w-full">
                  <div className="space-y-[6px]">
                    <label className="caption2" htmlFor="email">
                      {t('name')} *
                    </label>
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              className="h-[40px]"
                              {...field}
                              placeholder={t('name')}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="space-y-[6px]">
                    <label className="caption2" htmlFor="email">
                      {t('company')}
                    </label>
                    <FormField
                      control={form.control}
                      name="company"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              className="h-[40px]"
                              {...field}
                              placeholder={t('company')}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="space-y-[6px]">
                    <label className="caption2" htmlFor="email">
                      {t('email')}
                    </label>
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              className="h-[40px]"
                              type="email"
                              {...field}
                              disabled
                              placeholder={t('email')}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormMessage />
                </div>

                <div className="w-full mt-16 flex justify-end gap-4">
                  <Button
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      handleDeleteAccount()
                    }}
                    variant={'danger'}
                  >
                    {t('deleteAccount')}
                  </Button>
                  <Button variant={'primary'}>{t('saveChanges')}</Button>
                </div>
              </form>
            </Form>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserSetting
