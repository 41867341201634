import { ReactNode } from 'react'

import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'

interface IProps {
  haveSubSidebar?: boolean
  title?: string
  subTitle?: string
  children: ReactNode
  extra?: ReactNode
}
const PageHeader = (props: IProps) => {
  const isHaveSubSidebar = props.haveSubSidebar

  const pageHeaderClass = cn('w-full h-full sm:pt-[90px] overflow-auto', {
    'xl:px-36': !isHaveSubSidebar,
    'pt-[30px] lg:px-[64px] [&>div]:pt-10 [&>div]:sm:pt-[30px]':
      isHaveSubSidebar,
  })

  return (
    <div className={pageHeaderClass}>
      <div className="container pb-20 pt-8">
        {props.title && <p className="h2 text-n-3 mb-4">{props.title}</p>}
        {props.subTitle && (
          <p className="h4 text-text-gray mb-8">{props.subTitle}</p>
        )}
        {props.extra && <div className="mb-8">{props.extra}</div>}
        {(props.title || props.subTitle) && <Separator className="mb-8" />}
        {props.children}
      </div>
    </div>
  )
}

export default PageHeader
