import { useTrans } from '@/hooks/useTranslation'
import yup from '@/validates'

export const useUpdateConnectionSchema = () => {
  const { t } = useTrans()

  return {
    databaseSchema: yup.object().shape({
      dbType: yup.string().required(t('dbTypeRequired')),
      host: yup.string().required(t('dbHostRequired')),
      port: yup.string().required(t('dbPortRequired')),
      dbName: yup.string().required(t('dbNameRequired')),
      user: yup.string().required(t('dbUserRequired')),
      pwd: yup.string().required(t('dbPasswordRequired')),
    }),
    bigQuerySchema: yup.object().shape({
      type: yup.string().required(t('fieldRequired')),
      gcp_project_id: yup.string().required(t('fieldRequired')),
      gcp_dataset_id: yup.string().required(t('fieldRequired')),
      gcp_credentials: yup.string().required(t('fieldRequired')),
    }),
  }
}
