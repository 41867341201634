/* eslint-disable max-len */
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

import {
  useGetProjectOptions,
  useSelectProject,
} from './conversationSelectProject.hook'

const ConversationSelectProject = () => {
  const { projectOptions } = useGetProjectOptions()
  const { pid, handleSelectProject } = useSelectProject()

  return (
    <div className="w-full flex items-center px-1">
      <Select onValueChange={handleSelectProject} value={pid}>
        <SelectTrigger className="w-full pr-2 text-white font-bold text-base border-none focus:border-none focus:ring-0">
          <SelectValue placeholder="Select Project" />
        </SelectTrigger>
        <SelectContent>
          {projectOptions.map((option, index) => (
            <SelectItem key={index} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}

export default ConversationSelectProject
