import IcEmail from '@/components/Icon/mail.svg?react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from '@/components/ui/dialog'
import { useTrans } from '@/hooks/useTranslation'

import { ModalSuccessForgotPasswordProps } from './modalSuccessForgotPassword.props'

const ModalSuccessForgotPassword = (props: ModalSuccessForgotPasswordProps) => {
  const { isOpen, onClose } = props
  const { t } = useTrans()
  return (
    <Dialog onOpenChange={onClose} open={isOpen}>
      <DialogContent className="sm:max-w-[312px]">
        <DialogHeader>
          <DialogDescription>
            <div className="flex justify-center">
              <IcEmail className="w-6 h-6" />
            </div>
            <div className="mt-3 p-3">
              <p className="h5 text-center font-medium text-black">
                {t('linkSend')}
              </p>
              <p className="w-full h5 text-center font-normal text-text-gray mt-1">
                {t('linkSendDescription')}
              </p>
            </div>
          </DialogDescription>
        </DialogHeader>

        <DialogFooter className="w-full flex sm:justify-center">
          <button
            className="base font-semibold text-text-blue"
            onClick={props.onResend}
          >
            {t('reSendLink')}
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ModalSuccessForgotPassword
