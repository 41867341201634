// import { toast } from 'react-toastify'

import { isAuth } from '@/helpers/auth'

// import { useTrans } from '@/hooks/useTranslation'
import { useProjectEditContext } from '../projectEdit.context'

const useProjectMembers = () => {
  // const { t } = useTrans()
  const { form } = useProjectEditContext()
  const author = isAuth().email

  const listEmail = form.watch('email') || []
  const projectAuthor = form.watch('author')

  const onRemoveEmail = (index: number) => {
    const emailRemove = listEmail[index]
    if (emailRemove === author || emailRemove === projectAuthor) {
      // toast.error(t('errorMessageRemoveAuthor'))
      return
    }

    listEmail.splice(index, 1)
    form.setValue('email', listEmail)
  }

  return {
    author,
    projectAuthor,
    listEmail,
    onRemoveEmail,
  }
}

export default useProjectMembers
