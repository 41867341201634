import { useEffect, useRef } from 'react'
import { useFieldArray } from 'react-hook-form'

import { browserCacheKey } from '@/constants/common'
import { genTableQuestionUUID } from '@/helpers/genUUID'
import useBrowserCache from '@/hooks/useBrowserCache'
import { useRouter } from '@/hooks/useRouter'
import { ANY } from '@/types'

import { KNOWLEDGE_LIST_FORM_KEY } from './knowledgeList.constant'
import { useKnowledgeListContext } from './knowledgeList.context'

export const useKnowledgeList = () => {
  const { form } = useKnowledgeListContext()
  const { params } = useRouter()
  const { pid, id } = params
  const refFirstRender = useRef(true)

  const {
    getCachedData,
    insertCacheData,
    deleteCacheData,
    insertOrUpdateCacheData,
  } = useBrowserCache(browserCacheKey.tables)

  const updateKnowledgeForm = (
    knowledge: Array<{ content: string; cacheId: string; _id: string }>,
  ) => {
    const listKnowledge =
      form.getValues(KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE) ?? []
    const _list = Array.isArray(listKnowledge) ? listKnowledge : [listKnowledge]

    form.setValue(KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE, _list.concat(knowledge))
  }

  useEffect(() => {
    if (!pid || !id) {
      return
    }
    if (!refFirstRender.current) {
      return
    }

    const loadCache = async () => {
      getCachedData({
        pid,
        warehouseId: id,
        source: KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE,
      }).then((cache) => {
        if (cache) {
          const _listKnowledge = cache.reduce(
            (
              t: ANY,
              item: { _id: string; knowledge: string; cacheId: string },
            ) => {
              const key = item._id
              return {
                ...t,
                [key]: {
                  content: item.knowledge as string,
                  cacheId: item.cacheId as string,
                  _id: item._id as string,
                },
              }
            },
            {},
          )

          const listQuestion =
            form.getValues(KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE) ?? []

          const _knowledge = listQuestion.map((item: ANY) => {
            const exits = _listKnowledge[item._id]
            if (exits) {
              delete _listKnowledge[exits._id]
              return {
                ...item,
                content: exits.content,
              }
            }

            return item
          })
          form.setValue(
            KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE,
            _knowledge.concat(Object.values(_listKnowledge)),
          )
        }
      })
    }
    loadCache()
    refFirstRender.current = false

    return () => {
      form.resetField(KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE)
    }
  }, [])

  const handleAddKnowledge = (knowledge: string) => {
    if (!knowledge) return

    const cacheId = genTableQuestionUUID()

    updateKnowledgeForm([
      {
        _id: cacheId,
        content: knowledge,
        cacheId: cacheId,
      },
    ])
    insertCacheData({
      pid: pid,
      warehouseId: id,
      source: KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE,
      knowledge: knowledge,
      cacheId: cacheId,
      _id: cacheId,
    })
    form.setValue(KNOWLEDGE_LIST_FORM_KEY.ADD_KNOWLEDGE, '')
  }

  const handleUpdateKnowledge = (knowledge: {
    _id: string
    content: string
  }) => {
    insertOrUpdateCacheData({
      where: {
        pid: pid,
        warehouseId: id,
        source: KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE,
        _id: knowledge._id,
      },
      data: {
        pid: pid,
        warehouseId: id,
        source: KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE,
        _id: knowledge._id,
        knowledge: knowledge.content,
        cacheId: knowledge._id,
      },
      options: {
        upsert: true,
      },
    })
  }

  const { fields, remove } = useFieldArray({
    control: form.control,
    name: KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE,
  })

  const handleRemoveKnowledge = (
    index: number,
    cacheKnowledgeId: string | null,
  ) => {
    remove(index)

    if (!cacheKnowledgeId) return
    // If cacheKnowledgeId is not null, delete cache data
    deleteCacheData({
      pid: pid,
      warehouseId: id,
      source: KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE,
      cacheId: cacheKnowledgeId,
    })
  }

  return {
    form,
    handleAddKnowledge,
    knowledgeFields: fields,
    handleRemoveKnowledge: handleRemoveKnowledge,
    handleUpdateKnowledge,
  }
}
