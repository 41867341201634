import { useCallback, useEffect } from 'react'

import { CHATBOT_SOCKET_EMIT, CHATBOT_SOCKET_LISTEN } from '@/constants/common'
import { useRouter } from '@/hooks/useRouter'
import { chatBotSocket } from '@/services/socket/chatbot.socket'
import { ANY } from '@/types'

import { useChatBotContext } from '../chatbot.context'
import { MESSAGE_TYPE } from '../messageItem/messageItem.props'
import { UseChatMessageProps } from './useChatMessage.props'

export const useChatMessage = (props: UseChatMessageProps) => {
  const { params } = useRouter()
  const { setNewMessage, isShowMessage } = useChatBotContext()
  const clientId = localStorage.getItem('clientId')
  const { pid } = params
  const { updateMessage } = props
  const onMessage = useCallback((message: string) => {
    updateMessage({ text: message, sender: MESSAGE_TYPE.BOT })
    if (!isShowMessage) setNewMessage(1)
  }, [])

  const handleSendMessage = useCallback((message: string) => {
    chatBotSocket.sendMessage(
      CHATBOT_SOCKET_EMIT.CHAT_MESSAGE,
      { clientId, pid: pid, message: message },
      (response: ANY) => {
        if (response && response.success) {
          updateMessage({ text: message, sender: MESSAGE_TYPE.USER })
        }
      },
    )
  }, [])

  useEffect(() => {
    chatBotSocket.onListenMessage(CHATBOT_SOCKET_LISTEN.CHAT_MESSAGE, onMessage)

    return () => {
      chatBotSocket.offListenMessage(CHATBOT_SOCKET_LISTEN.CHAT_MESSAGE)
    }
  }, [])

  return { handleSendMessage }
}
