import { useState } from 'react'
import { toast } from 'react-toastify'

import { gptModelKey } from '@/constants/common'
import { setConfiguration } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useTrans } from '@/hooks/useTranslation'
import { IProject, IProjectConfigurationData } from '@/types'

interface IProps {
  pid: string
  configuration: IProjectConfigurationData
  project: IProject
}
export const useKnowledgeList = (props: IProps) => {
  const { configuration, project, pid } = props

  const { t } = useTrans()
  const dispatch = useAppDispatch()
  const [isCreate, setIsCreate] = useState(false)
  const toggleCreate = () => {
    setIsCreate((old) => !old)
  }

  const handleSaveKnowledge = () => {
    const { botName, lang } = configuration

    const inputEntries = Object.entries(project.inputData)

    const oldTopicName = configuration?.topicName ?? []
    const newTopicName = inputEntries.reduce((t, v) => {
      const [key, val] = v
      if (!oldTopicName[key]) {
        return { ...t, [key]: val.type }
      }
      return { ...t, [key]: oldTopicName[key] }
    }, {})

    const oldKnowledge = configuration?.knowledge ?? {}
    const newKnowledge = inputEntries.reduce((t, v) => {
      const [key] = v
      if (!oldKnowledge[key]) {
        return { ...t, [key]: [] }
      }
      return { ...t, [key]: oldKnowledge[key] }
    }, {})

    const oldKnowledgeGeneral = configuration?.knowledgeGeneral ?? []

    const _configuration = {
      botName,
      lang,
      topicName: newTopicName,
      knowledge: newKnowledge,
      knowledgeGeneral: oldKnowledgeGeneral,
    }
    const promise = dispatch(setConfiguration(pid, { ..._configuration }))
    toast.promise(promise, {
      pending: t('processingConfiguration'),
      success: t('configurationSetSuccessfully'),
      error: t('errorSettingConfiguringBot'),
    })
    promise.catch((e) => {
      if (e?.key) toast.error(t(e.key))
    })
  }

  return {
    dispatch,
    isCreate,
    toggleCreate,
    handleSaveKnowledge,
  }
}
