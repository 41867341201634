import { Outlet } from 'react-router'
import { Navigate } from 'react-router-dom'

import useGetUserRole from '@/hooks/useGetUserRole/useGetUserRole'
import { useRouter } from '@/hooks/useRouter'

import { RolesProjectRouteProps } from './rolesProjectRoute.props'

const RolesProjectRoute = (props: RolesProjectRouteProps) => {
  const { params } = useRouter()
  const { pid } = params
  const { role } = useGetUserRole({ pid: pid })

  // if (role === null) return <Navigate to="/projects" />
  // if (!props.roles.includes(role)) return <Navigate to="/projects" />

  return <Outlet />
}

export default RolesProjectRoute
