import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { UseFormAddEmailProps } from './formAddEmail.props'
import { useFormAddEmailValidate } from './formAddEmail.validate'

const useFormAddEmail = ({ submitCallback }: UseFormAddEmailProps) => {
  const { schema } = useFormAddEmailValidate()

  const form = useForm({
    defaultValues: { email: '', name: '' },
    resolver: yupResolver(schema),
  })

  const onSubmit = form.handleSubmit(
    async (payload: Record<string, string>) => {
      const isSubmitSuccess = submitCallback(payload)
      if (isSubmitSuccess) {
        form.reset()
        form.setFocus('email')
      }
    },
  )

  return {
    form,
    onSubmit,
  }
}

export default useFormAddEmail
