import React, { ReactNode, useEffect, useMemo, useRef } from 'react'

import { getCookie, isAuth } from '../helpers/auth'

interface SessionContextProps {
  session: boolean
  setSession: React.Dispatch<React.SetStateAction<boolean>>
}

export const SessionContext = React.createContext<SessionContextProps | null>(
  null,
)

export const SessionProvider = ({ children }: { children: ReactNode }) => {
  const firstEffectAuth = useRef(true)
  const [session, setSession] = React.useState(() => {
    const auth = isAuth()
    return auth ? true : false
  })

  // check when token changes
  const cookieChecked = getCookie('token')
  const auth = useMemo(() => isAuth(), [cookieChecked])
  useEffect(() => {
    if (firstEffectAuth.current) {
      firstEffectAuth.current = false
      return
    }

    auth ? setSession(true) : setSession(false)
  }, [auth])

  return (
    <SessionContext.Provider value={{ session, setSession }}>
      {children}
    </SessionContext.Provider>
  )
}

export const useSession = () => {
  const ctx = React.useContext(SessionContext)
  if (!ctx) throw new Error('useSession must be used within a SessionProvider')

  return ctx
}

export default SessionContext
