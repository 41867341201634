import { createContext } from 'react'

import { useCtx } from '@/hooks/useCtx'

import { useDataWarehouseChange } from './dataWarehouseUpdate.hook'
import {
  IDataWarehouseUpdateContext,
  IDataWarehouseUpdateProvider,
} from './dataWarehouseUpdate.props'

export const DataWarehouseUpdateContext =
  createContext<IDataWarehouseUpdateContext | null>(null)

export const DataWarehouseUpdateProvider = ({
  children,
  form,
  warehouse,
}: IDataWarehouseUpdateProvider) => {
  // Run when the component is mounted and when the warehouse changes
  useDataWarehouseChange(form, warehouse)
  return (
    <DataWarehouseUpdateContext.Provider value={{ form }}>
      {children}
    </DataWarehouseUpdateContext.Provider>
  )
}

export const useDataWarehouseUpdateContext = () => {
  const ctx = useCtx<IDataWarehouseUpdateContext>(DataWarehouseUpdateContext)

  return ctx
}
