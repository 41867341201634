import { cn } from '@/lib/utils'

import { Textarea } from '../ui/textarea'
import useJsonInput from './jsonInput.hook'
import { IJsonInput } from './jsonInput.props'

const JsonInput = (props: IJsonInput) => {
  const { isError, placeholder, jsonInput, handleInputChange } =
    useJsonInput(props)

  return (
    <div className="w-full">
      <Textarea
        className="h-auto"
        onChange={handleInputChange}
        placeholder={placeholder}
        rows={10}
        value={jsonInput}
      />
      <p
        className={cn('hidden text-[0.8rem] mt-1 font-medium', {
          'text-[#ef4444] block': isError,
        })}
      >
        Invalid JSON
      </p>
    </div>
  )
}

export default JsonInput
