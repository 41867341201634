import { useEffect, useRef } from 'react'
import { toast } from 'react-toastify'

import { browserCacheKey } from '@/constants/common'
import { useProject } from '@/contexts/projectContext'
import { setQuestions } from '@/features/project/projectSlice'
import { createQuestion } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import useBrowserCache from '@/hooks/useBrowserCache'
import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'

export const useQuestionList = () => {
  const { params, navigate } = useRouter()
  const ref_isRestoreCache = useRef(false)
  const { getCachedData, insertCacheData, updateCacheData, clearCache } =
    useBrowserCache(browserCacheKey.questions)

  const { toggleFlagDataPageQuestion, resetFlagDataPageQuestion } = useProject()
  const dispatch = useAppDispatch()
  const { t } = useTrans()
  const { pid, dataset_id } = params

  const { project } = useGetProject({ pid: pid! })

  const listQuestion = project.questions?.map((item, index) => {
    return {
      name: `${t('question')} #${index + 1}`,
      question: item,
    }
  })

  useEffect(() => {
    if (!listQuestion || !pid) {
      return
    }

    getCachedData({ pid }, true).then((cache) => {
      if (cache) {
        if (ref_isRestoreCache.current) {
          updateCacheData({
            where: { pid },
            data: { questions: listQuestion },
          })
        } else {
          ref_isRestoreCache.current = true

          const listQuestionCached = cache.questions.map(
            (e: Record<string, unknown>) => e.question,
          )

          const listQuestionProject = listQuestion.map((e) => e.question)

          const shouldRestore = listQuestionProject.every((e) =>
            listQuestionCached.includes(e),
          )

          const dataRestore = listQuestionCached.filter(
            (e: string) => !listQuestionProject.includes(e),
          )

          if (shouldRestore && dataRestore?.length) {
            // update store redux
            setTimeout(() => {
              toggleFlagDataPageQuestion()
              dataRestore.forEach((_questionItem: string) => {
                const payload = {
                  pid,
                  question: _questionItem,
                }
                dispatch(createQuestion(payload))
              })
            }, 100)
          }
        }
      } else {
        insertCacheData({ pid, questions: listQuestion })
      }
    })
  }, [
    pid,
    listQuestion,
    getCachedData,
    insertCacheData,
    updateCacheData,
    dispatch,
    toggleFlagDataPageQuestion,
  ])

  const handleSaveQuestionToDB = () => {
    const _payload = {
      questions: project.questions,
    }
    const promise = dispatch(setQuestions(pid!, { ..._payload }))
    toast.promise(promise, {
      pending: t('processingConfiguration'),
      success: t('configurationSetSuccessfully'),
      error: t('errorSettingConfiguringBot'),
    })
    promise.then(() => {
      clearCache()
      resetFlagDataPageQuestion()
      navigate(`/project/${pid}/dataset`)
    })
  }

  return {
    listQuestion: listQuestion ?? [],
    sizeListQuestion: listQuestion?.length ?? 0,
    pid,
    datasetId: dataset_id,
    handleSaveQuestionToDB,
  }
}
