import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons'

import { Button } from '@/components/ui/button'
import { FormLabel } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { onEnterPress } from '@/helpers/common'
import { useTrans } from '@/hooks/useTranslation'

import { useUpdateInfoForm } from './updateInfoForm.hook'
import { UpdateInfoFormProps } from './updateInfoForm.props'

const UpdateInfoForm = (props: UpdateInfoFormProps) => {
  const { isOpen, onClose } = props
  const { t } = useTrans()
  const { formData, onChange, handleSubmit } = useUpdateInfoForm(props)

  if (!isOpen) return <></>
  return (
    <div className="w-full space-y-2 py-4 px-6 pt-0">
      <div className="space-y-2">
        <FormLabel className="font-semibold inline-block w-full text-left">
          {t('name')}
        </FormLabel>
        <Input
          defaultValue={formData.displayName}
          id={props.idNameFocus}
          onChange={(e) => {
            onChange('displayName', e)
          }}
          onFocus={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
          onKeyDown={onEnterPress((e) => {
            handleSubmit(e)
          })}
          placeholder={t('enterYourContent')}
        />
      </div>
      <div className="space-y-2">
        <FormLabel className="font-semibold  inline-block w-full text-left">
          {t('description')}
        </FormLabel>
        <Textarea
          defaultValue={formData.description}
          id={props.idDescriptionFocus}
          onChange={(e) => {
            onChange('description', e)
          }}
          onKeyDown={onEnterPress((e) => {
            handleSubmit(e)
          })}
          placeholder={t('enterYourContent')}
        />
      </div>

      <div className="w-full flex justify-end gap-2 mt-4">
        <Button borderRadius="large" onClick={handleSubmit} type="button">
          <CheckIcon className="h-4 w-4" />
        </Button>
        <Button
          borderRadius="large"
          className="border border-n-2"
          onClick={onClose}
          type="button"
          variant="link"
        >
          <Cross2Icon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  )
}

export default UpdateInfoForm
