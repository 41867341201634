import { Navigate } from 'react-router'

import { useGetDatasetProject } from './dataset.hook'
import DatasetEmpty from './datasetEmpty/datasetEmpty'

const HOCDataSet = () => {
  const { pid, firstDataset } = useGetDatasetProject()

  if (firstDataset) {
    return <Navigate to={`/project/${pid}/dataset/${firstDataset.iid}`} />
  }

  return <DatasetEmpty />
}

export default HOCDataSet
