import {
  faArrowRightFromBracket,
  faGear,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useSession } from '@/contexts/sessionContext'
import { isAuth, signout } from '@/helpers/auth'
import { useRouter } from '@/hooks/useRouter'

import { Avatar, AvatarFallback } from './avatar'
import { Button } from './button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './dropdown-menu'

const UserNav = () => {
  const { t } = useTranslation()
  const author = isAuth()
  const { navigate } = useRouter()
  const { setSession } = useSession()

  const nameFallback = useMemo(() => {
    return (
      author.name
        ?.split(' ')
        ?.map((text: string) => text?.[0])
        ?.join('')
        ?.slice(0, 2) ?? 'T'
    )
  }, [author.name])

  const handleSignout = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault()
    signout(() => {
      toast.success('Logout Successfully')
      setSession(false)
      navigate('/login')
    })
  }

  const handleSettings = () => {
    navigate('/user-setting')
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="relative h-10 w-10 rounded-full" variant="ghost">
          <Avatar>
            <AvatarFallback>{nameFallback}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56 mt-2" forceMount>
        <DropdownMenuItem onClick={handleSettings}>
          <FontAwesomeIcon className="mr-4" icon={faGear} />
          {t('settings')}
        </DropdownMenuItem>

        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleSignout}>
          <FontAwesomeIcon className="mr-4" icon={faArrowRightFromBracket} />
          {t('logOut')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
export default UserNav
