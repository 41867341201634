import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input, InputPassword } from '@/components/ui/input'
import { useTrans } from '@/hooks/useTranslation'

import { DatabaseSQLProps } from './databaseSQL.props'

const DatabaseSQL = (props: DatabaseSQLProps) => {
  const { form } = props
  const { t } = useTrans()
  return (
    <>
      <FormField
        control={form.control}
        name="dbConfig.host"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-semibold" requiredField>
              {t('host')}
            </FormLabel>
            <FormControl>
              <Input {...field} placeholder={t('placeholderHost')} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="dbConfig.port"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-semibold" requiredField>
              {t('port')}
            </FormLabel>
            <FormControl>
              <Input {...field} placeholder={t('placeholderPort')} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="dbConfig.dbName"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-semibold" requiredField>
              {t('dbName')}
            </FormLabel>
            <FormControl>
              <Input {...field} placeholder={t('placeholderDBName')} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="dbConfig.user"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-semibold" requiredField>
              {t('user')}
            </FormLabel>
            <FormControl>
              <Input {...field} placeholder={t('placeholderUser')} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="dbConfig.pwd"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-semibold" requiredField>
              {t('password')}
            </FormLabel>
            <FormControl>
              <InputPassword
                {...field}
                placeholder={t('placeholderPassword')}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  )
}

export default DatabaseSQL
