import { createContext } from 'react'

import { useCtx } from '@/hooks/useCtx'

import {
  QuestionListContextProps,
  QuestionListProviderProps,
} from './questionList.props'

export const QuestionListContext =
  createContext<QuestionListContextProps | null>(null)

export const QuestionListProvider = ({
  children,
  value,
}: QuestionListProviderProps) => {
  return (
    <QuestionListContext.Provider value={value}>
      {children}
    </QuestionListContext.Provider>
  )
}

export const useQuestionListContext = () => {
  const ctx = useCtx<QuestionListContextProps>(QuestionListContext)

  return ctx
}
