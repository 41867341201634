import * as marked from 'marked'
import { useMemo } from 'react'

import { CONVERSATION_MESSAGE_TYPE } from './chattingMessage.constant'
import { IChattingMessage } from './chattingMessage.props'

const useChattingMessage = (props: IChattingMessage) => {
  const { data } = props

  const messageType = useMemo(() => {
    const sender = props?.data?.sender
    if (sender === 'bot') return CONVERSATION_MESSAGE_TYPE.BOT
    if (sender === 'user') return CONVERSATION_MESSAGE_TYPE.USER
    return null
  }, [props?.data?.sender])

  const message = useMemo(() => {
    return {
      text: marked.parse(data?.text as string),
      sender: data?.sender,
    }
  }, [JSON.stringify(data)])

  return { messageType, messageData: message }
}

export default useChattingMessage
