import { Outlet } from 'react-router'
import { Navigate } from 'react-router-dom'

import { useSession } from '@/contexts/sessionContext'

import { PublicRouteProps } from './publicRoute.props'

/**
 *
 * @param props
 * @param props.restricted if true, only authenticated users redirected to home route
 * @returns Component
 * @returns
 */
const PublicRoute = (props: PublicRouteProps) => {
  const { restricted } = props
  const { session } = useSession()
  if (session && restricted) return <Navigate to="/" />
  return <Outlet />
}

export default PublicRoute
