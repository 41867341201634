/* eslint-disable @typescript-eslint/no-explicit-any */
import localForage from 'localforage'

const INDEXED_DB_NAME = 'TERRAMOT'
const INDEXED_DB_PRIMARY_FILED = 'indexed_key_'

class IndexedDB {
  private instance: IndexedDB | null = null

  constructor() {
    if (this.instance) {
      return this.instance
    }

    this.instance = this
  }

  private async _connectStore(table: string) {
    return await localForage.createInstance({
      driver: localForage.INDEXEDDB,
      name: INDEXED_DB_NAME,
      storeName: table,
    })
  }

  private async _filterData({
    store,
    where,
    isFindOne,
  }: {
    store: LocalForage
    where?: Record<string, any>
    isFindOne?: boolean
  }) {
    const result: unknown[] = []
    return await store
      .iterate(function (value: any) {
        if (isFindOne && !where) return null

        if (typeof value !== 'object' || !value) {
          return isFindOne ? null : []
        }

        let isMatch = true
        if (where) {
          for (const prop in where) {
            if (where[prop] !== value[prop]) {
              isMatch = false
              break
            }
          }
        }

        if (isMatch) {
          if (isFindOne) return value
          result.push(value)
        }
      })
      .then((valueOfFindOne) => (isFindOne ? valueOfFindOne : result))
      .catch(() => (isFindOne ? [] : null))
  }

  findOne = async (
    table: string,
    { where }: { where?: Record<string, any> },
  ) => {
    const store = await this._connectStore(table)
    if (!store) return []

    return await this._filterData({ store, where, isFindOne: true })
  }

  findMany = async (
    table: string,
    { where }: { where?: Record<string, any> },
  ) => {
    const store = await this._connectStore(table)
    if (!store) return []

    return await this._filterData({ store, where })
  }

  update = async (
    table: string,
    {
      where,
      data,
      options,
    }: {
      data: Record<string, any>
      where: Record<string, any>
      options?: {
        upsert?: boolean
      }
    },
  ) => {
    const store = await this._connectStore(table)
    if (!store) return

    const records = await this._filterData({ store, where })
    if (!records?.length) {
      if (options?.upsert) {
        await this.create(table, {
          ...where,
          ...data,
        })
      }
      return
    }

    records.forEach((record: any) => {
      const updatedRecord = {
        ...record,
        ...data,
      }
      store.setItem(record[INDEXED_DB_PRIMARY_FILED], updatedRecord)
    })
  }

  create = async (table: string, data = {}) => {
    const store = await this._connectStore(table)
    if (!store || !data || !Object.keys(data)?.length) return
    const count = await store.length()
    const newRecordId = INDEXED_DB_PRIMARY_FILED + (count + 1)
    const newRecord = {
      ...data,
      [INDEXED_DB_PRIMARY_FILED]: newRecordId,
    }
    store.setItem(newRecordId, newRecord)
  }

  delete = async (
    table: string,
    { where }: { where?: Record<string, any> },
  ) => {
    const store = await this._connectStore(table)
    if (!store || !where) return

    const records = await this._filterData({ store, where })
    if (!records) return

    records.forEach((record: any) => {
      store.removeItem(record[INDEXED_DB_PRIMARY_FILED])
    })
  }

  dropTable = async (table: string) => {
    const store = await this._connectStore(table)
    if (!store) return

    await store.clear()
  }
}

const indexedDB = new IndexedDB()
export default indexedDB
