import { useContext } from 'react'

import { ANY } from '@/types'

export const useCtx = <T>(context: ANY) => {
  const ctx = useContext(context) as T
  if (!ctx) {
    throw new Error(
      'useCtx must be inside a Provider with a value' + context.name,
    )
  }
  return ctx
}
