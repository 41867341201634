import yup from '@/validates'

export const useResetPasswordSchema = () => {
  return {
    schema: yup.object().shape({
      password1: yup
        .string()
        .required('Password is required')
        .min(8, 'Password must contain at least 8 characters')
        .matches(/[a-z]/, 'Password must contain lowercase letters')
        .matches(/[A-Z]/, 'Password must contain uppercase letters')
        .matches(/[0-9]/, 'Password must contain numbers'),
      password2: yup
        .string()
        .oneOf([yup.ref('password1')], 'Passwords must match')
        .required('Password is required')
        .min(8, 'Password must contain at least 8 characters')
        .matches(/[a-z]/, 'Password must contain lowercase letters')
        .matches(/[A-Z]/, 'Password must contain uppercase letters')
        .matches(/[0-9]/, 'Password must contain numbers'),
    }),
  }
}
