import { toast } from 'react-toastify'

import {
  deleteSubscription,
  upgradeSubscription,
} from '@/features/project/projectSlice'
import { isAuth } from '@/helpers/auth'
import { useAppDispatch } from '@/hooks'
import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'

import { getUpgradeTier } from './billingInfo.helper'

export const useBillingInfo = () => {
  const { params } = useRouter()
  const pid = params['pid']
  const { email, role } = isAuth()
  const { project } = useGetProject({ pid: pid! })
  const subscription = project.subscription

  const dispatch = useAppDispatch()

  const nextTier = getUpgradeTier(subscription?.tierName)
  const handleUpgradeSubscription = () => {
    // console.log("Upgrade subscription ", pid, " ", nextTier)
    const data = {
      tierName: nextTier,
    }
    dispatch(upgradeSubscription(pid!, data))
      .then(() => {
        toast.success('Subscription upgraded')
      })
      .catch(() => {
        // console.log("Error upgrading subscription: ", err)
        toast.error("Your subscription couldn't be upgraded")
      })
  }

  const handleTeramotCleanup = () => {
    const data = {
      pid: pid,
      email: email,
      sessionId: 'teramot',
    }
    dispatch(deleteSubscription(pid!, data)).catch(() => {
      toast.error("Your subscription couldn't be deleted")
    })
  }

  const isActive =
    subscription?.status === 'active' || subscription?.status === 'trialing'
  const isTeramot = subscription?.sessionId === 'teramot'

  return {
    role,
    subscription,
    isActive,
    isTeramot,
    nextTier,
    email,
    handleUpgradeSubscription,
    handleTeramotCleanup,
  }
}
