import { Link } from 'react-router-dom'

import logoDark from '@/assets/images/logos/logoDark.svg'
import logoLight from '@/assets/images/logos/logoLight.svg'

import { LogoProps } from './Logo.props'

const Logo = ({ className, dark }: LogoProps) => {
  const logo = dark ? logoDark : logoLight

  return (
    <Link className={`flex w-[11.88rem] ${className}`} to="/projects">
      <img
        alt="Logo"
        className="w-full h-auto"
        src={logo as unknown as string}
        style={{ width: '180px' }}
      />
    </Link>
  )
}

export default Logo
