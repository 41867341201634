import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { BASE_URL } from '@/constants/common'
import axios from '@/services/http/axiosInstance'

import { useResetPasswordSchema } from './resetPassword.validate'

const useResetPassword = () => {
  const { schema } = useResetPasswordSchema()
  const navigate = useNavigate()
  const form = useForm({
    resolver: yupResolver(schema),
  })

  const params = useParams()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = form.handleSubmit((values: any) => {
    const { password1, password2 } = values
    const token = params.token as string | null
    if (password1 === password2 && password1 && password2) {
      axios
        .put(`${BASE_URL}/backend/resetpassword`, {
          newPassword: password1,
          resetPasswordLink: token,
        })
        .then((res) => {
          form.reset()
          toast.success(res.data.message)
          navigate('/login')
        })
        .catch((err) => {
          if (err.response.data.errors) toast.error(err.response.data.errors)
          else if (err.response) {
            toast.error(
              'Error connecting to Server - ' +
                err.response.status +
                ' ' +
                err.response.statusText,
            )
          } else {
            toast.error('Error: No connection to server. Contact support')
          }
        })
    } else {
      toast.error("Passwords don't match")
    }
  })

  return {
    form,
    handleSubmit,
  }
}

export default useResetPassword
