import { FieldValues, UseFormReturn } from 'react-hook-form'

export interface IconProps {
  width?: string
  height?: string
  className?: string
  style?: Record<string, string>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ANY = any

export type FormInstanceProps = UseFormReturn<
  {
    [x: string]: ANY
  },
  ANY,
  FieldValues
>

export enum ELang {
  en = 'en',
  es = 'es',
}
