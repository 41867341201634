import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { useProject } from '@/contexts/projectContext'
import { updateQuestion } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { ANY } from '@/types'

import { UseColumnFormItemProps } from './questionItemForm.props'
import { useColumnFormItemValidate } from './questionItemForm.validate'

export const useQuestionItemForm = (props: UseColumnFormItemProps) => {
  const { schema } = useColumnFormItemValidate()
  const { toggleFlagDataPageQuestion } = useProject()

  const dispatch = useAppDispatch()
  const _props = useForm({
    defaultValues: props.defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = _props.handleSubmit((data: ANY) => {
    dispatch(updateQuestion(data))
    toggleFlagDataPageQuestion()
    props.toggleEdit()
  })

  return {
    form: _props,
    onSubmit,
  }
}
