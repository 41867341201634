/* eslint-disable @typescript-eslint/no-explicit-any */

const INDEXED_DB_PRIMARY_FILED = 'session_key_'
export enum SessionKey {
  IS_NEW_QUESTION = 'isNewQuestion',
}

class SessionStore {
  private instance: SessionStore | null = null

  constructor() {
    if (this.instance) {
      return this.instance
    }

    this.instance = this
  }

  save = (key: string, value: any) => {
    return sessionStorage.setItem(INDEXED_DB_PRIMARY_FILED + key, value)
  }

  get = (key: string) => {
    return sessionStorage.getItem(INDEXED_DB_PRIMARY_FILED + key)
  }

  remove = (key: string) => {
    return sessionStorage.removeItem(INDEXED_DB_PRIMARY_FILED + key)
  }

  clear = () => {
    return sessionStorage.clear()
  }
}

const sessionStore = new SessionStore()
export default sessionStore
