import { useTrans } from '@/hooks/useTranslation'
import yup from '@/validates'

export const useAddQuestionFormValidate = () => {
  const { t } = useTrans()

  return {
    schema: yup.object().shape({
      question: yup.string().required(t('questionRequired')),
    }),
  }
}
