import FolderIcon from '@/components/Icon/folder.svg?react'

import useImagePreview from './imagePreview.hook'

interface IProps {
  path?: File | string
}
const ImagePreview = (props: IProps) => {
  const { src } = useImagePreview({ path: props.path })

  return (
    <div className="shrink-0 w-[240px] h-[126px] rounded-lg overflow-hidden flex justify-center items-center border">
      {src ? (
        <img
          alt="Cover"
          className="w-full h-full object-cover block"
          src={src}
        />
      ) : (
        <FolderIcon className="h-8.5 w-auto" />
      )}
    </div>
  )
}

export default ImagePreview
