import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { useTrans } from '@/hooks/useTranslation'
import RowCheckbox from '@/pages/dataWarehouse/components/rowCheckbox/rowCheckbox'
import { IProcessedData } from '@/types'

import { TableCreateFormItem } from './components'
import { useTableFormFactoryContext } from './tableCreateFormFactory.context'
import { TableFormFactoryProps } from './tableCreateFormFactory.props'

const TableFormFactory = (props: TableFormFactoryProps) => {
  const { t } = useTrans()
  const { isCheckedAll, handleUpdateAllColumnsChecked } =
    useTableFormFactoryContext()

  const { list } = props

  return list.map((item: IProcessedData, index: number) => {
    const processedId = item.id
    return (
      <AccordionItem
        className="mb-6"
        key={`${item.name}.${index}`}
        value={`${item.name}.${index}`}
      >
        <AccordionTrigger>
          <div className="w-full flex justify-between">
            <p>
              {t('tableName')}: {item.name}
            </p>
            <div className="mr-2">
              <RowCheckbox
                checked={isCheckedAll[processedId]}
                className="!p-0"
                isRowSelectALL={true}
                label="Check all"
                onChange={(checked) => {
                  handleUpdateAllColumnsChecked(processedId, checked)
                }}
              />
            </div>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <TableCreateFormItem columns={item?.columns} processedId={item.id} />
        </AccordionContent>
      </AccordionItem>
    )
  })
}

export default TableFormFactory
