import { useState } from 'react'

import { useDebounce } from '@/hooks/useDebounce'
import { ANY } from '@/types'

import { UseRowUpdateDescriptionFormProps } from './rowUpdateDescriptionForm.props'

export const useRowUpdateDescriptionForm = (
  props: UseRowUpdateDescriptionFormProps,
) => {
  const [description, setDescription] = useState(props.description)
  const onChange = useDebounce((e: ANY) => {
    setDescription(e.target.value)
  }, 300)

  const handleSubmit = (e: ANY) => {
    e.preventDefault()
    e.stopPropagation()
    props.handleUpdateDescription(description)
    props.onClose()
  }

  return {
    onChange,
    handleSubmit,
  }
}
