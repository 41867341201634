import { useState } from 'react'

import { ANY } from '@/types'

type CallbackFunction<T extends ANY[]> = (...args: T) => void

export function useDebounce<T extends ANY[]>(
  callback: CallbackFunction<T>,
  delay: number,
): CallbackFunction<T> {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  return (...args: T) => {
    if (timer) {
      clearTimeout(timer)
    }
    setTimer(
      setTimeout(() => {
        callback(...args)
      }, delay),
    )
  }
}
