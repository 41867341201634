import { useEffect, useRef } from 'react'
import { useDebounceValue } from 'usehooks-ts'

import { browserCacheKey } from '@/constants/common'
import useBrowserCache from '@/hooks/useBrowserCache'
import { useRouter } from '@/hooks/useRouter'
import { ANY } from '@/types'

import { useDataWarehouseUpdateContext } from '../dataWarehouseUpdate.context'
import { UPDATE_BASIC_FORM_KEY } from './updateBasic.constant'

export const useUpdateBasic = () => {
  const { form } = useDataWarehouseUpdateContext()
  const { params } = useRouter()
  const refFirstRender = useRef(true)
  const [debouncedValue, setCachedValue] = useDebounceValue<ANY>(undefined, 500)

  const { pid, id } = params

  const { getCachedData, insertOrUpdateCacheData } = useBrowserCache(
    browserCacheKey.tables,
  )

  const onFormChange = ({ name, value }: { name: string; value: ANY }) => {
    setCachedValue({
      ...(debouncedValue ?? {}),
      [name]: value,
    })
    insertOrUpdateCacheData({
      where: {
        pid,
        warehouseId: id,
        source: UPDATE_BASIC_FORM_KEY.UPDATE_BASIC,
      },
      data: {
        ...(debouncedValue ?? {}),
        [name]: value,
      },
    })
  }

  //Load cache when first render
  useEffect(() => {
    if (!pid || !id) {
      return
    }
    if (!refFirstRender.current) {
      return
    }

    const loadCache = async () => {
      getCachedData(
        {
          pid,
          warehouseId: id,
          source: UPDATE_BASIC_FORM_KEY.UPDATE_BASIC,
        },
        true,
      ).then((cache) => {
        if (cache) {
          cache?.name && form.setValue('name', cache.name)
          cache?.description && form.setValue('description', cache.description)
          cache?.refresh && form.setValue('refresh', cache.refresh)
          cache?.sources && form.setValue('sources', cache.sources)
        }
      })
    }
    loadCache()
    refFirstRender.current = false

    return () => {
      form.resetField('name')
      form.resetField('description')
      form.resetField('refresh')
      form.resetField('sources')
    }
  }, [])

  return { form, onFormChange }
}
