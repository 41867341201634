import { CREATE_DATASET_STEP } from './datasetCreate.constant'
import {
  DatasetCreateProvider,
  useDatasetCreateContext,
} from './datasetCreate.context'
import UpdateColumn from './updateColumns/updateColumn'
import UpdateConnection from './updateConnection/updateConnection'
import UpdateName from './updateName/updateName'
import UpdateSpreadsheet from './updateSpreadsheet/updateSpreadsheet'

const CreateDataset = () => {
  const { currentStep } = useDatasetCreateContext()
  switch (currentStep) {
    case CREATE_DATASET_STEP.NAME:
      return <UpdateName />
    case CREATE_DATASET_STEP.SPREADSHEET:
      return <UpdateSpreadsheet />
    case CREATE_DATASET_STEP.COLUMN:
      return <UpdateColumn />
    case CREATE_DATASET_STEP.CONNECTION:
      return <UpdateConnection />

    default:
      return <div></div>
  }
}

const CreateDatasetContainer = () => {
  return (
    <DatasetCreateProvider>
      <CreateDataset />
    </DatasetCreateProvider>
  )
}

export default CreateDatasetContainer
