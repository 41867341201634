import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { onEnterPress } from '@/helpers/common'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types'

import useFormAddEmail from './formAddEmail.hook'
import { FormAddEmailProps } from './formAddEmail.props'

const FormAddEmail = (props: FormAddEmailProps) => {
  const { disabled, submitCallback } = props

  const { t } = useTrans()
  const { form, onSubmit } = useFormAddEmail({
    submitCallback: submitCallback,
  })

  return (
    <Form {...form}>
      <form
        className="w-full"
        onKeyDown={onEnterPress(() => {
          form.handleSubmit(onSubmit as ANY)()
        })}
        onSubmit={onSubmit}
      >
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => {
              return (
                <FormItem className="w-full">
                  <FormLabel className="text-base" requiredField>
                    {t('newUser')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="min-h-9 mt-4"
                      disabled={disabled}
                      onChange={field.onChange}
                      placeholder={t('typeYourEmail')}
                      type="email"
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => {
              return (
                <FormItem className="w-full">
                  <FormLabel className="text-base" requiredField>
                    {t('nameUser')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="min-h-9 mt-4"
                      disabled={disabled}
                      onChange={field.onChange}
                      placeholder={t('typeYourName')}
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
        </div>
        <div className="flex justify-end mt-3">
          <Button
            className="min-w-[80px]"
            disabled={disabled || !form.formState.isDirty}
            onClick={onSubmit}
            type="button"
            variant={'default'}
          >
            {t('addEmail')}
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default FormAddEmail
