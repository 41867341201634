import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { CHATBOT_SOCKET_LISTEN } from '@/constants/common'
import { useTrans } from '@/hooks/useTranslation'
import { chatWebAppSocket } from '@/services/socket/chatWebApp/chatWebApp.socket'
import { ANY } from '@/types'

import { useConversationContext } from '../conversation.context'
import { useConversationChattingContext } from './conversationChatting.context'

const useConversationChatting = () => {
  const { t } = useTrans()
  const { pid } = useConversationContext()
  const [isLoading, setLoading] = useState(true)
  const { listMessage, setListMessage, pushNewMessage } =
    useConversationChattingContext()

  const clientId = localStorage.getItem('clientId')

  const ref_listMessage = useRef<HTMLDivElement>(null)
  const sizeMessage = listMessage.length

  const handleScrollToBottom = () => {
    setTimeout(() => {
      if (!ref_listMessage.current) return
      ref_listMessage.current.scrollTo({
        top: ref_listMessage.current.scrollHeight,
        behavior: 'smooth',
      })
    }, 100)
  }

  useEffect(() => {
    const clientId = localStorage.getItem('clientId')
    if (!pid || !clientId) return
    setLoading(true)
    chatWebAppSocket.getConversationHistory({
      pid,
      clientId,
      callback: (messageHistory: Array<Record<string, unknown>>) => {
        setLoading(false)
        setListMessage(messageHistory)
      },
    })
  }, [pid, setListMessage])

  useEffect(() => {
    chatWebAppSocket.onListenMessage(
      CHATBOT_SOCKET_LISTEN.CHAT_MESSAGE,
      (newMessage) => {
        pushNewMessage({
          text: newMessage,
          sender: 'bot',
        })
      },
    )
  }, [pushNewMessage])

  useEffect(() => {
    if (!listMessage.length) return

    handleScrollToBottom()
  }, [listMessage])

  const handleDeleteConversation = () => {
    if (sizeMessage === 0) return
    chatWebAppSocket.sendMessage(
      'delete chat history',
      { clientId, pid },
      (response: ANY) => {
        if (response && response.success) {
          setListMessage([])
          toast.success(t('chatHistoryDeleted'))
        } else {
          toast.error(t('errorDeletingChatHistory'))
        }
      },
    )
  }

  return {
    isLoading,
    ref_listMessage,
    sizeMessage,
    handleDeleteConversation,
  }
}

export default useConversationChatting
