export enum MESSAGE_TYPE {
  USER = 'user',
  BOT = 'bot',
  SYSTEM = 'system',
  BOT_ATTACHMENT = 'bot_attachment',
}

export interface IMessageSystem {
  buttons: Array<{ id: string; content: string }>
  fillColor?: boolean
  onClickButton: (message: Record<string, unknown>) => void
}

export interface IMessageItemProps {
  systemButtons?: Array<{ id: string; content: string }>
  text?: string
  file?: {
    name: string
    path: string
  }
  sender: MESSAGE_TYPE
}
