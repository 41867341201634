import { useEffect, useState } from 'react'

import { ANY } from '@/types'

import { IJsonInput } from './jsonInput.props'

const useJsonInput = (props: IJsonInput) => {
  const { value, placeholder } = props
  const [jsonInput, setJsonInput] = useState('')
  const [isError, setError] = useState<boolean>(false)

  const handleInputChange = (event: ANY) => {
    let inputValue = event.target.value
    try {
      inputValue = JSON.parse(inputValue)
      inputValue = JSON.stringify(inputValue, null, 4)
      if (props.onChange) props.onChange(inputValue)
      setError(false)
    } catch (error) {
      if (props.onChange) props.onChange('')
      if (!inputValue) {
        return setError(false)
      }
      setError(true)
    } finally {
      setJsonInput(inputValue)
    }
  }

  useEffect(() => {
    if (!value) return

    setJsonInput(value)
  }, [value])

  return {
    isError,
    jsonInput,
    placeholder,
    handleInputChange,
  }
}

export default useJsonInput
