import { FormField } from '@/components/ui/form'

import { useDataWarehouseUpdateContext } from '../dataWarehouseUpdate.context'
import { AddQuestionFormItem, QuestionItem } from './components'
import { QUESTION_LIST_FORM_KEY } from './questionList.constant'
import { QuestionListProvider } from './questionList.context'
import { useQuestionList } from './questionList.hook'

const QuestionList = () => {
  const {
    form,
    handleAddQuestion,
    questionFields,
    handleRemoveQuestion,
    handleUpdateQuestion,
  } = useQuestionList()

  return (
    <div className="w-full h-full space-y-6">
      <AddQuestionFormItem dispatchCallback={handleAddQuestion} />

      <div className="w-full flex flex-col gap-6">
        {questionFields.map((item, index: number) => {
          return (
            <FormField
              control={form.control}
              key={item.id}
              name={`${QUESTION_LIST_FORM_KEY.QUESTIONS}.${index}`}
              render={({ field }) => {
                return (
                  <QuestionItem
                    content={field.value?.content ?? field.value}
                    field={field}
                    index={index}
                    onDelete={() => {
                      handleRemoveQuestion(index, field.value._id)
                    }}
                    onUpdate={handleUpdateQuestion}
                  />
                )
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

const QuestionListContainer = () => {
  const { form } = useDataWarehouseUpdateContext()
  return (
    <QuestionListProvider value={{ form }}>
      <QuestionList />
    </QuestionListProvider>
  )
}

export default QuestionListContainer
