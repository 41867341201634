import { GoogleLogin } from '@react-oauth/google'
import { useMemo, useRef } from 'react'
import { Navigate } from 'react-router-dom'

import { Header } from '@/components/layout'
import { Separator } from '@/components/ui/separator'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useSession } from '@/contexts/sessionContext'
import { useTrans } from '@/hooks/useTranslation'
import { useWindowSize } from '@/hooks/useWindowSize'
import Login from '@/pages/Auth/Login/Login'
import Register from '@/pages/Auth/Register/Register'

import useAuth from './Auth.hook'

const Auth = () => {
  const { t } = useTrans()
  const size = useWindowSize()
  const ref_tabs = useRef(null)
  const { session } = useSession()
  const { isShowGoogleLoginButton, setShowGoogleLoginButton, responseGoogle } =
    useAuth()

  const widthGGButton = useMemo(
    () =>
      ((ref_tabs?.current as unknown as HTMLElement)?.clientWidth || 300) +
      'px',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [size],
  )

  if (session) {
    return <Navigate to="/projects" />
  }

  return (
    <div className="bg-white h-screen">
      <Header />
      <div className="container max-w-[791px] py-[40px]">
        <div className="py-[32px] w-full">
          <p className="h2 w-full text-center mb-[16px]">
            {t('welcomeToTeramot')}
          </p>
          <p className="w-full text-center h4 text-text-gray">
            {t('signInWithYourEmail')}
          </p>
        </div>
        <Separator />
        <div className="mx-auto max-w-[420px] flex flex-col items-center mt-[28px]">
          <div className="px-[10px] w-full">
            <div
              className="p-[24px] border-border border w-full rounded-[8px]"
              ref={ref_tabs}
            >
              <Tabs defaultValue="signIn">
                <TabsList className="grid w-full grid-cols-2 p-0 h-auto !rounded-none mb-[53px]">
                  <TabsTrigger
                    className="!shadow-none rounded"
                    onClick={() => setShowGoogleLoginButton(true)}
                    value="signIn"
                  >
                    {t('signIn')}
                  </TabsTrigger>
                  <TabsTrigger
                    className="!shadow-none rounded"
                    onClick={() => setShowGoogleLoginButton(false)}
                    value="signUp"
                  >
                    {t('signUp')}
                  </TabsTrigger>
                </TabsList>
                <TabsContent value="signIn">
                  <Login />
                </TabsContent>
                <TabsContent value="signUp">
                  <Register />
                </TabsContent>
              </Tabs>
            </div>
          </div>
          {isShowGoogleLoginButton && (
            <div className="flex justify-center mt-[28px] w-full">
              <GoogleLogin
                onError={responseGoogle}
                onSuccess={responseGoogle}
                width={widthGGButton}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Auth
