import OnboardingCover from '@/components/OnboardingCover/OnboardingCover'
import { Button } from '@/components/ui/button'
import { useRouter } from '@/hooks/useRouter'

const BillingCongratulation = () => {
  const { location, navigate } = useRouter()
  const handleRedirectGoLive = () => {
    const slugs = location.pathname.split('/')
    slugs.pop()
    slugs.push('billing-qr')
    const url = slugs.join('/')
    navigate(url)
  }
  return (
    <div className="w-full h-screen overflow-hidden">
      <OnboardingCover backgroundVariant="variant2">
        <div className="relative w-full h-full flex flex-col items-center justify-center gap-12">
          <div className="w-full flex flex-col justify-center items-center gap-8">
            <h2 className="h2 text-center !text-white  md:h1">All set!</h2>
            <p className="h4 text-center !text-white md:h2">
              Activate the bot to get the information you need, just when you
              need it
            </p>
          </div>
          <div className="flex justify-center">
            <div className="inline-block">
              <Button onClick={handleRedirectGoLive} variant="secondary">
                Go live
              </Button>
            </div>
          </div>
        </div>
      </OnboardingCover>
    </div>
  )
}

export default BillingCongratulation
