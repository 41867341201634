import { useRouter } from '@/hooks/useRouter'

export const useDatasetEmpty = () => {
  const { params, navigate } = useRouter()
  const { pid } = params

  const goToCreateDataset = () => {
    navigate(`/project/${pid}/dataset/create`)
  }

  const meta = {
    goToCreateDataset,
  }

  return meta
}
