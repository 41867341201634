import { EyeNoneIcon, EyeOpenIcon } from '@radix-ui/react-icons'
import { useState } from 'react'
import React from 'react'

import { cn } from '@/lib/utils'

import { Button } from './button'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string
}
export interface InputAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, error, ...props }, ref) => {
    return (
      <>
        <input
          className={cn(
            'flex h-11 w-full rounded-md border border-input bg-transparent px-3 py-2 ' +
            'text-sm transition-colors file:border-0 ' +
            'file:bg-transparent file:text-sm file:font-medium ' +
            'placeholder:text-muted-foreground focus-visible:outline-none ' +
            'focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
            className,
          )}
          ref={ref}
          type={type}
          {...props}
        />
        {error && (
          <div className="text-text-error pl-2 text-sm mt-1">{error}</div>
        )}
      </>
    )
  },
)

const InputPassword = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const [showPassword, setShowPassword] = useState(false)
    const disabled =
      props.value === '' || props.value === undefined || props.disabled

    return (
      <div className="relative">
        <Input {...props} ref={ref} type={showPassword ? 'text' : 'password'} />
        <Button
          className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
          disabled={disabled}
          onClick={() => setShowPassword((prev) => !prev)}
          size="sm"
          type="button"
          variant="ghost"
        >
          {showPassword && !disabled ? (
            <EyeOpenIcon aria-hidden="true" className="h-4 w-4" />
          ) : (
            <EyeNoneIcon aria-hidden="true" className="h-4 w-4" />
          )}
          <span className="sr-only">
            {showPassword ? 'Hide password' : 'Show password'}
          </span>
        </Button>

        {/* hides browsers password toggles */}
        <style>{`
					.hide-password-toggle::-ms-reveal,
					.hide-password-toggle::-ms-clear {
						visibility: hidden;
						pointer-events: none;
						display: none;
					}
				`}</style>
      </div>
    )
  },
)

const InputArea = React.forwardRef<HTMLTextAreaElement, InputAreaProps>(
  ({ className, error, ...props }, ref) => {
    return (
      <>
        <textarea
          className={cn(
            'flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
            className,
          )}
          ref={ref}
          {...props}
        />
        {error && (
          <div className="text-text-error pl-2 text-sm mt-1">{error}</div>
        )}
      </>
    )
  },
)

Input.displayName = 'Input'

export { Input, InputArea, InputPassword }
