import { useState } from 'react'

import { confirmRodal } from '@/components/rodal'
import Rodal from '@/components/rodal/rodal'
import { useTrans } from '@/hooks/useTranslation'

const useQuestionItem = ({ onDelete }: { onDelete: () => void }) => {
  const [isEdit, setEdit] = useState(false)
  const { t } = useTrans()
  const toggleEdit = () => {
    setEdit(!isEdit)
  }

  const handDeleteQuestion = () => {
    confirmRodal({
      children: ({ onClose }) => {
        return (
          <Rodal
            message={t('descriptionDeleteQuestion')}
            onCancel={onClose}
            onClose={onClose}
            onConfirm={() => {
              onDelete()
            }}
            title={t('titleDeleteQuestion')}
          />
        )
      },
    })
  }
  return { isEdit, toggleEdit, handDeleteQuestion }
}

export default useQuestionItem
