import { IProjectConfigurationData } from '@/types'

import { useAppSelector } from '..'
import { IUseGetProject } from './useGetProject.props'

export const useGetProject = (props: IUseGetProject) => {
  const { pid } = props

  const projects = useAppSelector((state) => state.project.projects)
  const project = projects?.[pid] ?? {}
  const configuration =
    project?.configuration ?? ({} as IProjectConfigurationData)

  return {
    project,
    configuration,
  }
}
