import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { createQuestion } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { ANY } from '@/types'

import { UseAddQuestionFormProps } from './addQuestionForm.props'
import { useAddQuestionFormValidate } from './addQuestionForm.validate'

export const useAddQuestionForm = (props: UseAddQuestionFormProps) => {
  const { schema } = useAddQuestionFormValidate()
  const dispatch = useAppDispatch()
  const _props = useForm({
    defaultValues: props.defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = _props.handleSubmit((data: ANY) => {
    dispatch(createQuestion(data))
    _props.reset(props.defaultValues)
    props.callback && props.callback()
    // props.toggleEdit()
  })

  return {
    form: _props,
    onSubmit,
  }
}
