import { ANY } from '@/types'

import { UPDATE_DATABASE_STEP } from './updateDatabase.constant'
import { useUpdateDatabaseContext } from './updateDatabase.context'
import { UpdateABSDatasetProps } from './updateDatabase.props'

export const useChangeStepUpdateDatabase = () => {
  const { handleChangeStep } = useUpdateDatabaseContext()
  const handleChangeDatabase = () => {
    handleChangeStep(UPDATE_DATABASE_STEP.CHANGE_DATABASE)
  }
  const handleViewUpdate = () => {
    handleChangeStep(UPDATE_DATABASE_STEP.VIEW_UPDATE)
  }
  return {
    handleChangeDatabase,
    handleViewUpdate,
  }
}

export const useUpdateDatabase = ({ detail }: UpdateABSDatasetProps) => {
  const { dbName, dbType, host, port, pwd, ...restDetail } = detail as ANY
  return {
    dbConfig: {
      dbName,
      dbType,
      host,
      port,
      pwd,
    },
    ...restDetail,
  }
}
