import clsx from 'clsx'
import React, { ReactNode } from 'react'

import IcClose from '@/components/Icon/close.svg?react'
import IcPlus from '@/components/Icon/plus.svg?react'
import IcSubPage from '@/components/Icon/subPage.svg?react'
import IcToggleMenu from '@/components/Icon/toggleMenu.svg?react'
import { Button } from '@/components/ui/button'
import { useRouter } from '@/hooks/useRouter'
import { ANY } from '@/types'

import useSubSidebar from './subSidebar.hook'

interface IProps {
  title: string
  buttons?: Array<{
    key: string
    label: string
    onClick: () => void
    disable?: boolean
    icon?: ReactNode
  }>
  addButton?: {
    label: string
    onClick: () => void
  }
  renderLabel?: (item: ANY, isActive: boolean) => JSX.Element
}
const SubSidebar = (props: IProps) => {
  const { isOpen, handleToggle, isMediumScreens } = useSubSidebar()
  const { location } = useRouter()
  const classContainer = clsx(
    'z-10 flex flex-col absolute md:relative h-full w-full md:w-auto md:bg-white left-[-100%] md:left-0',
    {
      '!left-0 transition-all': isOpen,
    },
  )

  return (
    <React.Fragment>
      {!isMediumScreens && (
        <Button
          className="!bg-white !rounded-[0px] absolute z-10"
          onClick={handleToggle}
        >
          <IcToggleMenu />
        </Button>
      )}

      <div className={classContainer} onClick={handleToggle}>
        <div className="w-11/12 md:w-[260px] grow pt-12 md:pt-[165px] pb-14 lg:pb-4 shrink-0 border-r border-border-1 bg-white relative h-full overflow-hidden flex flex-col">
          <Button
            className="!bg-white !rounded-[0px] absolute top-0 right-0 md:hidden"
            onClick={handleToggle}
          >
            <IcClose />
          </Button>
          <p className="base2 mb-8 px-8">{props.title}</p>
          <div className="w-full flex flex-col gap-4 overflow-auto overflow-x-hidden">
            {props.buttons?.map((item, index) => {
              const pathNames = location.pathname.split('/')
              const channel = pathNames.pop()
              return (
                <Button
                  // eslint-disable-next-line max-len
                  className={clsx(
                    'flex w-full justify-start gap-6 rounded-[4px] pl-0 !bg-transparent hover:underline hover:underline-offset-8 px-8',
                    {
                      'underline underline-offset-8': channel === item.key,
                    },
                  )}
                  disabled={item?.disable ?? false}
                  key={item.label + index}
                  onClick={item.onClick}
                  variant="ghost"
                >
                  {item.icon ? (
                    item.icon
                  ) : (
                    <div className="h-full shrink-0 flex items-center">
                      <IcSubPage className="shrink-0" height={20} />
                    </div>
                  )}

                  {/*  */}
                  {props.renderLabel ? (
                    props.renderLabel(item, channel === item.key)
                  ) : (
                    <span
                      className={clsx('text-ellipsis overflow-hidden', {
                        'font-bold': channel === item.key,
                      })}
                    >
                      {item.label}
                    </span>
                  )}
                </Button>
              )
            })}

            {props.addButton && (
              <Button
                // eslint-disable-next-line max-len
                className="flex w-full justify-start gap-6 rounded-[4px] !text-secondary-1 pl-0 hover:underline hover:underline-offset-8 px-8 bg-white z-10 sticky bottom-0"
                onClick={props.addButton.onClick}
                variant="ghost"
              >
                <IcPlus height={20} />
                {props.addButton.label}
              </Button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SubSidebar
