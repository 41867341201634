import { memo } from 'react'

import { cn } from '@/lib/utils'

import { ChatBotProvider, useChatBotContext } from './chatbot.context'
import { ChatBotBody } from './chatbotBody'
import { ChatBotHeader } from './chatbotHeader'

const ChatBot = () => {
  const { isShowMessage } = useChatBotContext()
  return (
    <div
      className={cn(
        'w-full max-w-[400px] bg-white',
        'bg-white border border-gray-300',
        'fixed bottom-0 right-0 sm:right-10 z-10',
        'flex justify-center items-center',
        'shadow-[0px 0px 15px 0px #00000026]',
        'rounded-xl rounded-ee-none rounded-es-none overflow-hidden',
      )}
    >
      <div className={cn('w-full h-full flex flex-col overflow-hidden')}>
        <ChatBotHeader />
        <div
          className={cn(
            'transition-all w-full h-full overflow-hidden relative flex flex-col',
            {
              'h-0': !isShowMessage,
              'h-[60dvh]': isShowMessage,
            },
          )}
        >
          <ChatBotBody />
        </div>
      </div>
    </div>
  )
}

const ChatBotContainer = () => {
  return (
    <ChatBotProvider>
      <ChatBot />
    </ChatBotProvider>
  )
}

export default memo(ChatBotContainer)
