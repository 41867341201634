import React, { ReactNode, useEffect } from 'react'

import sessionStore, { SessionKey } from '@/helpers/sessionStore'

interface ProjectContextProps {
  isNewQuestion: boolean
  toggleFlagDataPageQuestion: () => void
  resetFlagDataPageQuestion: () => void
}

export const ProjectContext = React.createContext<ProjectContextProps | null>(
  null,
)

export const ProjectProvider = ({ children }: { children: ReactNode }) => {
  const [isFlagNewQuestion, setIsFlagNewQuestion] = React.useState(false)

  const toggleFlagDataPageQuestion = () => {
    if (isFlagNewQuestion) return
    sessionStore.save(SessionKey.IS_NEW_QUESTION, 'true')
    setIsFlagNewQuestion(true)
  }

  const resetFlagDataPageQuestion = () => {
    if (!isFlagNewQuestion) return
    sessionStore.remove(SessionKey.IS_NEW_QUESTION)
    setIsFlagNewQuestion(false)
  }

  useEffect(() => {
    return () => sessionStore.remove(SessionKey.IS_NEW_QUESTION)
  }, [])

  return (
    <ProjectContext.Provider
      value={{
        isNewQuestion: isFlagNewQuestion,
        toggleFlagDataPageQuestion,
        resetFlagDataPageQuestion,
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}

export const useProject = () => {
  const ctx = React.useContext(ProjectContext)
  if (!ctx) throw new Error('useProject must be used within a ProjectProvider')

  return ctx
}

export default ProjectContext
