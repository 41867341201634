import yup from '@/validates'

export const useLoginSchema = () => {
  return {
    schema: yup.object().shape({
      email: yup
        .string()
        .required('Email is required')
        .email('Email is not valid')
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Email is not valid domain',
        ),
      password1: yup.string().required('Password is required'),
    }),
  }
}
