import { Cross1Icon } from '@radix-ui/react-icons'

import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'

import ConversationLogo from './conversationLogo/conversationLogo'
import ConversationSelectProject from './conversationSelectProject/conversationSelectProject'
import useConversationSidebar from './conversationSidebar.hook'

const ConversationSidebar = () => {
  const { isHideSidebar, handleToggleSidebar } = useConversationSidebar()

  const className = cn(
    // eslint-disable-next-line max-len
    'shrink-0 h-full w-9/12  md:w-[300px] bg-primary-1 flex flex-col overflow-auto pb-2 gap-3 transition-transform duration-300 ease-in-out',
    '-translate-x-full md:translate-x-0 top-0 left-0 fixed md:relative z-[40]',
    { '!translate-x-0': isHideSidebar },
  )
  return (
    <div className={className}>
      <ConversationLogo />
      <div className="shrink-0 sticky w-full px-3 pt-2 top-0 z-10 bg-primary-1">
        <div className="w-full flex justify-between gap-2 mb-4">
          <ConversationSelectProject />
          <Button
            className="shrink-0 rounded-lg -mt-2 flex md:hidden"
            onClick={handleToggleSidebar}
            size={'icon'}
            variant={'outline'}
          >
            <Cross1Icon className="w-4 h-4" />
          </Button>
        </div>
        {/* <ConversationButton>
          <div className="w-full flex justify-between items-center">
            <div className="flex items-center gap-3 grow">
              <img
                alt="Logo"
                className="h-[20px] shrink-0"
                src={'/favicon.ico'}
              />
              <span className="text-sm font-bold">{t('newChat')}</span>
            </div>
            <Pencil2Icon className="w-4 h-4 shrink-0" />
          </div>
        </ConversationButton> */}
      </div>
      {/* <div className="grow px-3 overflow-y-scroll">
        <HistoryConversation />
      </div> */}
    </div>
  )
}

export default ConversationSidebar
