import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { confirmRodal } from '@/components/rodal'
import Rodal from '@/components/rodal/rodal'
import {
  setCurrentProject,
  setOutputChannels,
} from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'

import { CHANEL_TYPE } from '../usersChannel.constant'
import { useTeramotChannelValidate } from './teramotChannel.validate'

const useTeramotChannel = () => {
  const dispatch = useAppDispatch()
  const { t } = useTrans()
  const { params } = useRouter()
  const pid = params.pid!

  const { schema } = useTeramotChannelValidate()
  const { project } = useGetProject({ pid })

  const form = useForm({
    defaultValues: { emails: [] },
    resolver: yupResolver(schema),
  })

  const isActivateTeramot = form.watch('activateTeramot')

  const emails = form.watch('emails')
  const outputChannelString = useMemo(
    () => JSON.stringify(project?.outputChannels ?? {}),
    [project?.outputChannels],
  )

  useEffect(() => {
    const outputChannels = JSON.parse(outputChannelString)
    const outputChannel = outputChannels[CHANEL_TYPE.TERAMOT]
    if (!outputChannel) return

    form.setValue('activateTeramot', outputChannel?.activateTeramot || false)
    form.setValue('emails', outputChannel?.emails || [])
  }, [form, outputChannelString])

  const onSubmit = form.handleSubmit(
    async (payload: Record<string, unknown>) => {
      const meta = {
        activateTeramot: payload?.activateTeramot ?? false,
        emails: payload.emails ?? [],
        type: CHANEL_TYPE.TERAMOT,
      }

      const promise = dispatch(setOutputChannels(pid, meta))
      toast.promise(promise, {
        pending: t('processingOutputChannels'),
        success: t('outputChannelsAddedSuccessfully'),
        error: t('errorSettingOutputChannels'),
      })

      promise.then(() => dispatch(setCurrentProject(pid)))
    },
  )

  /**
   *
   * @param payload
   * @returns false if email name already exist
   * @returns true if email name not exist
   */
  const handleAddEmail = (payload: Record<string, string>) => {
    const { email, name } = payload

    const emails = form.getValues('emails') ?? []
    const isExist = emails.some((e) => e.email === email)
    if (isExist) {
      toast.error(t('emailAlreadyExist'))
      return false
    }
    const newPhones = [
      ...emails,
      {
        name,
        email,
        checked: false,
      },
    ]
    // check if phone is already exist
    form.setValue('emails', newPhones)
    return true
  }

  const handleRemoveEmail = (phone: string) => {
    const removeEmail = () => {
      const listPhone = form.getValues('emails') ?? []
      const indexPhone = listPhone.findIndex((e) => e.email === phone)
      if (indexPhone != -1) {
        listPhone.splice(indexPhone, 1)
        form.setValue('emails', listPhone)
      }
    }

    confirmRodal({
      children: ({ onClose }) => {
        return (
          <Rodal
            message={t('descriptionDeleteEmail')}
            onCancel={onClose}
            onClose={onClose}
            onConfirm={() => {
              removeEmail()
            }}
            title={t('titleDeleteEmail')}
          />
        )
      },
    })
  }

  const handleCheckEmail = (email: string, isChecked: boolean) => {
    const listEmail = form.getValues('emails') ?? []
    const indexEmail = listEmail.findIndex((e) => e.email === email)
    if (indexEmail != -1) {
      listEmail[indexEmail].checked = isChecked
      form.setValue('emails', listEmail)
    }
  }

  return {
    isActivateTeramot,
    form,
    onSubmit,
    handleAddEmail,
    emails,
    handleRemoveEmail,
    handleCheckEmail,
  }
}

export default useTeramotChannel
