import { useEffect, useRef } from 'react'

import { genTableQuestionUUID } from '@/helpers/genUUID'
import { useGetCurrentProject } from '@/hooks/useGetCurrentProject/useGetCurrentProject'
import { useRouter } from '@/hooks/useRouter'
import { FormInstanceProps, IWarehouse } from '@/types'

export const useDataWarehouseUpdate = () => {
  const { params } = useRouter()
  const { id } = params
  const { project } = useGetCurrentProject()

  const warehouse = project?.warehouse?.find((wh) => wh.id === id)
  return { warehouse }
}

export const useDataWarehouseChange = (
  form: FormInstanceProps,
  warehouse: IWarehouse,
) => {
  const refOldWarehouse = useRef<string>()
  useEffect(() => {
    if (!warehouse) return
    if (refOldWarehouse.current === JSON.stringify(warehouse)) return
    refOldWarehouse.current = JSON.stringify(warehouse)
    const { questions, knowledge, ...rest } = warehouse
    const newQuestion = questions?.map((question) => {
      const _id = question._id ?? genTableQuestionUUID()
      const content =
        typeof question.content === 'string' ? question.content : question
      return { content, cacheId: null, _id: _id }
    })
    const newKnowledge = knowledge?.map((_knowledge) => {
      const _id = _knowledge._id ?? genTableQuestionUUID()
      const content =
        typeof _knowledge.content === 'string' ? _knowledge.content : _knowledge
      return { content, cacheId: null, _id }
    })

    form.reset({
      ...rest,
      refresh: rest.refresh ? rest.refresh : false,
      questions: newQuestion,
      knowledge: newKnowledge,
      add_question: '',
      add_knowledge: '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(warehouse)])
}
