import { useCallback } from 'react'
import { BlockerFunction, useBlocker } from 'react-router-dom'

import { UseNavBlockerProps } from './useNavBlocker.props'

const useNavBlocker = (props: UseNavBlockerProps) => {
  const { cb, dependencies = [] } = props
  const shouldBlocker = useCallback<BlockerFunction>(cb(), [...dependencies])
  const blocker = useBlocker(shouldBlocker)
  return { blocker }
}

export default useNavBlocker
