import * as React from 'react'

import IcClicle1 from '@/components/Icon/billing_clicle.svg?react'
import IcRect1 from '@/components/Icon/billing_rect.svg?react'
import IcClicle2 from '@/components/Icon/IconClicle2.svg?react'
import IcRect2 from '@/components/Icon/IconRect2.svg?react'
import IcRect3 from '@/components/Icon/IconRect3.svg?react'
import IcRect4 from '@/components/Icon/IconRect4.svg?react'

interface OnboardingCoverProps {
  backgroundVariant: 'variant1' | 'variant2' | 'variant3'
  children: React.ReactNode
}

const OnboardingCover: React.FC<OnboardingCoverProps> = ({
  backgroundVariant,
  children,
}) => {
  const renderBackgroundSvgs = (variant: string) => {
    switch (variant) {
      case 'variant2':
        return (
          <>
            <div className="absolute top-20 right-0">
              <IcRect1 />
            </div>
            <div className="absolute bottom-16 left-0">
              <IcRect2 />
            </div>
            <div className="absolute bottom-2.5 right-0">
              <IcClicle1 />
            </div>
          </>
        )
      case 'variant3':
        return (
          <>
            <div className="absolute top-5 left-0">
              <IcClicle2 />
            </div>
            <div className="absolute bottom-20 left-0">
              <IcRect4 />
            </div>
            <div className="absolute top-20 right-0">
              <IcRect3 />
            </div>
          </>
        )
      case 'variant1':
      default:
        return null
    }
  }

  return (
    <div className={`w-full h-screen overflow-hidden bg-primary-1`}>
      <div className="p-5 w-full h-full relative">
        {renderBackgroundSvgs(backgroundVariant)}
        {children}
      </div>
    </div>
  )
}

export default OnboardingCover
