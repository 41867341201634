export enum CREATE_DATASET_STEP {
  NAME = 'NAME',
  SPREADSHEET = 'SPREADSHEET',
  TEXT = 'TEXT',
  DESCRIPTION = 'DESCRIPTION',
  COLUMN = 'COLUMN',
  CONNECTION = 'CONNECTION',
}

export enum DATASET_TYPE {
  SPREADSHEET = 'SPREADSHEET',
  DATABASE = 'DATABASE',
}
