import { Outlet } from 'react-router'

import SubSidebar from '@/components/subSidebar/subSidebar'
import { useTrans } from '@/hooks/useTranslation'

import useUsersChannel from './usersChannel.hook'

const UsersChannel = () => {
  const { t } = useTrans()
  const { subSidebarLinks } = useUsersChannel()
  return (
    <div className="w-full grow flex h-full">
      <SubSidebar buttons={subSidebarLinks} title={t('outputChannel')} />
      <Outlet />
    </div>
  )
}
export default UsersChannel
