import { useCallback, useEffect, useRef } from 'react'

const useEffectOne = (callBack: () => void) => {
  const refFirstOne = useRef<boolean>(false)
  const cb = useCallback(callBack, [callBack])
  useEffect(() => {
    if (refFirstOne.current) {
      return
    }
    cb()
    refFirstOne.current = true
  }, [])
}

export default useEffectOne
