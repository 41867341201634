import { DATA_WAREHOUSE_CREATE_STEP } from './dataWarehouseCreate.constant'
import {
  DataWarehouseCreateProvider,
  useDataWarehouseCreateContext,
} from './dataWarehouseCreate.context'
import QuestionAndKnowledge from './questionAndKnowledge/questionAndKnowledge'
import SelectInput from './selectInput/selectInput'
import UpdateName from './updateName/updateName'

const DataWarehouseCreate = () => {
  const { currentStep } = useDataWarehouseCreateContext()
  switch (currentStep) {
    case DATA_WAREHOUSE_CREATE_STEP.NAME:
      return <UpdateName />
    case DATA_WAREHOUSE_CREATE_STEP.SELECT_INPUT:
      return <SelectInput />
    case DATA_WAREHOUSE_CREATE_STEP.QUESTION_AND_KNOWLEDGE:
      return <QuestionAndKnowledge />
    default:
      return <div></div>
  }
}

const DataWarehouseCreateContainer = () => {
  return (
    <DataWarehouseCreateProvider>
      <DataWarehouseCreate />
    </DataWarehouseCreateProvider>
  )
}

export default DataWarehouseCreateContainer
