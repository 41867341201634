import { useState } from 'react'

import { UseBasicInfoProps } from './basicInfo.props'

export const useBasicInfo = (props: UseBasicInfoProps) => {
  const [isEdit, setIsEdit] = useState(() => {
    return props.botName === ''
  })
  const toggleEdit = () => {
    setIsEdit((old) => !old)
  }
  return { isEdit, toggleEdit }
}
