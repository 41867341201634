import { Cross2Icon } from '@radix-ui/react-icons'

import { Button } from '@/components/ui/button'

interface IProps {
  email: string
  isAuthor: boolean
  onRemoveEmail: () => void
}
const MemberItem = (props: IProps) => {
  return (
    <Button
      onClick={props.onRemoveEmail}
      type="button"
      variant={props.isAuthor ? 'gray' : 'outline'}
    >
      {!props.isAuthor && <Cross2Icon className="mr-2.5 h-4 w-4" />}
      {props.email}
    </Button>
  )
}

export default MemberItem
