import { CREATE_DATASET_STEP, DATASET_TYPE } from './datasetCreate.constant'

const NextStepMap = {
  [CREATE_DATASET_STEP.SPREADSHEET]: CREATE_DATASET_STEP.COLUMN,
  [CREATE_DATASET_STEP.CONNECTION]: CREATE_DATASET_STEP.COLUMN,
  [CREATE_DATASET_STEP.COLUMN]: CREATE_DATASET_STEP.NAME,
}

export type IStepMap =
  | CREATE_DATASET_STEP.NAME
  | CREATE_DATASET_STEP.SPREADSHEET
  | CREATE_DATASET_STEP.CONNECTION
  | CREATE_DATASET_STEP.COLUMN

export const isEndStepFlow = (step: IStepMap) => {
  return step === CREATE_DATASET_STEP.COLUMN
}

export const getNextCreateDatasetFlow = (
  step: IStepMap,
  type?: DATASET_TYPE,
) => {
  if (step === CREATE_DATASET_STEP.NAME) {
    switch (type) {
      case DATASET_TYPE.SPREADSHEET:
        return CREATE_DATASET_STEP.SPREADSHEET
      case DATASET_TYPE.DATABASE:
        return CREATE_DATASET_STEP.CONNECTION
      default:
        return ''
    }
  }
  return NextStepMap[step]
}

export const getPreCreateDatasetFlow = (step: IStepMap) => {
  if (
    [CREATE_DATASET_STEP.SPREADSHEET, CREATE_DATASET_STEP.CONNECTION].includes(
      step,
    )
  ) {
    return CREATE_DATASET_STEP.NAME
  }

  const index = Object.values(NextStepMap).findIndex(
    (currStep) => currStep === step,
  )

  if (index == -1) return CREATE_DATASET_STEP.NAME
  const preStep = Object.keys(NextStepMap)[index]
  return preStep
}
