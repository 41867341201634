/* eslint-disable max-len */
import { ArrowUpIcon } from '@radix-ui/react-icons'

import { Button } from '@/components/ui/button'
import { Form, FormField } from '@/components/ui/form'

import useChattingFooter from './chattingFooter.hook'

const ChattingFooter = () => {
  const { form, onSubmit } = useChattingFooter()

  return (
    <div className="w-full pb-3">
      <Form {...form}>
        <form
          className="w-full border-text-gray rounded-xl p-2 border h-[50px] flex items-center gap-5"
          onSubmit={onSubmit}
        >
          <FormField
            control={form.control}
            name="message"
            render={({ field }) => (
              <input
                autoComplete="off"
                className="border-0 outline-0 w-full h-full px-2 font-semibold bg-transparent"
                placeholder="Enter message..."
                required
                {...field}
              />
            )}
          />

          <Button className="h-full rounded-lg" size={'icon'} type="submit">
            <ArrowUpIcon className="w-5 h-5" />
          </Button>
        </form>
      </Form>
    </div>
  )
}

export default ChattingFooter
