import { ArrowRightIcon } from '@radix-ui/react-icons'
import { forwardRef, Ref, useImperativeHandle, useRef } from 'react'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useTrans } from '@/hooks/useTranslation'

import useFormForgotPassword from './formForgotPassword.hook'
import { FormForgotPasswordProps } from './formForgotPassword.props'

const FormForgotPassword = forwardRef(
  ({ onOpen }: FormForgotPasswordProps, ref: Ref<unknown>) => {
    const ref_submitButton = useRef<HTMLButtonElement | null>(null)
    const { t } = useTrans()
    const { isSubmitting, errors, register, onSubmit } = useFormForgotPassword({
      onOpen,
    })

    useImperativeHandle(ref, () => ({
      onResend() {
        ref_submitButton.current?.click()
      },
    }))

    return (
      <form className="w-full" onSubmit={onSubmit}>
        <div className="space-y-[16px]">
          <div className="w-full flex flex-col items-end gap-[8px] md:flex-row">
            <div className="w-full space-y-[6px] grow">
              <label className="caption2" htmlFor="email">
                {t('email')}
              </label>
              <Input
                {...register('email')}
                className="h-[40px]"
                error={errors.email?.message}
                height={40}
                id="email"
                placeholder={t('typeYourEmailHere')}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-[28px]">
          <Button
            className="min-w-[180px]"
            ref={ref_submitButton}
            type="submit"
            variant="default"
          >
            <ArrowRightIcon className="mr-2.5 h-4 w-4" />
            <span>{isSubmitting ? t('isSubmitting') : t('submit')}</span>
          </Button>
        </div>
      </form>
    )
  },
)

export default FormForgotPassword
