import IcUpload from '@/components/Icon/upload.svg?react'
import { Button } from '@/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'

import { useChangeStepUpdateTable } from '../updateTable.hook'
import {
  useUpdateSpreadsheetFile,
  useUpdateSpreadsheetOnlineLink,
} from './updateTableUploadFile.hook'
import { UpdateTableUploadFileProps } from './updateTableUploadFile.props'

const UpdateTableUploadFile = (props: UpdateTableUploadFileProps) => {
  const { form } = props
  const { params } = useRouter()
  const { t } = useTrans()
  const { pid, id } = params

  const { handleViewUpdate } = useChangeStepUpdateTable()
  const {
    file,
    onChangeUploadFile,
    handleDragAndDropFiles,
    overrideEventDefaults,
  } = useUpdateSpreadsheetFile({
    pid: pid!,
    iid: id!,
    form,
  })
  const { handleUploadWithLink } = useUpdateSpreadsheetOnlineLink({
    pid: pid!,
    iid: id!,
    form,
  })

  return (
    <div className="flex flex-col justify-center gap-8">
      <div className="flex justify-end gap-8">
        <Button
          className="!border-none !shadow-none !bg-transparent !text-secondary-1"
          onClick={handleViewUpdate}
          variant={'outline'}
        >
          {t('viewUpdate')}
        </Button>
      </div>
      <div className="flex justify-center gap-8">
        <FormField
          control={form.control}
          name="file"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel
                  className="cursor-pointer"
                  htmlFor="upload-file"
                  onDragEnter={overrideEventDefaults}
                  onDragLeave={overrideEventDefaults}
                  onDragOver={overrideEventDefaults}
                  onDrop={overrideEventDefaults}
                >
                  <div
                    className="w-full py-4 px-8 border rounded-xl"
                    onDragEnter={overrideEventDefaults}
                    onDragLeave={overrideEventDefaults}
                    onDragOver={overrideEventDefaults}
                    onDrop={handleDragAndDropFiles}
                  >
                    <div className="flex gap-2">
                      <IcUpload />
                      <p className="text-base text-lg">
                        {t('chooseAFileOrDragItHere')}
                      </p>
                    </div>
                    <p className="text-sm mt-2">
                      {t('chooseAFileOrDragItHereDescription')}
                    </p>
                    <div className="py-2 mt-6">
                      <p className="text-sm text-secondary-1">
                        {file?.name ?? 'Choose a file'}
                      </p>
                    </div>
                  </div>
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="!hidden"
                    hidden
                    id="upload-file"
                    onChange={onChangeUploadFile}
                    type="file"
                  />
                </FormControl>
              </FormItem>
            )
          }}
        />
      </div>
      <div className="flex justify-center gap-8 w-full">
        <FormField
          control={form.control}
          name="fileLink"
          render={({ field }) => {
            return (
              <FormItem className="max-w-[784px] w-full">
                <FormLabel className="cursor-pointer" htmlFor="onlineLink">
                  Online link
                </FormLabel>
                <div className="flex gap-2 items-center">
                  <FormControl>
                    <Input
                      {...field}
                      id="onlineLink"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleUploadWithLink()
                        }
                      }}
                    />
                  </FormControl>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      handleUploadWithLink()
                    }}
                    size={'sm'}
                  >
                    Get
                  </Button>
                </div>
              </FormItem>
            )
          }}
        />
      </div>
    </div>
  )
}

export default UpdateTableUploadFile
