import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { useAppDispatch } from '@/hooks'
import { ANY } from '@/types'

import { UseKnowledgeFormItemProps } from './knowledgeFormItem.props'
import { useKnowledgeFormItemValidate } from './knowledgeFormItem.validate'

export const useKnowledgeFormItem = (props: UseKnowledgeFormItemProps) => {
  const { schema } = useKnowledgeFormItemValidate()
  const dispatch = useAppDispatch()
  const _props = useForm({
    defaultValues: props.defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = _props.handleSubmit((data: ANY) => {
    dispatch(props.dispatchCallback(data))
    props.toggleEdit()
  })

  return {
    form: _props,
    onSubmit,
  }
}
