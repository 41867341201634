import { BASE_URL, CHATBOT_SOCKET_PATH } from '@/constants/common'

import { AbsSocket } from './absSocket'

export class ChatBotSocket extends AbsSocket {
  constructor(url: string, path: string) {
    super(url, path)
  }
}

export const chatBotSocket = new ChatBotSocket(BASE_URL, CHATBOT_SOCKET_PATH)
