import UseAnimations from 'react-useanimations'
import activity from 'react-useanimations/lib/activity'
import infinity from 'react-useanimations/lib/infinity'

import { cn } from '@/lib/utils'
import { EETLStatus } from '@/types'

import { SidebarLabelItemProps } from './labelItemStatus.props'
const LabelItemStatus = (props: SidebarLabelItemProps) => {
  const { status } = props

  switch (status) {
    case EETLStatus.PENDING:
      return <UseAnimations animation={infinity} size={20} />
    case EETLStatus.IN_PROGRESS:
      return <UseAnimations animation={activity} size={20} />
    case EETLStatus.FINISHED:
    case EETLStatus.SUCCESSFUL:
      return <div className={cn('size-4 rounded-full bg-green-500')}></div>
    case EETLStatus.ERROR:
      return <div className={cn('size-4 rounded-full bg-red-500')}></div>
    default:
      return <></>
  }
}

export default LabelItemStatus
