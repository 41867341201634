import { FormField } from '@/components/ui/form'

import { TableFormFactoryProvider } from '../../tableFormFactory'
import TableFormFactory from '../../tableFormFactory/tableFormFactory'
import { useDatasetCreateContext } from '../datasetCreate.context'
import DatasetCreateLayout from '../datasetCreateLayout/datasetCreateLayout'
import { useUpdateColumnLeavePage } from './updateColumn.hook'
import { UpdateColumnProps } from './updateColumn.props'
import { useUpdateColumnSchema } from './updateColumn.validate'

const UpdateColumn = (props: UpdateColumnProps) => {
  const { form } = props
  const { dataset } = useDatasetCreateContext()

  useUpdateColumnLeavePage({
    form,
    meta: dataset,
  })

  return (
    <div className="w-full overflow-y-auto">
      <TableFormFactoryProvider form={form}>
        <FormField
          control={form.control}
          name="tables"
          render={({ field }) => {
            if (!field.value || !field.value.length) return <div></div>
            const _ = field.value

            return <TableFormFactory list={_} />
          }}
        />
      </TableFormFactoryProvider>
    </div>
  )
}

const UpdateColumnContainer = () => {
  const { schema } = useUpdateColumnSchema()

  return (
    <DatasetCreateLayout
      schema={schema}
      title="I found these columns. Please select the ones that I will need."
    >
      {(props) => <UpdateColumn {...props} />}
    </DatasetCreateLayout>
  )
}

export default UpdateColumnContainer
