import { useCallback } from 'react'

import indexedDB from '@/helpers/indexedDB'

const useBrowserCache = (table: string) => {
  const updateCacheData = useCallback(
    async ({
      where,
      data,
      options,
    }: {
      where: Record<string, unknown>
      data: Record<string, unknown>
      options?: {
        upsert?: boolean
      }
    }) => {
      await indexedDB.update(table, { where, data, options })
    },
    [table],
  )

  const insertOrUpdateCacheData = useCallback(
    async ({
      where,
      data,
      options,
    }: {
      where: Record<string, unknown>
      data: Record<string, unknown>
      options?: {
        upsert?: boolean
      }
    }) => {
      const cacheData = await getCachedData(where, true)
      if (cacheData) {
        await updateCacheData({ where, data, options })
      } else {
        await insertCacheData({ ...where, ...data })
      }
    },
    [table],
  )

  const insertCacheData = useCallback(
    async (data: Record<string, unknown>) => {
      await indexedDB.create(table, data)
    },
    [table],
  )

  const getCachedData = useCallback(
    async (where?: Record<string, unknown>, isFindOne?: boolean) => {
      if (isFindOne) return await indexedDB.findOne(table, { where })
      return await indexedDB.findMany(table, { where })
    },
    [table],
  )

  const clearCache = useCallback(async () => {
    return await indexedDB.dropTable(table)
  }, [table])

  const deleteCacheData = useCallback(
    async (where: Record<string, unknown>) => {
      return await indexedDB.delete(table, { where })
    },
    [table],
  )

  return {
    insertOrUpdateCacheData,
    insertCacheData,
    updateCacheData,
    getCachedData,
    deleteCacheData,
    clearCache,
  }
}

export default useBrowserCache
