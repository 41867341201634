import { memo } from 'react'

import OnboardingCover from '@/components/OnboardingCover/OnboardingCover'
import { Button } from '@/components/ui/button'
import { BASE_URL } from '@/constants/common'
import { useTrans } from '@/hooks/useTranslation'

import { useBillingInfo } from './billingInfo.hook'

const BillingInfo = () => {
  const { t } = useTrans()
  const {
    subscription,
    isActive,
    role,
    isTeramot,
    email,
    nextTier,
    handleUpgradeSubscription,
    handleTeramotCleanup,
  } = useBillingInfo()

  const portalURL = BASE_URL + '/backend/create-portal-session'
  const sessionId = subscription?.sessionId ? subscription.sessionId : ''
  return (
    <div className="w-full h-screen overflow-hidden">
      <OnboardingCover backgroundVariant="variant2">
        <div className="relative w-full h-full flex flex-col items-center justify-center gap-12">
          <div className="w-full flex flex-col justify-center items-center gap-8">
            <h2 className="h2 text-center !text-white md:h1">
              {t('yourSubscription')}
            </h2>
            <p className="h5 text-center !text-white md:h2">
              {subscription?.tierName}
            </p>
            <p className="h5 text-center !text-white md:h2">
              {t('owner')}: {subscription?.email}
            </p>
            <p className="h5 text-center !text-white md:h2">
              {t('status')}: {subscription?.status}
            </p>
          </div>

          {(role === 'admin' || role === 'teramot') && isTeramot && (
            <div className="flex justify-center gap-6">
              <div className="centering">
                <div className="button-holder">
                  <Button onClick={handleTeramotCleanup} variant={'third'}>
                    {t('adminTeramotCleanup')}
                  </Button>
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-center gap-6">
            <div className="inline-block">
              <form action={portalURL} className="centering" method="POST">
                <input name="sessionId" type="hidden" value={sessionId} />
                <div className="button-holder">
                  <Button
                    disabled={isTeramot || email !== subscription?.email}
                    type="submit"
                    variant="secondary"
                  >
                    {t('managerSubscription')}
                  </Button>
                </div>
              </form>
            </div>
            {nextTier ? (
              <div className="inline-block">
                <Button
                  disabled={isTeramot || !isActive}
                  onClick={handleUpgradeSubscription}
                  variant="primary"
                >
                  {t('upgrade')} - {nextTier}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </OnboardingCover>
    </div>
  )
}

export default memo(BillingInfo)
