import { ArrowRightIcon } from '@radix-ui/react-icons'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input, InputPassword } from '@/components/ui/input'

import useLogin from './Login.hook'

const Login = () => {
  const { t } = useTranslation()
  const { form, handleSubmit } = useLogin()

  return (
    <Form {...form}>
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="space-y-[16px]">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => {
              return (
                <FormItem className="w-full">
                  <FormLabel className="caption2" requiredField>
                    {t('email')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      className="h-[40px]"
                      placeholder={t('email')}
                      type="email"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
          <FormField
            control={form.control}
            name="password1"
            render={({ field }) => {
              return (
                <FormItem className="w-full">
                  <FormLabel className="caption2" requiredField>
                    {t('password')}
                  </FormLabel>
                  <FormControl>
                    <InputPassword
                      type="password"
                      {...field}
                      className="h-[40px]"
                      placeholder={t('password')}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
          <div>
            <Link to={'/forgot-password'}>
              <Button className="p-0" type="button" variant="link2">
                {t('forgotPassword')}
              </Button>
            </Link>
          </div>
        </div>

        <div className="flex justify-center mt-[53px]">
          <Button
            className="min-w-[180px]"
            disabled={
              form.formState.isSubmitting ||
              !form.formState.dirtyFields['email'] ||
              !form.formState.dirtyFields['password1']
            }
            type="submit"
            variant="default"
          >
            <ArrowRightIcon className="mr-2.5 h-4 w-4" />{' '}
            {form.formState.isSubmitting ? t('isSubmitting') : t('signIn')}
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default Login
