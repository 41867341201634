import { Link } from 'react-router-dom'

import { UserNav } from '@/components/ui'
import { Button } from '@/components/ui/button'
import { useTrans } from '@/hooks/useTranslation'

interface IProps {
  isLogged: boolean
}
const HeaderAction = ({ isLogged }: IProps) => {
  const { t } = useTrans()
  if (isLogged) {
    return <UserNav />
  }

  return (
    <Link to={'/login'}>
      <Button variant="secondary">{t('logIn')}</Button>
    </Link>
  )
}

export default HeaderAction
