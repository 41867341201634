import { useTrans } from '@/hooks/useTranslation'

import { Checkbox } from '../ui/checkbox'
import { RefreshCheckboxProps } from './refreshCheckbox.props'

const RefreshCheckbox = (props: RefreshCheckboxProps) => {
  const { isChecked, onCheckedChange } = props
  const { t } = useTrans()

  return (
    <label className="flex items-center gap-2 base1 font-medium cursor-pointer">
      <Checkbox checked={isChecked} onCheckedChange={onCheckedChange} />
      {t('datasetRefresh')}
    </label>
  )
}

export default RefreshCheckbox
