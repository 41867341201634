import { useEffect, useRef } from 'react'

import { ANY } from '@/types'

const useUnload = (fn: (e?: ANY) => void) => {
  const cb = useRef(fn) // this will store the function

  useEffect(() => {
    cb.current = fn // update the latest function into our ref
  }, [fn])

  useEffect(() => {
    const onUnload = cb.current

    window.addEventListener('beforeunload', onUnload)
    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [])
}

export default useUnload
