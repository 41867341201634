import 'react-phone-input-2/lib/style.css'

import PhoneInput from 'react-phone-input-2'

import { IPhoneProps } from './phone.props'

const Phone = (props: IPhoneProps) => {
  return <PhoneInput {...props} />
}

export default Phone
