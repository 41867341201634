import { Outlet, useLocation } from 'react-router'
import { Navigate } from 'react-router-dom'

import { useSession } from '@/contexts/sessionContext'
import { isAuth } from '@/helpers/auth'

const AdminRoute = () => {
  const { session } = useSession()
  const auth = isAuth()
  const location = useLocation()

  if (!session || auth.role !== 'admin')
    return <Navigate state={{ from: location }} to="/login" />
  return <Outlet />
}

export default AdminRoute
