import {
  BASE_URL,
  CHATBOT_SOCKET_EMIT,
  CHATBOT_SOCKET_PATH,
} from '@/constants/common'

import { AbsSocket } from '../absSocket'
import { IGetConversationHistory, IHandleSendMessage } from './chatWebApp.props'

export class ChatWebAppSocket extends AbsSocket {
  constructor(url: string, path: string) {
    super(url, path)
  }

  getConversationHistory = (data: IGetConversationHistory) => {
    const { clientId, pid, callback } = data
    this.sendMessage(
      CHATBOT_SOCKET_EMIT.CHAT_HISTORY,
      { clientId, pid },
      callback,
    )
  }

  handleSendMessage = (data: IHandleSendMessage) => {
    const { clientId, pid, message, callback } = data
    this.sendMessage(
      CHATBOT_SOCKET_EMIT.CHAT_MESSAGE,
      { clientId, pid, message },
      callback,
    )
  }
}

export const chatWebAppSocket = new ChatWebAppSocket(
  BASE_URL,
  CHATBOT_SOCKET_PATH,
)
