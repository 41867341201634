import { Outlet } from 'react-router'

import { ChatBot } from '@/components/chatbot'
import SideBar from '@/components/sidebar/sidebar'
import { ProjectProvider } from '@/contexts/projectContext'
import { SideBarProvider } from '@/contexts/sidebarContext'

import useProjectLayout from './projectLayout.hook'
import { useProjectPollingDataWarehouse } from './projectLayout.poolingWarehouse.hook'

const ProjectLayout = () => {
  useProjectLayout()
  useProjectPollingDataWarehouse()

  return (
    <div className="w-screen h-dvh min-h-dvh overflow-hidden">
      <div className="flex w-full h-full flex-col md:flex-row md:flex-nowrap">
        <SideBar />
        {/* <div className="grow h-full md:h-dvh md:overflow-auto flex"> */}
        <div className="grow h-full md:h-dvh overflow-hidden flex">
          <div className="grow h-full w-full md:overflow-hidden flex flex-col relative">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

const ProjectLayoutContainer = () => {
  return (
    <SideBarProvider>
      <ProjectProvider>
        <ProjectLayout />
      </ProjectProvider>
      <ChatBot />
    </SideBarProvider>
  )
}

export default ProjectLayoutContainer
