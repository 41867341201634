import { EETLStatus } from '@/types'

import { useGetCurrentProject } from '../useGetCurrentProject/useGetCurrentProject'

const listStatusRunning = [EETLStatus.PENDING, EETLStatus.IN_PROGRESS]

export const useWarehouseStatusProcessing = () => {
  const { warehouse } = useGetCurrentProject()
  return {
    isWarehouseAllProcessing: warehouse?.some((wh) =>
      listStatusRunning.includes(wh.etl?.status),
    ),
  }
}
