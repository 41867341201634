import { ConversationProvider } from './conversation.context'
import ConversationChatting from './conversationChatting/conversationChatting'
import ConversationSidebar from './conversationSidebar/conversationSidebar'

const Conversation = () => {
  return (
    <div className="w-dvw h-dvh flex relative">
      <ConversationSidebar />
      <ConversationChatting />
    </div>
  )
}

const ConversationContainer = () => {
  return (
    <ConversationProvider>
      <Conversation />
    </ConversationProvider>
  )
}

export default ConversationContainer
