import { useEffect, useMemo } from 'react'

import { getProjects } from '@/features/project/projectSlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useRouter } from '@/hooks/useRouter'

import { useConversationContext } from '../../conversation.context'

export const useGetProjectOptions = () => {
  const dispatch = useAppDispatch()
  const projects = useAppSelector((state) => state.project?.projects ?? {})

  useEffect(() => {
    dispatch(getProjects({}))
  }, [dispatch])

  const projectOptions = useMemo(() => {
    return Object.values(projects)
      .filter((e) => e.guestProject)
      .map((project) => ({
        label: project.name,
        value: project.pid,
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(projects)])

  return {
    projectOptions,
  }
}

export const useSelectProject = () => {
  const { navigate } = useRouter()

  const { pid } = useConversationContext()
  const handleSelectProject = (pid: string) => {
    navigate(`/conversation/${pid}`)
  }

  return {
    pid,
    handleSelectProject,
  }
}
