import React from 'react'

import { BREAKPOINTS } from '@/constants/common'
import { useWindowSize } from '@/hooks/useWindowSize'

const useSubSidebar = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { width } = useWindowSize()

  const isMediumScreens = width >= BREAKPOINTS.MD

  const handleToggle = () => {
    if (isMediumScreens) return
    setIsOpen(!isOpen)
  }

  return {
    isOpen,
    handleToggle,
    isMediumScreens,
  }
}

export default useSubSidebar
