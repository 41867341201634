import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { chatWebAppSocket } from '@/services/socket/chatWebApp/chatWebApp.socket'
import { ANY } from '@/types'

import { useConversationContext } from '../../conversation.context'
import { useConversationChattingContext } from '../conversationChatting.context'
import { schemaConversationForm } from './chattingFooter.validate'

const useChattingFooter = () => {
  const { pid } = useConversationContext()
  const { pushNewMessage } = useConversationChattingContext()

  const form = useForm({
    resolver: yupResolver(schemaConversationForm),
  })

  const onSubmit = form.handleSubmit(async (data: ANY) => {
    const clientId = localStorage.getItem('clientId')
    if (!clientId || !pid) return

    const { message } = data

    chatWebAppSocket.handleSendMessage({
      clientId,
      pid,
      message,
      callback: (response: ANY) => {
        if (!response?.success) return
        pushNewMessage({
          text: message,
          sender: 'user',
        })
        form.setValue('message', '')
      },
    })
  })

  return { form, onSubmit }
}

export default useChattingFooter
