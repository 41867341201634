import OnboardingCover from '@/components/OnboardingCover/OnboardingCover'
import {
  ENV_VITE,
  QR_URL_DEV,
  QR_URL_PROD,
  VITE_NODE_ENV,
} from '@/constants/common'

const BillingQr = () => {
  const qrImage =
    VITE_NODE_ENV === ENV_VITE.PRODUCTION ? QR_URL_PROD : QR_URL_DEV
  return (
    <div className="w-full h-screen overflow-hidden">
      <OnboardingCover backgroundVariant="variant2">
        <div className="relative w-full h-full flex flex-col items-center justify-center gap-10 md:gap-[78px]">
          <div className="w-full flex flex-col justify-center items-center gap-8">
            <h2 className="h2 text-center !text-white md:h1">Scan to chat</h2>
            <div>
              <p className="h4 text-center !text-white md:h2">
                Or copy your bot phone Number
              </p>
              <p className="h4 text-center !text-accent-2 mt-4 md:h2">
                +01 22 33003 2202
              </p>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="inline-block w-48 aspect-square p-6 rounded-lg bg-white">
              <img alt="QR" src={qrImage} />
            </div>
          </div>
        </div>
      </OnboardingCover>
    </div>
  )
}

export default BillingQr
