import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { UseFormAddPhoneNumberProps } from './formAddPhoneNumber.props'
import { useFormAddPhoneNumberValidate } from './formAddPhoneNumber.validate'

const useFormAddPhoneNumber = ({
  submitCallback,
}: UseFormAddPhoneNumberProps) => {
  const { schema } = useFormAddPhoneNumberValidate()

  const form = useForm({
    defaultValues: { phone: '', name: '' },
    resolver: yupResolver(schema),
  })

  const onSubmit = form.handleSubmit(
    async (payload: Record<string, string>) => {
      const isSubmitSuccess = submitCallback(payload)
      if (isSubmitSuccess) {
        form.reset()
        form.setFocus('phone')
      }
    },
  )

  return {
    form,
    onSubmit,
  }
}

export default useFormAddPhoneNumber
