import { Navigate } from 'react-router'

import { useDataWarehouse } from './dataWarehouse.hook'

const HOCDataWarehouse = () => {
  const { pid, firstWarehouse } = useDataWarehouse()

  if (firstWarehouse) {
    return (
      <Navigate to={`/project/${pid}/data-warehouse/${firstWarehouse.id}`} />
    )
  }

  return <Navigate to={`/project/${pid}/data-warehouse/create`} />
}

export default HOCDataWarehouse
