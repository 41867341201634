import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { onEnterPress } from '@/helpers/common'
import { useTrans } from '@/hooks/useTranslation'

import { useKnowledgeFormItem } from './knowledgeFormItem.hook'
import { KnowledgeFormItemProps } from './knowledgeFormItem.props'

const KnowledgeFormItem = (props: KnowledgeFormItemProps) => {
  const { toggleEdit } = props
  const { t } = useTrans()

  const { form, onSubmit } = useKnowledgeFormItem({
    defaultValues: props.defaultValues,
    toggleEdit,
    dispatchCallback: props.dispatchCallback,
  })

  return (
    <Form {...form}>
      <form className="w-full mt-2" onSubmit={onSubmit}>
        <FormField
          control={form.control}
          name="knowledge"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold">
                {t('description')}
              </FormLabel>
              <Textarea
                id={props.IdFocus}
                placeholder={t('enterYourContent')}
                {...field}
                onKeyDown={onEnterPress(() => {
                  form.handleSubmit(onSubmit)()
                })}
              />
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="w-full flex justify-end gap-2 mt-4">
          <Button borderRadius="large">
            <CheckIcon className="h-4 w-4" />
          </Button>
          <Button
            borderRadius="large"
            className="border border-n-2"
            onClick={toggleEdit}
            type="button"
            variant="link"
          >
            <Cross2Icon className="h-4 w-4" />
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default KnowledgeFormItem
