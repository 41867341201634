import { useCallback, useContext, useState } from 'react'
import { createContext } from 'react'

import { UPDATE_DATABASE_STEP } from './updateDatabase.constant'
import {
  IUpdateDatabaseContext,
  IUpdateDatabaseProvider,
} from './updateDatabase.props'

export const UpdateDatabaseContext =
  createContext<IUpdateDatabaseContext | null>(null)

export const UpdateDatabaseProvider = ({
  children,
}: IUpdateDatabaseProvider) => {
  const [currentStep, setStep] = useState<UPDATE_DATABASE_STEP>(
    UPDATE_DATABASE_STEP.VIEW_UPDATE,
  )

  const handleChangeStep = useCallback((step: UPDATE_DATABASE_STEP) => {
    setStep(step)
  }, [])

  return (
    <UpdateDatabaseContext.Provider
      value={{
        currentStep,
        handleChangeStep,
      }}
    >
      {children}
    </UpdateDatabaseContext.Provider>
  )
}

export const useUpdateDatabaseContext = () => {
  const ctx = useContext(UpdateDatabaseContext)

  if (!ctx)
    throw new Error(
      'useDatasetCreate must be used within a DatasetCreateProvider',
    )

  return {
    currentStep: ctx.currentStep,
    handleChangeStep: ctx.handleChangeStep,
  }
}
