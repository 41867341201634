import { useTrans } from '@/hooks/useTranslation'
import yup from '@/validates'

export const useKnowledgeFormItemValidate = () => {
  const { t } = useTrans()

  return {
    schema: yup.object().shape({
      knowledge: yup.string().required(t('knowledgeRequired')),
    }),
  }
}
