import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'

import { BASE_URL } from '@/constants/common'
import { clearAuth } from '@/helpers/auth'
import { ANY } from '@/types'

interface CustomAxiosInstance extends AxiosInstance {
  CancelToken?: ANY
}

const axiosInstance: CustomAxiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    // 'Content-Type':'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
  },
})

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig<ANY>) => {
    const isFormData = config.data instanceof FormData

    const customConfig: InternalAxiosRequestConfig<ANY> = {
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
      } as ANY,
    }
    return customConfig
  },
)

axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    return res
  },
  async (error) => {
    // //handle call api 401
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      clearAuth()
      window.location.replace('/sign-in')
    }
    //handle call api error
    return Promise.reject(error)
  },
)

axiosInstance.CancelToken = axios.CancelToken

export default axiosInstance
