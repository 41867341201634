import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { connectDB, setInputData } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types'
import { IProjectInputData } from '@/types'

import { CREATE_DATASET_STEP, DATASET_TYPE } from '../datasetCreate.constant'
import { useDatasetCreateContext } from '../datasetCreate.context'
import { DB_CONNECT_TYPE } from '../updateConnection/updateConnection.constant'
import { UseDatasetCreateProps } from './datasetCreateLayout.props'

const useFormDatasetCreate = ({
  schema,
  defaultValues,
}: UseDatasetCreateProps) => {
  const { t } = useTrans()
  const dispatch = useAppDispatch()
  const { location, navigate } = useRouter()
  const {
    pid,
    currentStep,
    dataset,
    handleNextStep,
    handlePreStep,
    handleUpdateCurrentDataset,
  } = useDatasetCreateContext()

  const props = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    if (!dataset) return

    if (currentStep === CREATE_DATASET_STEP.NAME) {
      props.setValue('name', dataset.name ?? '')
      props.setValue('description', dataset.description ?? '')
      props.setValue('type', dataset.type ?? DATASET_TYPE.SPREADSHEET)
    }

    if (currentStep === CREATE_DATASET_STEP.COLUMN) {
      const tables = dataset.meta?.tables as Array<ANY>

      props.setValue('tables', tables)
    }

    if (currentStep === CREATE_DATASET_STEP.CONNECTION) {
      const dbConfig = dataset.meta?.dbConfig as Record<string, string>
      props.setValue('dbType', dbConfig?.dbType ?? 'postgresql')
      props.setValue('host', dbConfig?.host ?? '')
      props.setValue('port', dbConfig?.port ?? '5432')
      props.setValue('dbName', dbConfig?.dbName ?? '')
      props.setValue('user', dbConfig?.user ?? '')
      props.setValue('pwd', dbConfig?.pwd ?? '')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset, currentStep])

  const onUpdateDatasetToServer = useCallback(
    (payload: Record<string, unknown>) => {
      if (!pid) {
        throw new Error('Project ID is not defined')
      }

      const _payload: Record<string, unknown> = { ...payload }

      return dispatch(setInputData(pid, _payload)).catch((error) => {
        console.error(error)
        throw error
      })
    },
    [pid],
  )

  const handleSubmitNameStep = (payload: Record<string, unknown>) => {
    handleUpdateCurrentDataset(payload)
    handleNextStep(payload.type as DATASET_TYPE)
  }

  const handleSubmitUploadSpreadsheetStep = async () => {
    handleNextStep()
  }

  const handleNavigateDetail = (iid: string) => {
    navigate(`${location.pathname}/${iid}`)
  }

  const handleSubmitColumnStepWithTypeSpreadsheet = async (
    payload: Record<string, unknown>,
  ) => {
    const meta = {
      ...dataset.meta,
      tables: payload.tables,
      name: dataset.name,
      description: dataset.description,
      refresh: dataset.refresh ?? false,
    }
    const promise = onUpdateDatasetToServer(meta).then((result) => {
      handleNavigateDetail((result as IProjectInputData).iid)
    })
    toast
      .promise(promise, {
        pending: t('processingData'),
        success: t('successMessage'),
        error: t('errorCreateDataset'),
      })
      .then(() => handleNextStep())
  }

  const handleSubmitColumnStepWithTypeDB = async (
    payload: Record<string, unknown>,
  ) => {
    const meta = {
      ...dataset.meta,
      tables: payload.tables,
      name: dataset.name,
      description: dataset.description,
      type: 'database',
      refresh: dataset.refresh ?? false,
    }
    const promise = onUpdateDatasetToServer(meta)
    toast
      .promise(promise, {
        pending: t('statusMessagesPending'),
        success: t('successMessage'),
        error: t('createDatasetError'),
      })
      .then((updatedDataset) => {
        handleNavigateDetail((updatedDataset as IProjectInputData).iid)
        handleNextStep()
      })
      .catch((e) => {
        toast.error(e)
      })
  }

  const handleSubmitColumnStep = async (payload: Record<string, unknown>) => {
    switch (dataset.type) {
      case DATASET_TYPE.SPREADSHEET:
        handleSubmitColumnStepWithTypeSpreadsheet(payload)
        return

      case DATASET_TYPE.DATABASE:
        handleSubmitColumnStepWithTypeDB(payload)
        return
      default:
        throw new Error('Unknown dataset type')
    }
  }

  const handleSubmitConnectionStep = async (
    payload: Record<string, unknown>,
  ) => {
    const { type, ...restPayload } = payload
    if (type === DB_CONNECT_TYPE.BIG_QUERY) {
      const {
        gcp_project_id,
        gcp_dataset_id,
        gcp_credentials = '',
      } = restPayload

      const dbConfig = {
        name: dataset.name,
        description: dataset.description,
        type,
        gcp_project_id: gcp_project_id,
        gcp_dataset_id: gcp_dataset_id,
        credentials: JSON.parse(gcp_credentials as string),
      }

      const promise = dispatch(connectDB(pid, dbConfig))
      toast.promise(promise, {
        pending: t('processingInputData'),
        success: t('successMessage'),
        error: t('errorMessageSettingInputData'),
      })
      promise
        .then((project) => {
          handleUpdateCurrentDataset({
            meta: project as Record<string, unknown>,
          })
          handleNextStep()
        })
        .catch((e) => {
          toast.error(e)
        })
    } else {
      // Submit for dbType is mysql or postgresql
      const { dbType, dbName, host, port, user, pwd } = restPayload
      const dbConfig = {
        name: dataset.name,
        description: dataset.description,
        dbType,
        dbName,
        host,
        port,
        user,
        pwd,
      }

      // CONNECT TO DATABASE
      const promise = dispatch(connectDB(pid, dbConfig))
      toast.promise(promise, {
        pending: t('processingInputData'),
        success: t('successMessage'),
        error: t('errorMessageSettingInputData'),
      })
      promise
        .then((project) => {
          handleUpdateCurrentDataset({
            meta: project as Record<string, unknown>,
          })
          handleNextStep()
        })
        .catch((e) => {
          toast.error(e)
        })
    }
  }
  const onSubmit = props.handleSubmit(async (payload) => {
    switch (currentStep) {
      case CREATE_DATASET_STEP.NAME:
        handleSubmitNameStep(payload)
        return
      case CREATE_DATASET_STEP.CONNECTION:
        await handleSubmitConnectionStep(payload)
        return
      case CREATE_DATASET_STEP.SPREADSHEET:
        // eslint-disable-next-line no-case-declarations, no-inner-declarations
        const isValidLink = (link?: string) => {
          if (link?.includes('://')) return true
          return false
        }

        if (
          [payload.isUploadFile, isValidLink(payload.fileLink)].every((e) => !e)
        ) {
          toast.error(t('pleaseFillInTheCorrectInformation'))
          return
        }

        await handleSubmitUploadSpreadsheetStep()
        return
      case CREATE_DATASET_STEP.COLUMN:
        await handleSubmitColumnStep(payload)
        return
    }
  })

  return {
    form: props,
    onSubmit,
    handlePreStep,
    currentStep,
  }
}

export default useFormDatasetCreate
