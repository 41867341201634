import { useFieldArray } from 'react-hook-form'

import { genTableQuestionUUID } from '@/helpers/genUUID'

import { KNOWLEDGE_LIST_FORM_KEY } from './knowledgeList.constant'
import { useKnowledgeListContext } from './knowledgeList.context'

export const useKnowledgeList = () => {
  const { form } = useKnowledgeListContext()

  const handleAddKnowledge = (knowledge: string) => {
    const listKnowledge =
      form.getValues(KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE) ?? []
    if (!knowledge) return

    const _list = Array.isArray(listKnowledge) ? listKnowledge : [listKnowledge]

    form.setValue(KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE, [
      ..._list,
      {
        content: knowledge,
        _id: genTableQuestionUUID(),
      },
    ])
    form.setValue(KNOWLEDGE_LIST_FORM_KEY.ADD_KNOWLEDGE, '')
  }

  const { fields, remove } = useFieldArray({
    control: form.control,
    name: KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE,
  })

  return {
    form,
    handleAddKnowledge,
    knowledgeFields: fields,
    handleRemoveKnowledge: remove,
  }
}
