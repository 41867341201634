import { FormField } from '@/components/ui/form'

import { useDataWarehouseUpdateContext } from '../dataWarehouseUpdate.context'
import { AddKnowledgeFormItem, KnowledgeItem } from './components'
import { KNOWLEDGE_LIST_FORM_KEY } from './knowledgeList.constant'
import { KnowledgeListProvider } from './knowledgeList.context'
import { useKnowledgeList } from './knowledgeList.hook'

const KnowledgeList = () => {
  const {
    form,
    handleAddKnowledge,
    knowledgeFields,
    handleRemoveKnowledge,
    handleUpdateKnowledge,
  } = useKnowledgeList()

  return (
    <div className="w-full h-full space-y-6">
      <AddKnowledgeFormItem dispatchCallback={handleAddKnowledge} />

      <div className="w-full flex flex-col gap-6">
        {knowledgeFields?.map((item, index: number) => {
          return (
            <FormField
              control={form.control}
              key={item.id}
              name={`${KNOWLEDGE_LIST_FORM_KEY.KNOWLEDGE}.${index}`}
              render={({ field }) => {
                return (
                  <KnowledgeItem
                    content={field.value?.content ?? field.value}
                    field={field}
                    index={index}
                    onDelete={() => {
                      handleRemoveKnowledge(index, field.value.cacheId)
                    }}
                    onUpdate={handleUpdateKnowledge}
                  />
                )
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

const KnowledgeListContainer = () => {
  const { form } = useDataWarehouseUpdateContext()

  return (
    <KnowledgeListProvider value={{ form }}>
      <KnowledgeList />
    </KnowledgeListProvider>
  )
}

export default KnowledgeListContainer
