import OnboardingCover from '@/components/OnboardingCover/OnboardingCover'
import { Button } from '@/components/ui/button'
import { useTrans } from '@/hooks/useTranslation'

import { useGetBillingParams } from '../../billing.hook'

const BillingCanceled = () => {
  const { t } = useTrans()
  const { clearSearchParams } = useGetBillingParams()
  const handleRedirectBack = () => {
    clearSearchParams(['tier_name', 'pid', 'sid', 'canceled', 'success'])
  }
  return (
    <div className="w-full h-screen overflow-hidden">
      <OnboardingCover backgroundVariant="variant2">
        <div className="relative w-full h-full flex flex-col items-center justify-center gap-12">
          <div className="w-full flex flex-col justify-center items-center gap-8">
            <h2 className="h2 text-center !text-white  md:h1">
              {t('paymentCanceled')}
            </h2>
            <p className="h4 text-center !text-white md:h2">
              {t('paymentCanceledDescription')}
            </p>
          </div>
          <div className="flex justify-center">
            <div className="inline-block">
              <Button onClick={handleRedirectBack} variant="secondary">
                {t('backButtonMessage')}
              </Button>
            </div>
          </div>
        </div>
      </OnboardingCover>
    </div>
  )
}

export default BillingCanceled
