import BotMessage from './botMessage/botMessage'
import { CONVERSATION_MESSAGE_TYPE } from './chattingMessage.constant'
import useChattingMessage from './chattingMessage.hook'
import { IChattingMessage } from './chattingMessage.props'
import UserMessage from './userMessage/userMessage'

const ChattingMessage = (props: IChattingMessage) => {
  const { messageType, messageData } = useChattingMessage(props)

  switch (messageType) {
    case CONVERSATION_MESSAGE_TYPE.BOT:
      return <BotMessage data={messageData} />

    case CONVERSATION_MESSAGE_TYPE.USER:
      return <UserMessage data={messageData} />

    default:
      return <></>
  }
}

export default ChattingMessage
