import clsx from 'clsx'

import Logo from '@/components/Logo/Logo'
import { useSession } from '@/contexts/sessionContext'

import HeaderAction from './HeaderAction'

const Header = () => {
  const { session } = useSession()
  const isLogged = session

  return (
    <div className={clsx('w-full', { 'bg-primary': !isLogged })}>
      <div
        className={
          'container w-full flex items-center justify-between py-[21.6px]'
        }
      >
        <Logo dark={isLogged} />

        <HeaderAction isLogged={isLogged} />
      </div>
    </div>
  )
}

export default Header
