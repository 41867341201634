import { useEffect, useRef } from 'react'
import { useFieldArray } from 'react-hook-form'

import { browserCacheKey } from '@/constants/common'
import { genTableQuestionUUID } from '@/helpers/genUUID'
import useBrowserCache from '@/hooks/useBrowserCache'
import { useRouter } from '@/hooks/useRouter'
import { ANY } from '@/types'

import { QUESTION_LIST_FORM_KEY } from './questionList.constant'
import { useQuestionListContext } from './questionList.context'

export const useQuestionList = () => {
  const { form } = useQuestionListContext()
  const { params } = useRouter()
  const { pid, id } = params
  const refFirstRender = useRef(true)
  const {
    getCachedData,
    insertCacheData,
    deleteCacheData,
    insertOrUpdateCacheData,
  } = useBrowserCache(browserCacheKey.tables)

  const updateQuestionForm = (
    questions: Array<{ content: string; cacheId: string; _id: string }>,
  ) => {
    const listQuestion = form.getValues(QUESTION_LIST_FORM_KEY.QUESTIONS) ?? []
    form.setValue(
      QUESTION_LIST_FORM_KEY.QUESTIONS,
      listQuestion.concat(questions),
    )
  }

  useEffect(() => {
    if (!pid || !id) {
      return
    }
    if (!refFirstRender.current) {
      return
    }

    const loadCache = async () => {
      getCachedData({
        pid,
        warehouseId: id,
        source: QUESTION_LIST_FORM_KEY.QUESTIONS,
      }).then((cache) => {
        if (cache) {
          const _listQuestion = cache.reduce(
            (
              t: ANY,
              item: { _id: string; question: string; cacheId: string },
            ) => {
              const key = item._id
              return {
                ...t,
                [key]: {
                  content: item.question as string,
                  cacheId: item.cacheId as string,
                  _id: item._id as string,
                },
              }
            },
            {},
          )
          const listQuestion =
            form.getValues(QUESTION_LIST_FORM_KEY.QUESTIONS) ?? []

          const _questions = listQuestion.map((item: ANY) => {
            const exits = _listQuestion[item._id]
            if (exits) {
              delete _listQuestion[exits._id]
              return {
                ...item,
                content: exits.content,
              }
            }

            return item
          })

          form.setValue(
            QUESTION_LIST_FORM_KEY.QUESTIONS,
            _questions.concat(Object.values(_listQuestion)),
          )
        }
      })
    }
    loadCache()
    refFirstRender.current = false
  }, [])

  const handleAddQuestion = (question: string) => {
    if (!question) return

    const cacheId = genTableQuestionUUID()

    updateQuestionForm([
      {
        _id: cacheId,
        content: question,
        cacheId: cacheId,
      },
    ])

    insertCacheData({
      pid: pid,
      warehouseId: id,
      source: QUESTION_LIST_FORM_KEY.QUESTIONS,
      question: question,
      cacheId: cacheId,
      _id: cacheId,
    })

    form.setValue(QUESTION_LIST_FORM_KEY.ADD_QUESTION, '')
  }
  const handleUpdateQuestion = (question: { _id: string; content: string }) => {
    insertOrUpdateCacheData({
      where: {
        pid: pid,
        warehouseId: id,
        source: QUESTION_LIST_FORM_KEY.QUESTIONS,
        _id: question._id,
      },
      data: {
        pid: pid,
        warehouseId: id,
        source: QUESTION_LIST_FORM_KEY.QUESTIONS,
        _id: question._id,
        question: question.content,
        cacheId: question._id,
      },
      options: {
        upsert: true,
      },
    })
  }

  const { fields, remove } = useFieldArray({
    control: form.control,
    name: QUESTION_LIST_FORM_KEY.QUESTIONS,
  })

  const handleRemoveQuestion = (
    index: number,
    cacheQuestionId: string | null,
  ) => {
    remove(index)
    if (!cacheQuestionId) return
    // If cacheQuestionId is not null, delete cache data
    deleteCacheData({
      pid: pid,
      warehouseId: id,
      source: QUESTION_LIST_FORM_KEY.QUESTIONS,
      cacheId: cacheQuestionId,
    })
  }

  return {
    form,
    handleAddQuestion,
    questionFields: fields,
    handleRemoveQuestion,
    handleUpdateQuestion,
  }
}
