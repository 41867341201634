import { CheckIcon } from '@radix-ui/react-icons'

import { Button } from '@/components/ui/button'
import {
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { onEnterPress } from '@/helpers/common'
import { useTrans } from '@/hooks/useTranslation'
import {
  KNOWLEDGE_LIST_FORM_KEY,
  useKnowledgeListContext,
} from '@/pages/dataWarehouse/dataWarehouseCreate/questionAndKnowledge'

import { AddKnowledgeFormItemProps } from './addKnowledgeFormItem.props'

const AddKnowledgeFormItem = (props: AddKnowledgeFormItemProps) => {
  const { dispatchCallback } = props
  const { form } = useKnowledgeListContext()
  const { t } = useTrans()

  return (
    <div>
      <FormField
        control={form.control}
        name={KNOWLEDGE_LIST_FORM_KEY.ADD_KNOWLEDGE}
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-semibold" requiredField>
              {t('newKnowledge')}
            </FormLabel>
            <Textarea
              id={props.IdFocus}
              placeholder={t('enterYourContent')}
              {...field}
              onKeyDown={onEnterPress(() => {
                dispatchCallback(field.value)
              })}
            />
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="w-full flex justify-end gap-2 mt-4">
        <Button
          borderRadius="large"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            dispatchCallback(
              form.getValues(KNOWLEDGE_LIST_FORM_KEY.ADD_KNOWLEDGE),
            )
          }}
        >
          <CheckIcon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  )
}

export default AddKnowledgeFormItem
