import { useGetCurrentProject } from '@/hooks/useGetCurrentProject/useGetCurrentProject'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types'

const useTuning = () => {
  const { t } = useTrans()

  const { params, navigate } = useRouter()
  const { pid } = params

  const { warehouse } = useGetCurrentProject()

  const subSidebarLinks: Array<ANY> = warehouse.reduce(
    (t, wh) => {
      t.push({
        key: wh.id!,
        label: wh.name,
        onClick: () => navigate(`/project/${pid}/tuning/${wh.id!}`),
      })
      return t
    },
    [
      {
        key: '',
        label: 'General',
        onClick: () => navigate(`/project/${pid}/tuning`),
      },
    ],
  )
  return { t, subSidebarLinks: subSidebarLinks }
}

export default useTuning
